const actions = {
  EXAMS_FETCH_REQUEST: 'EXAMS_FETCH_REQUEST',
  EXAMS_FETCH_SUCCESS: 'EXAMS_FETCH_SUCCESS',
  EXAMS_FETCH_ERROR: 'EXAMS_FETCH_ERROR',
  REQUEST_EXAMS: 'REQUEST_EXAMS',
  REQUEST_EXAMS_WAITING: 'REQUEST_EXAMS_WAITING',
  REQUEST_EXAMS_SUCCESS: 'REQUEST_EXAMS_SUCCESS',
  REQUEST_EXAMS_SUCCESS_SECREATARY: 'REQUEST_EXAMS_SUCCESS_SECREATARY',
  REQUEST_EXAMS_ERROR: 'REQUEST_EXAMS_ERROR',
  // EXAMS_REQUEST_WAITING: 'EXAMS_REQUEST_WAITING',
  // EXAMS_REQUEST_SUCCESS: 'EXAMS_REQUEST_SUCCESS',
  // EXAMS_REQUEST_ERROR: 'EXAMS_REQUEST_ERROR',
  SAVE_EXAMS_REQUEST: 'SAVE_EXAMS_REQUEST',
  SAVE_EXAMS_SUCCESS: 'SAVE_EXAMS_SUCCESS',
  SAVE_EXAMS_ERROR: 'SAVE_EXAMS_ERROR',
  CREATE_EXAMS_REQUEST: 'CREATE_EXAMS_REQUEST',
  CREATE_EXAMS_SUCCESS: 'CREATE_EXAMS_SUCCESS',
  CREATE_EXAMS_ERROR: 'CREATE_EXAMS_ERROR',
  EXAMS_FETCH_OR_UPDATING_WAITING: 'EXAMS_FETCH_OR_UPDATING_WAITING',
  REMOVE_EXAMS_REQUEST: 'REMOVE_EXAMS_REQUEST',
  REMOVE_EXAMS_SUCCESS: 'REMOVE_EXAMS_SUCCESS',
  REMOVE_EXAMS_ERROR: 'REMOVE_EXAMS_ERROR',
  REMOVING_EXAMS: 'REMOVING_EXAMS',
  EXAMS_REQUESTS_LISTENER: 'EXAMS_REQUESTS_LISTENER',
  EXAMS_REQUESTS_LISTENER_SUCCESS: 'EXAMS_REQUESTS_LISTENER_SUCCESS',
  FINALIZE_EXAMS_REQUEST: 'FINALIZE_EXAMS_REQUEST',
  FINALIZE_EXAMS_SUCCESS: 'FINALIZE_EXAMS_SUCCESS',
  FINALIZE_EXAMS_ERROR: 'FINALIZE_EXAMS_ERROR',
  FINALIZE_EXAMS_WAITING: 'FINALIZE_EXAMS_WAITING',
  EXAMS_HISTORY_REQUEST: 'EXAMS_HISTORY_REQUEST',
  EXAMS_HISTORY_SUCCESS: 'EXAMS_HISTORY_SUCCESS',
  EXAMS_HISTORY_ERROR: 'EXAMS_HISTORY_ERROR',
  EXAMS_HISTORY_WAITING: 'EXAMS_HISTORY_WAITING',
  EXANS_HISTORY_CLEAR: 'EXANS_HISTORY_CLEAR',
  ENABLE_NOTIFICATION: 'ENABLE_NOTIFICATION',
  DISABLE_NOTIFICATION: 'DISABLE_NOTIFICATION',
  RESET_EXAMS: 'RESET_EXAMS',
  TOGGLE_NEW_EXAM_MODAL: 'TOGGLE_ADD_EXAM_MODAL',
  USER_PRINTED_OR_DOWNLOADED_EXAM: 'USER_PRINTED_OR_DOWNLOADED_EXAM',
  REQUEST_PACSWEB: 'REQUEST_PACSWEB',
  REQUEST_UNIMED_EXAMS_RESULTS: 'REQUEST_UNIMED_EXAMS_RESULTS',
  REQUEST_UNIMED_EXAMS_RESULTS_LOADING: 'REQUEST_UNIMED_EXAMS_RESULTS_LOADING',
  REQUEST_UNIMED_EXAMS_RESULTS_SUCCESS: 'REQUEST_UNIMED_EXAMS_RESULTS_SUCCESS',
  DOCUMENT_UNIMED_REQUEST: 'DOCUMENT_UNIMED_REQUEST',
  DOCUMENT_UNIMED_REQUEST_SUCCESS: 'DOCUMENT_UNIMED_REQUEST_SUCCESS',
  DOCUMENT_UNIMED_REQUEST_ERROR: 'DOCUMENT_UNIMED_REQUEST_ERROR',
  DOCUMENT_UNIMED_REQUEST_WAITING: 'DOCUMENT_UNIMED_REQUEST_WAITING',
  DOCUMENT_UNIMED_BUTTON_PRESSED: 'DOCUMENT_UNIMED_BUTTON_PRESSED',
  UNIMED_EXAMS_MISSING_CPF: 'UNIMED_EXAMS_MISSING_CPF',
  CREATE_CUSTOM_EXAM_ITENS: 'CREATE_CUSTOM_EXAM_ITENS',
  CREATE_CUSTOM_EXAM_ITENS_WAITING: 'CREATE_CUSTOM_EXAM_ITENS_WAITING',
  CREATE_CUSTOM_EXAM_ITENS_SUCCESS: 'CREATE_CUSTOM_EXAM_ITENS_SUCCESS',
  CREATE_CUSTOM_EXAM_ITENS_ERROR: 'CREATE_CUSTOM_EXAM_ITENS_ERROR',
  DUPLICATE_MODEL_REQUEST: 'DUPLICATE_MODEL_REQUEST',
  DUPLICATE_MODEL_WAITING: 'DUPLICATE_MODEL_WAITING',
  DUPLICATE_MODEL_SUCCESS: 'DUPLICATE_MODEL_SUCCESS',
  DUPLICATE_MODEL_ERROR: 'DUPLICATE_MODEL_ERROR',
  toggleNewExamModal: (value) => ({
    type: actions.TOGGLE_NEW_EXAM_MODAL,
    payload: value,
  }),
  enableNotification: () => ({
    type: actions.ENABLE_NOTIFICATION,
  }),
  getExamsRequest: (selectedAddress) => ({
    type: actions.EXAMS_REQUESTS_LISTENER,
    payload: selectedAddress,
  }),
  getExams: () => ({
    type: actions.EXAMS_FETCH_REQUEST,
  }),
  clearHistory: () => ({
    type: actions.EXANS_HISTORY_CLEAR,
  }),
  getExamHistory: (paginate = false) => ({
    type: actions.EXAMS_HISTORY_REQUEST,
    payload: {
      paginate,
    },
  }),
  saveExam: (exam, name, key, desc, observation = '', mode) => ({
    type: actions.SAVE_EXAMS_REQUEST,
    payload: {
      value: {
        exam,
        name,
        desc,
        observation,
        mode,
      },
      key,
    },
  }),
  finalizeExam: (key, data, success) => ({
    type: actions.FINALIZE_EXAMS_REQUEST,
    payload: {
      key,
      data,
      success,
    },
  }),
  createExamRequest: (data, userInfo, requestSecretary, pacsweb) => ({
    type: actions.REQUEST_EXAMS,
    payload: {
      title: data.name || '',
      exam: data.exam,
      professional: data.professional || '',
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      userId: userInfo.id,
      mode: data.mode || 'exam',
      isExamRequest: true,
      observation: data.observation ? data.observation : '',
      requestSecretary,
      userInfo,
      pacsweb,
    },
  }),
  createExamRequestPlan: (data, userInfo, plans, plansInfo, requestSecretary, pacsweb) => ({
    type: actions.REQUEST_EXAMS,
    payload: {
      title: data.name || '',
      exam: data.exam,
      professional: data.professional || '',
      plans,
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      userId: userInfo.id,
      mode: data.mode || 'exam',
      isExamRequest: true,
      plansInfo,
      observation: data.observation ? data.observation : '',
      requestSecretary,
      userInfo,
      pacsweb,
    },
  }),
  createExam: (exam, name, desc, observation = '', mode) => ({
    type: actions.CREATE_EXAMS_REQUEST,
    payload: {
      exam,
      name,
      desc,
      observation,
      mode,
    },
  }),
  removeExams: (value) => ({
    type: actions.REMOVE_EXAMS_REQUEST,
    payload: value,
  }),
  printOrDownloaded: (value) => ({
    type: actions.USER_PRINTED_OR_DOWNLOADED_EXAM,
    payload: value,
  }),
  requestPacsweb: (data, userInfo, pushKey) => ({
    type: actions.REQUEST_PACSWEB,
    payload: {
      exam: data.exam,
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      userId: userInfo.id,
      userInfo,
      pushKey,
    },
  }),
  requestUnimedExamsResults: (contact) => ({
    type: actions.REQUEST_UNIMED_EXAMS_RESULTS,
    payload: contact,
  }),
  requestUnimedDocument: (id, contact) => ({
    type: actions.DOCUMENT_UNIMED_REQUEST,
    payload: {
      uriAnexo: id,
      // nextUriAnexo: id2,
      contact,
    },
  }),
  updateCustomItem: (item) => ({
    type: actions.CREATE_CUSTOM_EXAM_ITENS,
    payload: item,
  }),
  duplicateModel: (item) => ({
    type: actions.DUPLICATE_MODEL_REQUEST,
    payload: item,
  }),
};

export default actions;
