import React from 'react';
import moment from 'moment-timezone';

function CustomOwnMessage(props) {
  const {
    text,
    timestamp,
    sendingNewMessage,
  } = props;

  const renderTimestamp = () => {
    if (sendingNewMessage) {
      return 'Enviando...';
    }
    if (timestamp) {
      if (moment(timestamp).isSameOrAfter(new Date(), 'day')) {
        return moment(timestamp).format('HH:mm');
      }
      return moment(timestamp).format('DD/MM/YYYY HH:mm');
    }
    return '';
  };

  return (
    <div className="rcw-client">
      <div className="rcw-message-text">
        <p>{text}</p>
      </div>
      <span className="rcw-timestamp">
        {renderTimestamp()}
      </span>
    </div>
  );
}

export default CustomOwnMessage;
