import _ from 'lodash';
import React, {
  useState,
  useEffect,
} from 'react';
import { connect } from 'react-redux';
import {
  LoadingOutlined,
} from '@ant-design/icons';
import {
  Drawer,
  Alert,
} from 'antd';
import {
  Widget,
  toggleInputDisabled,
} from 'react-chat-widget';
import CustomOwnMessage from './customOwnMessage';
import CustomResponseMessage from './customResponseMessage';
import telemedActions from '../../redux/telemed/actions';
import 'react-chat-widget/lib/styles.css';
import ChatWrapper from './chat.styles';
import './style.css';

function TelemedChat({
  openTelemedModal,
  userType,
  sendChatMessage,
  telemedChatMessages,
  disabled,
  hideHeader,
  smallScreenMode,
  showChat,
  appointmentInfo,
  telemedAppointmentData,
  otherPeerEndedCall,
  initiatedCall,
  endingTelemedRoom,
  userMediaAvailable,
  noMediaDevices,
  noVideoDevices,
  noAudioDevices,
}) {
  const [isInputDisabled, setIsInputDisabled] = useState(false);

  useEffect(() => {
    if (disabled && !isInputDisabled) {
      toggleInputDisabled('telemed');
      setIsInputDisabled(true);
    }
    if (!disabled && isInputDisabled) {
      toggleInputDisabled('telemed');
      setIsInputDisabled(false);
    }
  }, [disabled, isInputDisabled]);

  const handleNewUserMessage = (newMessage) => {
    // Now send the message throught the backend API
    sendChatMessage(userType, newMessage);
  };

  const getDataSource = () => {
    let messages = [];
    if (telemedChatMessages) {
      const arr = [];
      telemedChatMessages.forEach((el) => {
        if (userType === el.userType) {
          arr.push({
            component: CustomOwnMessage,
            props: { ...el },
            type: 'component',
            timestamp: new Date(),
            unread: true,
          });
        } else {
          arr.push({
            component: CustomResponseMessage,
            props: { ...el, appointmentInfo, telemedAppointmentData },
            type: 'component',
            timestamp: new Date(),
            unread: true,
          });
        }
      });
      messages = _.orderBy(
        arr,
        [(o) => o?.props?.sendingNewMessage || '', (o) => o?.props?.timestamp],
        ['asc', 'asc'],
      );
    }
    return messages;
  };

  if (smallScreenMode && !showChat) {
    return null;
  }

  const renderWaitingConnection = () => {
    if (!openTelemedModal) {
      return null;
    }
    if (noMediaDevices) {
      return null;
    }
    if (disabled) {
      let text = 'Aguardando conexão...';
      if ((otherPeerEndedCall && initiatedCall) || (endingTelemedRoom && endingTelemedRoom !== 'retry')) {
        text = 'Encerrando chamada...';
      }
      if (!userMediaAvailable) {
        text = 'Aguardando permissão para acessar câmera e microfone';
      }
      if (noVideoDevices) {
        text = 'Aguardando microfone...';
      }
      if (noAudioDevices) {
        text = 'Aguardando câmera...';
      }
      return (
        <div className="waiting-connection">
          <p>
            {text}
          </p>
          <div>
            <LoadingOutlined />
          </div>
        </div>
      );
    }
    if (getDataSource()?.length > 0) {
      return null;
    }
    return (
      <div className="connection-established">
        <Alert
          showIcon
          type="success"
          message={(
            <p>
              Conexão estabelecida
            </p>
          )}
        />
      </div>
    );
  };

  if (smallScreenMode && showChat) {
    return (
      <Drawer
        // title="Basic Drawer"
        rootClassName="telemed-chat-drawer"
        placement="bottom"
        closable={false}
        open={showChat}
        getContainer={false}
        mask={false}
        styles={{
          body: {
            padding: 0,
          },
        }}
      >
        <ChatWrapper
          disabled={disabled}
          hideHeader={hideHeader}
          smallScreenMode={smallScreenMode}
        >
          <Widget
            chatId="telemed"
            // fullScreenMode
            title={null}
            subtitle={null}
            senderPlaceHolder="Digite sua mensagem..."
            handleNewUserMessage={handleNewUserMessage}
            dataSource={getDataSource()}
            inlineMode
          />
          {renderWaitingConnection()}
        </ChatWrapper>
      </Drawer>
    );
  }

  return (
    <ChatWrapper
      disabled={disabled}
      hideHeader={hideHeader}
    >
      <Widget
        chatId="telemed"
        // fullScreenMode
        title={null}
        subtitle={null}
        senderPlaceHolder="Digite sua mensagem..."
        handleNewUserMessage={handleNewUserMessage}
        dataSource={getDataSource()}
        inlineMode
      />
      {renderWaitingConnection()}
    </ChatWrapper>
  );
}

export default connect((state) => ({
  // user: state.Auth.user,
  openTelemedModal: state.Telemed.openTelemedModal,
  userType: state.Telemed.userType,
  telemedChatMessages: state.Telemed.telemedChatMessages,
  otherPeerEndedCall: state.Telemed.otherPeerEndedCall,
  endingTelemedRoom: state.Telemed.endingTelemedRoom,
  noMediaDevices: state.Telemed.noMediaDevices,
  noVideoDevices: state.Telemed.noVideoDevices,
  noAudioDevices: state.Telemed.noAudioDevices,
}), {
  ...telemedActions,
})(TelemedChat);
