const pdfActions = {
  CHANGE_CUSTOM_HEADER_TEXT: 'CHANGE_CUSTOM_HEADER_TEXT',
  CHANGE_HEADER_MODE: 'CHANGE_HEADER_MODE',
  CHANGE_HEADER_LOGO_MODE: 'CHANGE_HEADER_LOGO_MODE',
  CHANGE_HEADER_LOGO_WIDTH_MODE: 'CHANGE_HEADER_LOGO_WIDTH_MODE',
  CHANGE_RENDER_SIGNATURE_DATE: 'CHANGE_RENDER_SIGNATURE_DATE',
  CHANGE_RENDER_SIGNATURE: 'CHANGE_RENDER_SIGNATURE',
  SAVE_LOGO: 'SAVE_LOGO',
  SAVE_LOGO_AS_FILE: 'SAVE_LOGO_AS_FILE',
  SAVE_HEADER: 'SAVE_HEADER',
  SAVE_HEADER_AS_FILE: 'SAVE_HEADER_AS_FILE',
  UPLOAD_PDF_SETTINGS_REQUEST: 'UPLOAD_PDF_SETTINGS_REQUEST',
  UPLOAD_PDF_SETTINGS_START_UPDATE: 'UPLOAD_PDF_SETTINGS_START_UPDATE',
  SAVE_HEADER_SUCCESS: 'SAVE_HEADER_SUCCESS',
  PDF_SETTINGS_FETCH_REQUEST: 'PDF_SETTINGS_FETCH_REQUEST',
  PDF_SETTINGS_FETCH_SUCCESS: 'PDF_SETTINGS_FETCH_SUCCESS',
  DISCARD_PDF_SETTINGS_CHANGES_REQUEST: 'DISCARD_PDF_SETTINGS_CHANGES_REQUEST',
  DISCARD_PDF_SETTINGS_CHANGES_SUCCESS: 'DISCARD_PDF_SETTINGS_CHANGES_SUCCESS',
  GET_PDF_GENERAL_SIZES: 'GET_PDF_GENERAL_SIZES',
  SET_PDF_PAGE_SIZE: 'SET_PDF_PAGE_SIZE',
  SET_PDF_PAGE_SIZE_SUCCESS: 'SET_PDF_PAGE_SIZE_SUCCESS',
  SET_PDF_PAGE_ORIENTATION: 'SET_PDF_PAGE_ORIENTATION',
  SET_PDF_PAGE_ORIENTATION_SUCCESS: 'SET_PDF_PAGE_ORIENTATION_SUCCESS',
  RESET_SEARCH_PATIENTS_SETTINGS: 'RESET_SEARCH_PATIENTS_SETTINGS',
  CHANGE_HEADER_FONT_SIZE: 'CHANGE_HEADER_FONT_SIZE',
  CHANGE_CERTIFICATE_MAIN_TEXT_FONT_SIZE: 'CHANGE_CERTIFICATE_MAIN_TEXT_FONT_SIZE',
  CHANGE_PATIENT_NAME_FONT_SIZE: 'CHANGE_PATIENT_NAME_FONT_SIZE',
  CHANGE_PATIENT_ADDRESS_FONT_SIZE: 'CHANGE_PATIENT_ADDRESS_FONT_SIZE',
  TOGGLE_REGULAR_PRESCRIPTION_PATIENT_INFO: 'TOGGLE_REGULAR_PRESCRIPTION_PATIENT_INFO',
  TOGGLE_SPECIAL_PRESCRIPTION_PATIENT_INFO: 'TOGGLE_SPECIAL_PRESCRIPTION_PATIENT_INFO',
  CHANGE_PRESCRIPTIONS_MEDICATION_FONT_SIZE: 'CHANGE_PRESCRIPTIONS_MEDICATION_FONT_SIZE',
  CHANGE_PRESCRIPTIONS_USE_MODE_FONT_SIZE: 'CHANGE_PRESCRIPTIONS_USE_MODE_FONT_SIZE',
  CHANGE_PRESCRIPTIONS_DESCRIPTION_FONT_SIZE: 'CHANGE_PRESCRIPTIONS_DESCRIPTION_FONT_SIZE',
  CHANGE_PRESCRIPTIONS_QUANTITY_FONT_SIZE: 'CHANGE_PRESCRIPTIONS_QUANTITY_FONT_SIZE',
  CHANGE_EXAMS_ITEM_FONT_SIZE: 'CHANGE_EXAMS_ITEM_FONT_SIZE',
  CHANGE_EXAMS_QUANTITY_FONT_SIZE: 'CHANGE_EXAMS_QUANTITY_FONT_SIZE',
  CHANGE_EXAMS_OBS_FONT_SIZE: 'CHANGE_EXAMS_OBS_FONT_SIZE',
  CHANGE_SIGNATURE_FONT_SIZE: 'CHANGE_SIGNATURE_FONT_SIZE',
  CHANGE_SIGNATURE_DATE_FONT_SIZE: 'CHANGE_SIGNATURE_DATE_FONT_SIZE',
  CHANGE_ADDRESS_FONT_SIZE: 'CHANGE_ADDRESS_FONT_SIZE',
  CHANGE_OPTICAL_OBS_FONT_SIZE: 'CHANGE_OPTICAL_OBS_FONT_SIZE',
  CHANGE_HEADER_MARGIN: 'CHANGE_HEADER_MARGIN',
  CHANGE_FOOTER_MARGIN: 'CHANGE_FOOTER_MARGIN',
  // CHANGE_HEADER_MARGIN_A5: 'CHANGE_HEADER_MARGIN_A5',
  // CHANGE_FOOTER_MARGIN_A5: 'CHANGE_FOOTER_MARGIN_A5',
  CHANGE_LEFT_MARGIN: 'CHANGE_LEFT_MARGIN',
  CHANGE_RIGHT_MARGIN: 'CHANGE_RIGHT_MARGIN',
  // CHANGE_LEFT_MARGIN_A5: 'CHANGE_LEFT_MARGIN_A5',
  // CHANGE_RIGHT_MARGIN_A5: 'CHANGE_RIGHT_MARGIN_A5',
  TOGGLE_RENDER_HEADER: 'TOGGLE_RENDER_HEADER',
  TOGGLE_RENDER_FOOTER: 'TOGGLE_RENDER_FOOTER',
  TOGGLE_RENDER_DATE_SPECIAL_PRESCRIPTION: 'TOGGLE_RENDER_DATE_SPECIAL_PRESCRIPTION',
  CHANGE_SPECIAL_PRESCRIPTION_DATE: 'CHANGE_SPECIAL_PRESCRIPTION_DATE',
  CHANGE_SPECIAL_PRESCRIPTION_DATE_ON_DB: 'CHANGE_SPECIAL_PRESCRIPTION_DATE_ON_DB',
  CHANGE_SIGNATURE_DATE: 'CHANGE_SIGNATURE_DATE',
  CHANGE_SIGNATURE_DATE_ON_DB: 'CHANGE_SIGNATURE_DATE_ON_DB',
  CHANGE_RENDER_ADDRESS: 'CHANGE_RENDER_ADDRESS',
  CREATE_PDF_PROFILE_REQUEST: 'CREATE_PDF_PROFILE_REQUEST',
  CREATE_PDF_PROFILE_SUCCESS: 'CREATE_PDF_PROFILE_SUCCESS',
  CREATE_PDF_PROFILE_ERROR: 'CREATE_PDF_PROFILE_ERROR',
  CREATING_PDF_PROFILE: 'CREATING_PDF_PROFILE',
  SELECT_PDF_PROFILE_REQUEST: 'SELECT_PDF_PROFILE_REQUEST',
  SELECT_PDF_PROFILE_SUCCESS: 'SELECT_PDF_PROFILE_SUCCESS',
  SELECT_PDF_PROFILE_ERROR: 'SELECT_PDF_PROFILE_ERROR',
  UPDATE_PDF_SETTINGS_ON_DB: 'UPDATE_PDF_SETTINGS_ON_DB',
  REMOVE_PDF_PROFILE_REQUEST: 'REMOVE_PDF_PROFILE_REQUEST',
  REMOVE_PDF_PROFILE_SUCCESS: 'REMOVE_PDF_PROFILE_SUCCESS',
  REMOVE_PDF_PROFILE_ERROR: 'REMOVE_PDF_PROFILE_ERROR',
  REMOVING_PDF_PROFILE: 'REMOVING_PDF_PROFILE',
  SELECT_PROFESSIONAL_PDF_PROFILE_REQUEST: 'SELECT_PROFESSIONAL_PDF_PROFILE_REQUEST',
  RESET_SELECT_PROFESSIONAL_PDF_PROFILE: 'RESET_SELECT_PROFESSIONAL_PDF_PROFILE',
  SELECT_PROFESSIONAL_PDF_PROFILE_RENDER: 'SELECT_PROFESSIONAL_PDF_PROFILE_RENDER',
  getPdfSizes: () => ({
    type: pdfActions.GET_PDF_GENERAL_SIZES,
  }),
  changePrescriptionPageSize: (value) => ({
    type: pdfActions.SET_PDF_PAGE_SIZE,
    payload: value,
  }),
  changePrescriptionPageOrientation: (value) => ({
    type: pdfActions.SET_PDF_PAGE_ORIENTATION,
    payload: value,
  }),
  changeCustomHeaderText: (text) => ({
    type: pdfActions.CHANGE_CUSTOM_HEADER_TEXT,
    payload: text,
  }),
  changeHeaderLogoMode: (mode) => ({
    type: pdfActions.CHANGE_HEADER_LOGO_MODE,
    payload: mode,
  }),
  changeRenderSignatureDate: (mode) => ({
    type: pdfActions.CHANGE_RENDER_SIGNATURE_DATE,
    payload: mode,
  }),
  changeRenderSignature: (mode) => ({
    type: pdfActions.CHANGE_RENDER_SIGNATURE,
    payload: mode,
  }),
  toggleFullWidthLogo: (value) => ({
    type: pdfActions.CHANGE_HEADER_LOGO_WIDTH_MODE,
    payload: value,
  }),
  saveLogo: (file) => ({
    type: pdfActions.SAVE_LOGO,
    payload: file,
  }),
  saveLogoAsFile: (file) => ({
    type: pdfActions.SAVE_LOGO_AS_FILE,
    payload: file,
  }),
  uploadPdfSettings: () => ({
    type: pdfActions.UPLOAD_PDF_SETTINGS_REQUEST,
  }),
  getPdfSettings: () => ({
    type: pdfActions.PDF_SETTINGS_FETCH_REQUEST,
  }),
  discardPdfSettingsChanges: () => ({
    type: pdfActions.DISCARD_PDF_SETTINGS_CHANGES_REQUEST,
  }),
  changeHeaderFontSize: (value) => ({
    type: pdfActions.CHANGE_HEADER_FONT_SIZE,
    payload: value,
  }),
  changeCertificateContentFontSize: (value) => ({
    type: pdfActions.CHANGE_CERTIFICATE_MAIN_TEXT_FONT_SIZE,
    payload: value,
  }),
  changePatientNameFontSize: (value) => ({
    type: pdfActions.CHANGE_PATIENT_NAME_FONT_SIZE,
    payload: value,
  }),
  changePatientAddressFontSize: (value) => ({
    type: pdfActions.CHANGE_PATIENT_ADDRESS_FONT_SIZE,
    payload: value,
  }),
  toggleRegularPrescriptionPatientInfo: (checked) => ({
    type: pdfActions.TOGGLE_REGULAR_PRESCRIPTION_PATIENT_INFO,
    payload: checked,
  }),
  toggleSpecialPrescriptionPatientInfo: (checked) => ({
    type: pdfActions.TOGGLE_SPECIAL_PRESCRIPTION_PATIENT_INFO,
    payload: checked,
  }),
  changePrescriptionsMedicationFontSize: (value) => ({
    type: pdfActions.CHANGE_PRESCRIPTIONS_MEDICATION_FONT_SIZE,
    payload: value,
  }),
  changePrescriptionsUseModeFontSize: (value) => ({
    type: pdfActions.CHANGE_PRESCRIPTIONS_USE_MODE_FONT_SIZE,
    payload: value,
  }),
  changePrescriptionsDescriptionFontSize: (value) => ({
    type: pdfActions.CHANGE_PRESCRIPTIONS_DESCRIPTION_FONT_SIZE,
    payload: value,
  }),
  changePrescriptionsQuantityFontSize: (value) => ({
    type: pdfActions.CHANGE_PRESCRIPTIONS_QUANTITY_FONT_SIZE,
    payload: value,
  }),
  changeExamsItemFontSize: (value) => ({
    type: pdfActions.CHANGE_EXAMS_ITEM_FONT_SIZE,
    payload: value,
  }),
  changeExamsQuantityFontSize: (value) => ({
    type: pdfActions.CHANGE_EXAMS_QUANTITY_FONT_SIZE,
    payload: value,
  }),
  changeExamsObsFontSize: (value) => ({
    type: pdfActions.CHANGE_EXAMS_OBS_FONT_SIZE,
    payload: value,
  }),
  changeSignatureFontSize: (value) => ({
    type: pdfActions.CHANGE_SIGNATURE_FONT_SIZE,
    payload: value,
  }),
  changeSignatureDateFontSize: (value) => ({
    type: pdfActions.CHANGE_SIGNATURE_DATE_FONT_SIZE,
    payload: value,
  }),
  changeAddressFontSize: (value) => ({
    type: pdfActions.CHANGE_ADDRESS_FONT_SIZE,
    payload: value,
  }),
  changeOpticalObservationFontSize: (value) => ({
    type: pdfActions.CHANGE_OPTICAL_OBS_FONT_SIZE,
    payload: value,
  }),
  changeHeaderMargin: (value) => ({
    type: pdfActions.CHANGE_HEADER_MARGIN,
    payload: value,
  }),
  changeFooterMargin: (value) => ({
    type: pdfActions.CHANGE_FOOTER_MARGIN,
    payload: value,
  }),
  changeLeftMargin: (value) => ({
    type: pdfActions.CHANGE_LEFT_MARGIN,
    payload: value,
  }),
  changeRightMargin: (value) => ({
    type: pdfActions.CHANGE_RIGHT_MARGIN,
    payload: value,
  }),
  // changeHeaderMarginA5: (value) => ({
  //   type: pdfActions.CHANGE_HEADER_MARGIN_A5,
  //   payload: value,
  // }),
  // changeFooterMarginA5: (value) => ({
  //   type: pdfActions.CHANGE_FOOTER_MARGIN_A5,
  //   payload: value,
  // }),
  // changeLeftMarginA5: (value) => ({
  //   type: pdfActions.CHANGE_LEFT_MARGIN_A5,
  //   payload: value,
  // }),
  // changeRightMarginA5: (value) => ({
  //   type: pdfActions.CHANGE_RIGHT_MARGIN_A5,
  //   payload: value,
  // }),
  toggleRenderHeader: (checked) => ({
    type: pdfActions.TOGGLE_RENDER_HEADER,
    payload: checked,
  }),
  toggleRenderFooter: (checked) => ({
    type: pdfActions.TOGGLE_RENDER_FOOTER,
    payload: checked,
  }),
  toggleRenderDateSpecialPrescription: (checked) => ({
    type: pdfActions.TOGGLE_RENDER_DATE_SPECIAL_PRESCRIPTION,
    payload: checked,
  }),
  changeSpecialPrescriptionDate: (value) => ({
    type: pdfActions.CHANGE_SPECIAL_PRESCRIPTION_DATE,
    payload: value,
  }),
  changeSpecialPrescriptionDateOnDB: (value) => ({
    type: pdfActions.CHANGE_SPECIAL_PRESCRIPTION_DATE_ON_DB,
    payload: value,
  }),
  changeSignatureDate: (value) => ({
    type: pdfActions.CHANGE_SIGNATURE_DATE,
    payload: value,
  }),
  changeSignatureDateOnDB: (value) => ({
    type: pdfActions.CHANGE_SIGNATURE_DATE_ON_DB,
    payload: value,
  }),
  changeRenderAddress: (mode) => ({
    type: pdfActions.CHANGE_RENDER_ADDRESS,
    payload: mode,
  }),
  createPdfProfile: (name) => ({
    type: pdfActions.CREATE_PDF_PROFILE_REQUEST,
    payload: name,
  }),
  selectPdfProfile: (key) => ({
    type: pdfActions.SELECT_PDF_PROFILE_REQUEST,
    payload: {
      key,
    },
  }),
  removePdfProfile: (key) => ({
    type: pdfActions.REMOVE_PDF_PROFILE_REQUEST,
    payload: key,
  }),
  selectProfessionalPdfProfile: (key, values) => ({
    type: pdfActions.SELECT_PROFESSIONAL_PDF_PROFILE_REQUEST,
    payload: {
      key,
      values,
    },
  }),
  resetSelectProfessionalPdfProfile: () => ({
    type: pdfActions.RESET_SELECT_PROFESSIONAL_PDF_PROFILE,
  }),
  selectProfessionalPdfProfileRender: () => ({
    type: pdfActions.SELECT_PROFESSIONAL_PDF_PROFILE_RENDER,
  }),
};
export default pdfActions;
