import { initializeApp } from 'firebase/app';
import {
  getAuth,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signOut,
  FacebookAuthProvider,
} from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';
// import { getDatabase } from 'firebase/database';
// import { getFirestore } from 'firebase/firestore';
// import { getStorage } from 'firebase/storage';
import { getMessaging } from 'firebase/messaging';
import { firebaseConfig } from '../../settings';

const valid = firebaseConfig && firebaseConfig.apiKey && firebaseConfig.projectId;
const validAnalytics = firebaseConfig && firebaseConfig.measurementId;

let firebaseApp;
if (valid) {
  firebaseApp = initializeApp(firebaseConfig);
}
const firebaseAnalytics = validAnalytics && getAnalytics(firebaseApp);
// const firebaseAuth = valid && firebase.auth;
const firebaseAuth = getAuth(firebaseApp);
const facebookProvider = new FacebookAuthProvider();

class FirebaseHelper {
  isValid = valid;

  EMAIL = 'email';

  FACEBOOK = 'facebook';

  GOOGLE = 'google';

  GITHUB = 'github';

  TWITTER = 'twitter';

  constructor() {
    // this.login = this.login;
    this.logout = this.logout.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    // this.firestore = this.isValid && getFirestore(firebaseApp);
    // this.database = this.isValid && getDatabase(firebaseApp);
    // this.storage = this.isValid && getStorage(firebaseApp);

    // if (this.firestore) {
    //   const settings = { timestampsInSnapshots: true };
    //   this.firestore.settings(settings);
    // }
    // this.rsf = this.isValid && new ReduxSagaFirebase(firebaseApp, firebase.firestore());
    // this.rsfFirestore = this.isValid && this.rsf.firestore;
    if (firebaseAnalytics) {
      console.info('firebase analytics instantiated.');
    }
  }

  displayErrorMessage = (error) => {
    if (this.isValid) {
      if (error.code === 'auth/user-not-found') {
        return 'Não há registro deste email. O usuário pode ter sido deletado.';
      }
      if (error.code === 'auth/email-already-exists') {
        return 'O email fornecido já está em uso. Cada usuário deve ter um email único.';
      }
      if (error.code === 'auth/email-already-in-use') {
        return 'O endereço de email já está em uso por outro usuário.';
      }
      if (error.code === 'auth/wrong-password') {
        return 'A senha é inválida';
      }
      if (error.code === 'auth/too-many-requests') {
        return 'Muitas tentativas de login. Por favor, tente novamente mais tarde.';
      }
      if (error.code === 'auth/expired-action-code') {
        return 'O e-mail de ativação já expirou.';
      }
      // "The email provided does not match the sign-in email address."
      if (error.code === 'auth/invalid-email') {
        return 'O e-mail informado está incorreto.';
      }
      if (error.code === 'auth/invalid-action-code') {
        return 'O e-mail de ativação está inválido. Isso pode ocorrer se o link já foi usado ou expirou.';
      }
      if (error.code === 'auth/wrong-change-password') {
        return 'A senha atual informada é inválida, verifique novamente o primeiro campo.';
      }
      if (error.code === 'auth/user-not-informed') {
        return 'Houve um problema com o usuário informado.';
      }
      if (error.code === 'auth/invalid-credential') {
        return 'Senha incorreta para o e-mail informado. Verifique ambos os campos.';
      }
      if (error.code === 'auth/password-reset-cooldown') {
        return `Você excedeu o limite de requisições, tente novamente em ${
          error.waitTime > 1 ? `${error.waitTime} minutos` : '1 minuto'}.`;
      }
    }
    return 'Algo deu errado!';
  };

  initializePush = () => {
    const messaging = getMessaging(firebaseApp);
    if (this.isValid && messaging.isSupported()) {
      messaging
        .requestPermission()
        .then(() => {
          console.info('Have Permission');
          return messaging.getToken();
        })
        .then(() => {
          // console.info('FCM Token:', token);
          // you probably want to send your new found FCM token to the
          // application server so that they can send any push
          // notification to you.
        })
        .catch((error) => {
          if (error.code === 'messaging/permission-blocked') {
            console.info('Please Unblock Notification Request Manually');
          } else {
            console.info('Error Occurred', error);
          }
        });

      messaging.onMessage((payload) => {
        console.info('Notification Received', payload);
        // this is the function that gets triggered when you receive a
        // push notification while you’re on the page. So you can
        // create a corresponding UI for you to have the push
        // notification handled.
      });
    }
  };

  createBatch = () => this.firestore.batch();

  login = (provider, info) => {
    if (!this.isValid) {
      return null;
    }
    switch (provider) {
      case this.EMAIL:
        return firebaseAuth().signInWithEmailAndPassword(
          info.email,
          info.password,
        );
      case this.FACEBOOK:
        return firebaseAuth().signInWithPopup(facebookProvider);
      case this.GOOGLE:
        return firebaseAuth().GoogleAuthProvider();
      case this.GITHUB:
        return firebaseAuth().GithubAuthProvider();
      case this.TWITTER:
        return firebaseAuth().TwitterAuthProvider();
      default:
        return null;
    }
  };

  logout = () => this.isValid && signOut(firebaseAuth);

  isAuthenticated = () => this.isValid && onAuthStateChanged(firebaseAuth, (user) => user);

  resetPassword = (email) => this.isValid && sendPasswordResetEmail(firebaseAuth, email);
}

export default new FirebaseHelper();
