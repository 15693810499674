const actions = {
  SET_TELEMED_MAIN_USER: 'SET_TELEMED_MAIN_USER',
  SET_OPEN_TELEMED_MODAL: 'SET_OPEN_TELEMED_MODAL',
  OPEN_USER_MEDIA_REQUEST: 'OPEN_USER_MEDIA_REQUEST',
  OPEN_USER_MEDIA_WEBCAM_SUCCESS: 'OPEN_USER_MEDIA_WEBCAM_SUCCESS',
  OPEN_USER_MEDIA_WEBCAM_ERROR: 'OPEN_USER_MEDIA_WEBCAM_ERROR',
  OPEN_PATIENT_USER_MEDIA_REQUEST: 'OPEN_PATIENT_USER_MEDIA_REQUEST',
  OPEN_PATIENT_USER_MEDIA_WEBCAM_SUCCESS: 'OPEN_PATIENT_USER_MEDIA_WEBCAM_SUCCESS',
  LOADING_OWN_WEBCAM: 'LOADING_OWN_WEBCAM',
  LOADING_PATIENT_OWN_WEBCAM: 'LOADING_PATIENT_OWN_WEBCAM',
  NO_MEDIA_DEVICES: 'NO_MEDIA_DEVICES',
  NO_VIDEO_DEVICES: 'NO_VIDEO_DEVICES',
  NO_AUDIO_DEVICES: 'NO_AUDIO_DEVICES',
  CREATE_TELEMED_ROOM_REQUEST: 'CREATE_TELEMED_ROOM_REQUEST',
  CREATE_TELEMED_ROOM_SUCCESS: 'CREATE_TELEMED_ROOM_SUCCESS',
  CREATE_TELEMED_ROOM_ERROR: 'CREATE_TELEMED_ROOM_ERROR',
  CREATING_TELEMED_ROOM: 'CREATING_TELEMED_ROOM',
  CREATE_PEER_CONNECTION_SUCCESS: 'CREATE_PEER_CONNECTION_SUCCESS',
  REGISTER_PEER_CONNECTION_LISTENERS: 'REGISTER_PEER_CONNECTION_LISTENERS',
  PEER_CONNECTION_CONNECTED: 'PEER_CONNECTION_CONNECTED',
  SET_SESSION_UUID: 'SET_SESSION_UUID',
  JOINING_TELEMED_CREATE_PEER_CONNECTION_SUCCESS: 'JOINING_TELEMED_CREATE_PEER_CONNECTION_SUCCESS',
  JOIN_TELEMED_ROOM_REQUEST: 'JOIN_TELEMED_ROOM_REQUEST',
  JOIN_TELEMED_ROOM_SUCCESS: 'JOIN_TELEMED_ROOM_SUCCESS',
  JOIN_TELEMED_ROOM_ERROR: 'JOIN_TELEMED_ROOM_ERROR',
  JOINING_TELEMED_ROOM: 'JOINING_TELEMED_ROOM',
  GET_EVENT_CANDIDATES_ERROR: 'GET_EVENT_CANDIDATES_ERROR',
  GET_REMOTE_TRACK_ERROR: 'GET_REMOTE_TRACK_ERROR',
  GET_SESSION_DESCRIPTION_ERROR: 'GET_SESSION_DESCRIPTION_ERROR',
  GET_REMOTE_ICE_CANDIDATES_ERROR: 'GET_REMOTE_ICE_CANDIDATES_ERROR',
  END_TELEMED_ROOM_REQUEST: 'END_TELEMED_ROOM_REQUEST',
  END_TELEMED_ROOM_SUCCESS: 'END_TELEMED_ROOM_SUCCESS',
  END_TELEMED_ROOM_ERROR: 'END_TELEMED_ROOM_ERROR',
  ENDING_TELEMED_ROOM: 'ENDING_TELEMED_ROOM',
  VALIDATE_PATIENT_CPF_REQUEST: 'VALIDATE_PATIENT_CPF_REQUEST',
  VALIDATE_PATIENT_CPF_SUCCESS: 'VALIDATE_PATIENT_CPF_SUCCESS',
  VALIDATE_PATIENT_CPF_ERROR: 'VALIDATE_PATIENT_CPF_ERROR',
  VALIDATING_PATIENT_CPF: 'VALIDATING_PATIENT_CPF',
  STOP_OWN_WEBCAM_REQUEST: 'STOP_OWN_WEBCAM_REQUEST',
  STOP_OWN_WEBCAM_SUCCESS: 'STOP_OWN_WEBCAM_SUCCESS',
  STOP_OWN_WEBCAM_ERROR: 'STOP_OWN_WEBCAM_ERROR',
  START_OWN_WEBCAM_REQUEST: 'START_OWN_WEBCAM_REQUEST',
  START_OWN_WEBCAM_SUCCESS: 'START_OWN_WEBCAM_SUCCESS',
  STOP_OWN_PATIENT_WEBCAM_SUCCESS: 'STOP_OWN_PATIENT_WEBCAM_SUCCESS',
  START_OWN_PATIENT_WEBCAM_SUCCESS: 'START_OWN_PATIENT_WEBCAM_SUCCESS',
  MUTE_OWN_MIC_REQUEST: 'MUTE_OWN_MIC_REQUEST',
  MUTE_OWN_MIC_SUCCESS: 'MUTE_OWN_MIC_SUCCESS',
  UNMUTE_OWN_MIC_REQUEST: 'UNMUTE_OWN_MIC_REQUEST',
  UNMUTE_OWN_MIC_SUCCESS: 'UNMUTE_OWN_MIC_SUCCESS',
  CREATE_DATA_CHANNEL_SUCCESS: 'CREATE_DATA_CHANNEL_SUCCESS',
  DATA_CHANNEL_STATE_IS_OPEN: 'DATA_CHANNEL_STATE_IS_OPEN',
  DATA_CHANNEL_STATE_IS_CLOSE: 'DATA_CHANNEL_STATE_IS_CLOSE',
  DATA_CHANNEL_MESSAGES_ARE_READY: 'DATA_CHANNEL_MESSAGES_ARE_READY',
  REMOTE_MIC_MUTED: 'REMOTE_MIC_MUTED',
  REMOTE_MIC_UNMUTED: 'REMOTE_MIC_UNMUTED',
  REMOTE_CAM_OFF: 'REMOTE_CAM_OFF',
  REMOTE_CAM_ON: 'REMOTE_CAM_ON',
  SAVE_CHAT_MESSAGES: 'SAVE_CHAT_MESSAGES',
  SEND_CHAT_MESSAGE: 'SEND_CHAT_MESSAGE',
  RECEIVED_CHAT_MESSAGE: 'RECEIVED_CHAT_MESSAGE',
  OTHER_PEER_ENDED_CALL: 'OTHER_PEER_ENDED_CALL',
  HANDSHAKE_RECEIVED: 'HANDSHAKE_RECEIVED',
  REMOTE_PEER_HANDSHAKE_RECEIVED: 'REMOTE_PEER_HANDSHAKE_RECEIVED',
  setOpenTelemedModal: (value, roomId = null, selectedData = null) => ({
    type: actions.SET_OPEN_TELEMED_MODAL,
    payload: {
      value,
      roomId,
      selectedData,
    },
  }),
  openUserMediaWebcam: () => ({
    type: actions.OPEN_USER_MEDIA_REQUEST,
  }),
  openPatientUserMediaWebcam: () => ({
    type: actions.OPEN_PATIENT_USER_MEDIA_REQUEST,
  }),
  createTelemedRoom: (roomId) => ({
    type: actions.CREATE_TELEMED_ROOM_REQUEST,
    payload: {
      roomId,
    },
  }),
  joinTelemedRoom: (roomId, mainUser) => ({
    type: actions.JOIN_TELEMED_ROOM_REQUEST,
    payload: {
      roomId,
      mainUser,
    },
  }),
  endTelemedRoom: (roomId) => ({
    type: actions.END_TELEMED_ROOM_REQUEST,
    payload: {
      roomId,
    },
  }),
  validatedPatientCpf: (value, queryConfirmedObj) => ({
    type: actions.VALIDATE_PATIENT_CPF_REQUEST,
    payload: {
      cpf: value,
      queryConfirmedObj,
    },
  }),
  stopOwnWebcam: () => ({
    type: actions.STOP_OWN_WEBCAM_REQUEST,
  }),
  startOwnWebcam: () => ({
    type: actions.START_OWN_WEBCAM_REQUEST,
  }),
  muteOwnMic: () => ({
    type: actions.MUTE_OWN_MIC_REQUEST,
  }),
  unmuteOwnMic: () => ({
    type: actions.UNMUTE_OWN_MIC_REQUEST,
  }),
  sendChatMessage: (userType, text) => ({
    type: actions.SEND_CHAT_MESSAGE,
    payload: {
      userType,
      text,
    },
  }),
};

export default actions;
