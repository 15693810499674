const messagesActions = {
  FETCH_MESSAGES_SETTINGS_REQUEST: 'FETCH_MESSAGES_SETTINGS_REQUEST',
  FETCH_MESSAGES_SETTINGS_SUCCESS: 'FETCH_MESSAGES_SETTINGS_SUCCESS',
  FETCH_MESSAGES_SETTINGS_ERROR: 'FETCH_MESSAGES_SETTINGS_ERROR',
  CHANGE_SEND_AUTOMATIC_MESSAGES: 'CHANGE_SEND_AUTOMATIC_MESSAGES',
  CHANGE_SEND_AUTOMATIC_MESSAGES_ERROR: 'CHANGE_SEND_AUTOMATIC_MESSAGES_ERROR',
  UPDATING_SEND_AUTOMATIC_MESSAGES: 'UPDATING_SEND_AUTOMATIC_MESSAGES',
  CHANGE_DEFAULT_SEND_MESSAGES_STATE: 'CHANGE_DEFAULT_SEND_MESSAGES_STATE',
  CHANGE_DEFAULT_SEND_MESSAGES_STATE_ERROR: 'CHANGE_DEFAULT_SEND_MESSAGES_STATE_ERROR',
  UPDATING_DEFAULT_SEND_MESSAGES_STATE: 'UPDATING_DEFAULT_SEND_MESSAGES_STATE',
  FETCH_MESSAGE_MODELS_REQUEST: 'FETCH_MESSAGE_MODELS_REQUEST',
  FETCH_MESSAGE_MODELS_SUCCESS: 'FETCH_MESSAGE_MODELS_SUCCESS',
  FETCH_MESSAGE_MODELS_ERROR: 'FETCH_MESSAGE_MODELS_ERROR',
  SAVE_MESSAGE_MODELS_REQUEST: 'SAVE_MESSAGE_MODELS_REQUEST',
  SAVE_MESSAGE_MODELS_SUCCESS: 'SAVE_MESSAGE_MODELS_SUCCESS',
  SAVE_MESSAGE_MODELS_ERROR: 'SAVE_MESSAGE_MODELS_ERROR',
  START_SAVING_MESSAGE_MODEL: 'START_SAVING_MESSAGE_MODEL',
  DISCARD_MESSAGE_MODELS_CHANGES: 'DISCARD_MESSAGE_MODELS_CHANGES',
  DELETE_MESSAGE_MODEL: 'DELETE_MESSAGE_MODEL',
  FETCH_PATIENT_MOBILE_REQUEST: 'FETCH_PATIENT_MOBILE_REQUEST',
  FETCH_PATIENT_MOBILE_SUCCESS: 'FETCH_PATIENT_MOBILE_SUCCESS',
  FETCH_PATIENT_MOBILE_ERROR: 'FETCH_PATIENT_MOBILE_ERROR',
  CHECK_IF_QUERYCONFIRMED_EXISTS: 'CHECK_IF_QUERYCONFIRMED_EXISTS',
  CHECK_IF_QUERYCONFIRMED_EXISTS_TRUE: 'CHECK_IF_QUERYCONFIRMED_EXISTS_TRUE',
  CHECK_IF_QUERYCONFIRMED_EXISTS_FALSE: 'CHECK_IF_QUERYCONFIRMED_EXISTS_FALSE',
  CHECK_IF_QUERYCONFIRMED_EXISTS_ERROR: 'CHECK_IF_QUERYCONFIRMED_EXISTS_ERROR',
  SET_TOMORROW_APPOINTMENTS_NOT_CONFIRMED_COUNT: 'SET_TOMORROW_APPOINTMENTS_NOT_CONFIRMED_COUNT',
  SET_TWILIO_SETTINGS: 'SET_TWILIO_SETTINGS',
  changeSendAutomaticMessages: (value, userId) => ({
    type: messagesActions.CHANGE_SEND_AUTOMATIC_MESSAGES,
    payload: {
      value,
      userId,
    },
  }),
  changeDefaultSendMessageState: (value, userId) => ({
    type: messagesActions.CHANGE_DEFAULT_SEND_MESSAGES_STATE,
    payload: {
      value,
      userId,
    },
  }),
  getMessageModels: () => ({
    type: messagesActions.FETCH_MESSAGE_MODELS_REQUEST,
  }),
  saveMessageModels: (content) => ({
    type: messagesActions.SAVE_MESSAGE_MODELS_REQUEST,
    payload: {
      content,
    },
  }),
  saveDefaultConfirmationMessage: () => ({
    type: messagesActions.SAVE_MESSAGE_MODELS_REQUEST,
    payload: {
      useDefault: true,
    },
  }),
  discardConfirmationMessageChanges: () => ({
    type: messagesActions.DISCARD_MESSAGE_MODELS_CHANGES,
  }),
  deleteMessageModel: (id) => ({
    type: messagesActions.DELETE_MESSAGE_MODEL,
    payload: id,
  }),
  fetchPatientMobile: (patientId) => ({
    type: messagesActions.FETCH_PATIENT_MOBILE_REQUEST,
    payload: {
      patientId,
    },
  }),
  checkIfQueryConfirmedExists: (event) => ({
    type: messagesActions.CHECK_IF_QUERYCONFIRMED_EXISTS,
    payload: {
      event,
    },
  }),
  setTomorrowApponitmentsNotConfirmedCount: (value) => ({
    type: messagesActions.SET_TOMORROW_APPOINTMENTS_NOT_CONFIRMED_COUNT,
    payload: value,
  }),
};
export default messagesActions;
