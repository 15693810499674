import actions from './actions';
import authActions from '../auth/actions';

const initState = {
  confirmed: false,
  rejected: false,
  updating: false,
};

export default function patientAppointments(state = initState, action) {
  switch (action.type) {
    case authActions.CLEAR_STATES:
      return {
        ...initState,
      };
    case actions.PATIENT_PENDING_FETCH_SUCCESS:
      return {
        ...state,
        pending: action.payload.data,
        updating: false,
      };
    case actions.PATIENT_CONFIRMED_FETCH_SUCCESS:
      return {
        ...state,
        confirmed: action.payload.data,
        updating: false,
      };
    case actions.PATIENT_REJECTED_FETCH_SUCCESS:
      return {
        ...state,
        rejected: action.payload.data,
        updating: false,
      };
    case actions.PATIENT_RESPONDED_REQUEST_PENDING:
      return {
        ...state,
        updating: action.payload,
      };
    case actions.PATIENT_RESPONDED_REQUEST_SUCCESS:
      return {
        ...state,
        patientResponse: 'confirmed',
        // updating: false,
      };
    case actions.PATIENT_RESPONDED_REQUEST_REJECTED:
      return {
        ...state,
        patientResponse: 'canceled',
        updating: false,
      };
    case actions.PATIENT_RESPONDED_REQUEST_ERROR:
      return {
        ...state,
        updating: false,
      };
    case actions.APPOINTMENT_INFO_REQUEST:
      return {
        ...state,
        missingPatientCpf: false,
      };
    case actions.APPOINTMENT_INFO_REQUEST_SUCCESS:
      return {
        ...state,
        appointmentInfo: action.payload.data,
        updating: false,
        correctLink: action.payload.link,
        updatingTelemedCompliance: false,
      };
    case actions.APPOINTMENT_INFO_REQUEST_REJECTED:
      return {
        ...state,
        appointmentInfo: null,
        updating: false,
        updatingTelemedCompliance: null,
      };
    case actions.FETCH_TELEMED_DOCUMENT_SUCCESS:
      return {
        ...state,
        downloadingTelemedDocument: false,
        telemedDocument: action.payload.document,
      };
    case actions.FETCHING_TELEMED_DOCUMENT:
      return {
        ...state,
        downloadingTelemedDocument: true,
      };
    case actions.FETCH_TELEMED_DOCUMENT_ERROR:
      return {
        ...state,
        downloadingTelemedDocument: null,
      };
    case actions.UPDATING_TELEMED_COMPLIANCE:
      return {
        ...state,
        updatingTelemedCompliance: true,
      };
    case actions.NO_PATIENT_CPF:
      return {
        ...state,
        missingPatientCpf: true,
      };
    default:
      return state;
  }
}
