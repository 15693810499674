import React, {
  useState,
  useEffect,
  useRef,
} from 'react';
import { connect } from 'react-redux';
import {
  PhoneOutlined,
  VideoCameraOutlined,
  AudioMutedOutlined,
  AudioOutlined,
  UserOutlined,
  MessageOutlined,
  FullscreenOutlined,
  FullscreenExitOutlined,
  WarningFilled,
  ExclamationCircleFilled,
} from '@ant-design/icons';
import {
  Button,
  Avatar,
  Popover,
  Badge,
  Alert,
} from 'antd';
import telemedActions from '../../redux/telemed/actions';
import './style.css';

function usePrevious(value) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

function TelemedVideos({
  accessUserMedia,
  loadingOwnWebcam,
  loadingPatientOwnWebcam,
  userMediaAvailable,
  userMediaWebcamDisabled,
  userMediaError,
  noMediaDevices,
  noVideoDevices,
  noAudioDevices,
  endTelemedRoom,
  roomId,
  // joinedTelemedRoom,
  peerConnectionConnected,
  dataChannelOpen,
  stopOwnWebcam,
  startOwnWebcam,
  ownMicMuted,
  remoteMicMuted,
  muteOwnMic,
  unmuteOwnMic,
  remoteCamOff,
  endingTelemedRoom,
  professional,
  modalSize,
  showChatIcon,
  showChat,
  setShowChat,
  smallScreenMode,
  showFullScreenIcon,
  fullScreenMode,
  setFullScreenMode,
  isModal,
  isModalOpen,
  telemedChatMessages,
  initiatedCall,
  otherPeerEndedCall,
}) {
  const [chatBadgeCount, setChatBadgeCount] = useState(0);

  useEffect(() => {
    if (!smallScreenMode) {
      if (modalSize === 'small') {
        setShowChat(false);
      }
      if (modalSize === 'normal') {
        setShowChat(true);
      }
    }
  }, [smallScreenMode, modalSize, setShowChat]);

  const renderCover = () => {
    if (isModal && !isModalOpen) {
      return null;
    }
    if (noMediaDevices) {
      // No media devices
      return (
        <div className="webcam-off">
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <WarningFilled style={{ color: '#faad14', fontSize: '32px' }} />
            <p style={{ marginTop: '10px', color: '#fff', fontSize: '16px' }}>
              Nenhum dispositivo de áudio ou vídeo detectado
            </p>
          </div>
        </div>
      );
    }
    if (userMediaError) {
      // UserMedia was denied
      return (
        <div className="webcam-off">
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <p style={{ marginBottom: '10px', color: '#fff', fontSize: '16px' }}>
              Ativar câmera e microfone?
            </p>
            <Button
              type="primary"
              style={{ outline: 'none' }}
              onClick={accessUserMedia}
            >
              Permitir o acesso ao microfone e à câmera
            </Button>
          </div>
        </div>
      );
    }
    if (!userMediaAvailable && !userMediaError) {
      // UserMedia is not available yet and not denied either
      let text = 'Aguardando câmera e microfone...';
      if (noVideoDevices) {
        text = 'Aguardando câmera...';
      }
      if (noAudioDevices) {
        text = 'Aguardando microfone...';
      }
      return (
        <div className="webcam-off">
          <p style={{ marginBottom: 0, color: '#fff', fontSize: '16px' }}>
            {text}
          </p>
        </div>
      );
    }
    if (userMediaAvailable) {
      if (noVideoDevices && (!peerConnectionConnected || !dataChannelOpen)) {
        return (
          <div className="webcam-off">
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <p style={{ color: '#fff', fontSize: '16px', marginBottom: 0 }}>
                Nenhuma câmera encontrada
              </p>
            </div>
          </div>
        );
      }
      if (userMediaWebcamDisabled || noVideoDevices) {
        let webcamOffClassName = 'webcam-off';
        if (peerConnectionConnected && dataChannelOpen) {
          webcamOffClassName = `${webcamOffClassName} joined`;
        }
        return (
          <div className={webcamOffClassName}>
            <Avatar
              size={peerConnectionConnected && dataChannelOpen ? 32 : 80}
              // size={32}
              icon={<UserOutlined />}
              style={{
                backgroundColor: '#b6b6b6',
              }}
            />
          </div>
        );
      }
    }
    return null;
  };

  const renderInfoAlert = () => {
    if ((peerConnectionConnected && dataChannelOpen) || !showChatIcon) {
      return null;
    }
    if ((otherPeerEndedCall && initiatedCall) || (endingTelemedRoom && endingTelemedRoom !== 'retry')) {
      return (
        <Alert
          message="Encerrando a chamada"
          banner
          type="info"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
          }}
        />
      );
    }
    if (roomId && professional) {
      let text = 'Aguardando o profissional se conectar';
      if (professional) {
        text = 'Aguardando o paciente se conectar';
      }
      return (
        <Alert
          message={text}
          banner
          type="info"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
          }}
        />
      );
    }
    return null;
  };

  const renderLocalVideo = () => {
    const styles = {};
    if (loadingOwnWebcam) {
      styles.backgroundColor = '#000';
    }
    let className = 'localVideo';
    // let webcamOffClassName = 'webcam-off';
    if (peerConnectionConnected && dataChannelOpen) {
      className = `${className} joined`;
      styles.backgroundColor = '#333';
      // webcamOffClassName = `${webcamOffClassName} joined`;
    }
    return (
      <div className="localVideo-wrapper">
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video id="localVideo" className={className} playsInline muted autoPlay style={styles} />
        {renderCover()}
        {renderInfoAlert()}
        {/* {!userMediaAvailable ? (
          <div className={webcamOffClassName}>
            <Avatar
              size={peerConnectionConnected && dataChannelOpen ? 32 : 80}
              // size={32}
              icon={<UserOutlined />}
              style={{
                backgroundColor: '#b6b6b6',
              }}
            />
          </div>
        ) : null} */}
      </div>
    );
  };

  const renderRemoteVideo = () => {
    const styles = {};
    let className = 'remoteVideo';
    className = `${className} joined`;
    styles.backgroundColor = '#000';
    if (!peerConnectionConnected || !dataChannelOpen) {
      styles.display = 'none';
    }
    return (
      <div className="remoteVideo-wrapper">
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video id="remoteVideo" className={className} playsInline autoPlay style={styles} />
        {remoteMicMuted && peerConnectionConnected && dataChannelOpen ? (
          <div className="remote-mic-icon-wrapper">
            <AudioMutedOutlined />
          </div>
        ) : null}
        {remoteCamOff && peerConnectionConnected && dataChannelOpen ? (
          <div className="webcam-off">
            <Avatar
              size={80}
              icon={<UserOutlined />}
              style={{
                backgroundColor: '#b6b6b6',
              }}
            />
          </div>
        ) : null}
      </div>
    );
  };

  const renderCameraButton = () => {
    if (noVideoDevices) {
      return (
        <div className="webcam-off-button-wrapper">
          <div className="diagonal-line" />
          <Badge
            offset={[-5, 2]}
            count={(
              <ExclamationCircleFilled style={{ color: '#faad14' }} />
            )}
          >
            <Popover content="Nenhuma câmera detectada" trigger="click">
              <Button
                shape="circle"
                icon={<VideoCameraOutlined />}
                type="primary"
                danger
              />
            </Popover>
          </Badge>
        </div>
      );
    }
    if (!userMediaWebcamDisabled) {
      return (
        <Button
          shape="circle"
          icon={<VideoCameraOutlined />}
          type="primary"
          onClick={stopOwnWebcam}
          style={{
            backgroundColor: '#555',
            color: '#fff',
          }}
        />
      );
    }
    return (
      <div className="webcam-off-button-wrapper">
        <div className="diagonal-line" />
        <Button
          shape="circle"
          icon={<VideoCameraOutlined />}
          type="primary"
          danger
          onClick={startOwnWebcam}
        />
      </div>
    );
    // return null;
  };

  const renderMicButton = () => {
    if (noAudioDevices) {
      return (
        <Badge
          offset={[-5, 2]}
          count={(
            <ExclamationCircleFilled style={{ color: '#faad14' }} />
          )}
        >
          <Popover content="Nenhum microfone detectado" trigger="click">
            <Button
              shape="circle"
              icon={<AudioMutedOutlined />}
              type="primary"
              danger
            />
          </Popover>
        </Badge>
      );
    }
    if (!ownMicMuted) {
      return (
        <Button
          shape="circle"
          icon={<AudioOutlined />}
          type="primary"
          onClick={muteOwnMic}
          style={{
            backgroundColor: '#555',
            color: '#fff',
          }}
        />
      );
    }
    return (
      <Button
        shape="circle"
        icon={<AudioMutedOutlined />}
        type="primary"
        danger
        onClick={unmuteOwnMic}
      />
    );
    // return null;
  };

  const renderEndButton = () => {
    if (peerConnectionConnected || dataChannelOpen || professional) {
      return (
        <Button
          shape="circle"
          icon={<PhoneOutlined rotate={225} />}
          type="primary"
          danger
          loading={endingTelemedRoom}
          // disabled={endingTelemedRoom}
          onClick={() => endTelemedRoom(roomId)}
        />
      );
    }
    return null;
  };

  const prevTelemedChatMessages = usePrevious(telemedChatMessages);
  useEffect(() => {
    if (telemedChatMessages && prevTelemedChatMessages) {
      const chatDifference = telemedChatMessages.filter((x) => !prevTelemedChatMessages.find((y) => x.id === y.id));
      if (chatDifference?.length > 0 && !showChat) {
        setChatBadgeCount(chatDifference.length);
      }
    }
  }, [prevTelemedChatMessages, telemedChatMessages, showChat]);

  const renderChatButton = () => {
    if (showChatIcon) {
      if (showChat) {
        return (
          <Button
            shape="circle"
            icon={<MessageOutlined />}
            type="primary"
            onClick={() => setShowChat(false)}
            style={{
              backgroundColor: '#555',
              color: '#fff',
            }}
          />
        );
      }
      return (
        <Badge
          count={chatBadgeCount}
          size="small"
          offset={[-5, 2]}
        >
          <Button
            shape="circle"
            icon={<MessageOutlined />}
            type="primary"
            onClick={() => {
              setShowChat(true);
              setChatBadgeCount(0);
            }}
            style={{
              backgroundColor: '#1890ff',
              color: '#fff',
            }}
          />
        </Badge>
      );
    }
    return null;
  };

  const renderFullScreenButton = () => {
    if (showFullScreenIcon) {
      if (!fullScreenMode && (!peerConnectionConnected || !dataChannelOpen)) {
        return null;
      }
      // if ((peerConnectionConnected || dataChannelOpen || professional) && fullScreenMode) {
      // }
      return (
        <div className="full-screen-button-wrapper">
          <Button
            shape="circle"
            icon={fullScreenMode ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
            type="primary"
            onClick={() => setFullScreenMode(!fullScreenMode)}
            style={{
              backgroundColor: '#555',
              color: '#fff',
              outline: 'none',
            }}
          />
        </div>
      );
    }
    return null;
  };

  return (
    <div className="video-section-container">
      <div id="videos">
        {renderFullScreenButton()}
        {renderLocalVideo()}
        {renderRemoteVideo()}
        <div className={`onScreen-buttons ${modalSize || ''}`}>
          {!loadingOwnWebcam && !loadingPatientOwnWebcam ? (
            <>
              {renderCameraButton()}
              {renderMicButton()}
              {renderChatButton()}
              {renderEndButton()}
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default connect((state) => ({
  loadingOwnWebcam: state.Telemed.loadingOwnWebcam,
  loadingPatientOwnWebcam: state.Telemed.loadingPatientOwnWebcam,
  roomId: state.Telemed.roomId,
  joinedTelemedRoom: state.Telemed.joinedTelemedRoom,
  peerConnectionConnected: state.Telemed.peerConnectionConnected,
  dataChannelOpen: state.Telemed.dataChannelOpen,
  ownMicMuted: state.Telemed.ownMicMuted,
  remoteMicMuted: state.Telemed.remoteMicMuted,
  remoteCamOff: state.Telemed.remoteCamOff,
  endingTelemedRoom: state.Telemed.endingTelemedRoom,
  userMediaError: state.Telemed.userMediaError,
  noMediaDevices: state.Telemed.noMediaDevices,
  noVideoDevices: state.Telemed.noVideoDevices,
  noAudioDevices: state.Telemed.noAudioDevices,
  telemedChatMessages: state.Telemed.telemedChatMessages,
  otherPeerEndedCall: state.Telemed.otherPeerEndedCall,
}), {
  ...telemedActions,
})(TelemedVideos);
