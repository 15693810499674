import actions from './actions';
import authActions from '../auth/actions';

const initState = {
  openTelemedModal: false,
  createRoomAttempts: 0,
  telemedChatMessages: [],
};

export default function telemedReducer(state = initState, action) {
  switch (action.type) {
    case authActions.CLEAR_STATES:
      return {
        ...initState,
      };
    case actions.SET_OPEN_TELEMED_MODAL:
      return {
        ...state,
        openTelemedModal: action.payload.value,
        roomId: action.payload.roomId,
        telemedAppointmentData: action.payload.selectedData,
      };
    case actions.LOADING_OWN_WEBCAM:
      return {
        ...state,
        loadingOwnWebcam: true,
        userMediaError: false,
        noVideoDevices: false,
      };
    case actions.LOADING_PATIENT_OWN_WEBCAM:
      return {
        ...state,
        loadingPatientOwnWebcam: true,
        userMediaError: false,
        noVideoDevices: false,
      };
    case actions.OPEN_USER_MEDIA_WEBCAM_SUCCESS:
      return {
        ...state,
        userMediaAvailable: true,
        loadingOwnWebcam: false,
        userType: 'professional',
        localStreamAvailable: true,
        userMediaWebcamDisabled: false,
      };
    case actions.OPEN_PATIENT_USER_MEDIA_WEBCAM_SUCCESS:
      return {
        ...state,
        userPatientMediaAvailable: true,
        loadingPatientOwnWebcam: false,
        userType: 'patient',
        userPatientMediaWebcamDisabled: false,
      };
    case actions.OPEN_USER_MEDIA_WEBCAM_ERROR:
      return {
        ...state,
        userMediaError: true,
      };
    case actions.NO_MEDIA_DEVICES:
      return {
        ...state,
        noMediaDevices: true,
      };
    case actions.NO_VIDEO_DEVICES:
      return {
        ...state,
        noVideoDevices: true,
      };
    case actions.NO_AUDIO_DEVICES:
      return {
        ...state,
        noAudioDevices: true,
      };
    case actions.END_TELEMED_ROOM_SUCCESS:
      return {
        ...state,
        roomId: null,
        telemedAppointmentData: null,
        userMediaAvailable: false,
        ownMicMuted: false,
        openTelemedModal: false,
        userPatientMediaAvailable: false,
        createRoomAttempts: 0,
        endingTelemedRoom: false,
        telemedEnded: true,
        localStreamAvailable: false,
        telemedMainUser: null,
      };
    case actions.JOIN_TELEMED_ROOM_REQUEST:
      return {
        ...state,
        endingTelemedRoom: false,
        telemedEnded: false,
        otherPeerEndedCall: false,
      };
    case actions.CREATE_TELEMED_ROOM_REQUEST:
      return {
        ...state,
        endingTelemedRoom: false,
        telemedEnded: false,
        otherPeerEndedCall: false,
      };
    case actions.SET_TELEMED_MAIN_USER:
      return {
        ...state,
        telemedMainUser: action.payload,
      };
    case actions.VALIDATING_PATIENT_CPF:
      return {
        ...state,
        validatingPatientCpf: true,
      };
    case actions.VALIDATE_PATIENT_CPF_SUCCESS:
      return {
        ...state,
        validatingPatientCpf: false,
        patientCpfValidated: true,
      };
    case actions.VALIDATE_PATIENT_CPF_ERROR:
      return {
        ...state,
        validatingPatientCpf: null,
      };
    case actions.CREATING_TELEMED_ROOM:
      return {
        ...state,
        createRoomAttempts: action.payload.counter,
      };
    case actions.SET_SESSION_UUID:
      return {
        ...state,
        sessionUuid: action.payload.sessionUuid,
      };
    case actions.JOIN_TELEMED_ROOM_SUCCESS:
      return {
        ...state,
        joinedTelemedRoom: true,
        roomId: action.payload.roomId,
        sessionUuid: action.payload.sessionUuid,
      };
    case actions.PEER_CONNECTION_CONNECTED:
      return {
        ...state,
        peerConnectionConnected: true,
      };
    case actions.DATA_CHANNEL_STATE_IS_OPEN:
      return {
        ...state,
        dataChannelOpen: true,
        createRoomAttempts: 0,
      };
    case actions.DATA_CHANNEL_STATE_IS_CLOSE:
      return {
        ...state,
        dataChannelOpen: false,
        handshake: false,
        remotePeerHandshakeReceived: false,
      };
    case actions.END_TELEMED_ROOM_REQUEST:
      return {
        ...state,
        sessionUuid: null,
      };
    case actions.ENDING_TELEMED_ROOM:
      return {
        ...state,
        endingTelemedRoom: action.payload,
        joinedTelemedRoom: false,
        peerConnectionConnected: false,
      };
    case actions.STOP_OWN_WEBCAM_SUCCESS:
      return {
        ...state,
        userMediaWebcamDisabled: true,
      };
    case actions.START_OWN_WEBCAM_SUCCESS:
      return {
        ...state,
        userMediaWebcamDisabled: false,
      };
    case actions.STOP_OWN_PATIENT_WEBCAM_SUCCESS:
      return {
        ...state,
        userPatientMediaWebcamDisabled: true,
      };
    case actions.START_OWN_PATIENT_WEBCAM_SUCCESS:
      return {
        ...state,
        userPatientMediaWebcamDisabled: false,
      };
    case actions.MUTE_OWN_MIC_SUCCESS:
      return {
        ...state,
        ownMicMuted: true,
      };
    case actions.UNMUTE_OWN_MIC_SUCCESS:
      return {
        ...state,
        ownMicMuted: false,
      };
    case actions.REMOTE_MIC_MUTED:
      return {
        ...state,
        remoteMicMuted: true,
      };
    case actions.REMOTE_MIC_UNMUTED:
      return {
        ...state,
        remoteMicMuted: false,
      };
    case actions.REMOTE_CAM_OFF:
      return {
        ...state,
        remoteCamOff: true,
      };
    case actions.REMOTE_CAM_ON:
      return {
        ...state,
        remoteCamOff: false,
      };
    case actions.SAVE_CHAT_MESSAGES:
      return {
        ...state,
        telemedChatMessages: action.payload.messages,
      };
    case actions.OTHER_PEER_ENDED_CALL:
      return {
        ...state,
        otherPeerEndedCall: true,
      };
    case actions.HANDSHAKE_RECEIVED:
      return {
        ...state,
        handshake: true,
      };
    case actions.REMOTE_PEER_HANDSHAKE_RECEIVED:
      return {
        ...state,
        remotePeerHandshakeReceived: true,
      };
    default:
      return state;
  }
}
