import _ from 'lodash';
import {
  getAuth,
} from 'firebase/auth';
import {
  getDatabase,
  ref,
  update,
  push,
  get,
  child,
} from 'firebase/database';
import {
  getStorage,
  ref as sRef,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from 'firebase/storage';
import moment from 'moment-timezone';
import {
  all,
  takeEvery,
  fork,
  call,
  put,
  select,
  take,
} from 'redux-saga/effects';
import actions from './actions';
import profileActions from '../profile/actions';

const defaultPdfSettings = {
  header: {
    logoMode: 'withoutLogo',
    fullWidthLogo: false,
    fontSize: 14,
    renderHeader: true,
    renderDateSpecialPrescription: false,
  },
  content: {
    certificateMainTextFontSize: 12,
    showRegularPrescriptionPatientInfo: false,
    showSpecialPrescriptionPatientInfo: true,
    patientNameFontSize: 14,
    patientAddressFontSize: 14,
    prescriptionsMedicationFontSize: 12,
    prescriptionsUseModeFontSize: 12,
    prescriptionsDescriptionFontSize: 12, // Posologia
    prescriptionsQuantityFontSize: 12,
    examsItemFontSize: 12,
    examsQuantityFontSize: 12,
    examsObsFontSize: 12,
    opticalObservationFontSize: 12,
  },
  footer: {
    renderFooter: true,
    renderSignature: 'show',
    renderSignatureDate: 'show',
    renderAddress: 'show',
    signatureFontSize: 10,
    signatureDateFontSize: 10,
    addressFontSize: 12,
  },
  pageSize: 'A4',
  headerMargin: 0,
  footerMargin: 0,
  // headerMarginA5: 0,
  // footerMarginA5: 0,
  leftMargin: 0,
  rightMargin: 0,
  // leftMarginA5: 0,
  // rightMarginA5: 0,
  specialPrescriptionDate: moment().tz('America/Sao_Paulo').format(),
  signatureDate: moment().tz('America/Sao_Paulo').format(),
  // pageMode: 'blank',
};

const getCustomHeaderLogo = (state) => state.Pdf.logoFile;

const getPdfSettingsFromStore = (state) => state.Pdf.pdfSettings;

const getPdfSettingsOnDBFromStore = (state) => state.Pdf.pdfSettingsOnDB;

const getSelectedPdfProfileFromStore = (state) => state.Pdf.selectedPdfProfile;

// const getPdfProfilesArrFromStore = (state) => state.Pdf.pdfProfilesArr;

const getSelectedProfessionalPdfProfileFromStore = (state) => state.Pdf.selectedProfessionalPdfProfile;

const getAllUsersPdfSettingsFromStore = (state) => state.Pdf.allUsersPdfSettings;

const getMainUserFromStore = (state) => state.Auth.mainUser;

// const getHealthProfessionalFromStore = (state) => state.Auth.healthProfessional;

const getUserFromStore = (state) => state.Auth.user;

const getProfileFromStore = (state) => state.Profile.profileOnDB;

const getCustomUserFromStore = (state) => state.CustomUsers.customUsers;

function saveCustomLogoOnDB(id, pdfProfile, file, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  const storage = getStorage();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  if (file) {
    const avatarImagesRef = sRef(storage, `public/${uid}/images/pdfSettings/${id}/profiles/${pdfProfile}/customLogo`);
    return uploadBytes(avatarImagesRef, file)
      .then((response) => (
        // response.ref.getDownloadURL()
        getDownloadURL(response.ref)
      ))
      .then((url) => {
        const updates = {};
        updates[`users/${uid}/pdfSettings/${id}/profiles/${pdfProfile}/logo`] = url || null;
        // updates[`users/${uid}/pdf/mode`] = 'custom';
        // updates[`users/${uid}/pdf/text`] = text || null;
        return update(dbRef, updates);
      })
      .catch((error) => new Error(error));
  }
  return true;
}

// function saveLogoOnDefaultProfileOnDB(pdfProfile, url, mainUser) {
//   const db = getDatabase();
//   const dbRef = ref(db);
//   let uid;
//   if (mainUser) {
//     uid = mainUser;
//   } else {
//     const auth = getAuth();
//     const { currentUser } = auth;
//     ({ uid } = currentUser);
//   }
//   const updates = {};
//   updates[`users/${uid}/pdf/profiles/${pdfProfile}/logo`] = url || null;
//   return update(dbRef, updates);
// }

function savePdfSettingsOnDB(id, pdfProfile, pdfSettings, mainUser, saveLogo = false) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  if (pdfSettings?.header) {
    updates[`users/${uid}/pdfSettings/${id}/profiles/${pdfProfile}/header`] = pdfSettings.header;
  }
  if (pdfSettings?.content) {
    updates[`users/${uid}/pdfSettings/${id}/profiles/${pdfProfile}/content`] = pdfSettings.content;
  }
  if (pdfSettings?.footer) {
    updates[`users/${uid}/pdfSettings/${id}/profiles/${pdfProfile}/footer`] = pdfSettings.footer;
  }
  // if (pdfSettings?.pageSize) {
  //   updates[`users/${uid}/pdfSettings/${id}/${pdfProfile}/pageSize`] = pdfSettings.pageSize;
  // }
  if (pdfSettings?.headerMargin || pdfSettings?.headerMargin === 0) {
    updates[`users/${uid}/pdfSettings/${id}/profiles/${pdfProfile}/headerMargin`] = pdfSettings.headerMargin;
  }
  if (pdfSettings?.footerMargin || pdfSettings?.footerMargin === 0) {
    updates[`users/${uid}/pdfSettings/${id}/profiles/${pdfProfile}/footerMargin`] = pdfSettings.footerMargin;
  }
  // if (pdfSettings?.headerMarginA5 || pdfSettings?.headerMarginA5 === 0) {
  //   updates[`users/${uid}/pdfSettings/${id}/profiles/${pdfProfile}/headerMarginA5`] = pdfSettings.headerMarginA5;
  // }
  // if (pdfSettings?.footerMarginA5 || pdfSettings?.footerMarginA5 === 0) {
  //   updates[`users/${uid}/pdfSettings/${id}/profiles/${pdfProfile}/footerMarginA5`] = pdfSettings.footerMarginA5;
  // }
  if (pdfSettings?.leftMargin || pdfSettings?.leftMargin === 0) {
    updates[`users/${uid}/pdfSettings/${id}/profiles/${pdfProfile}/leftMargin`] = pdfSettings.leftMargin;
  }
  if (pdfSettings?.rightMargin || pdfSettings?.rightMargin === 0) {
    updates[`users/${uid}/pdfSettings/${id}/profiles/${pdfProfile}/rightMargin`] = pdfSettings.rightMargin;
  }
  // if (pdfSettings?.leftMarginA5 || pdfSettings?.leftMarginA5 === 0) {
  //   updates[`users/${uid}/pdfSettings/${id}/profiles/${pdfProfile}/leftMarginA5`] = pdfSettings.leftMarginA5;
  // }
  // if (pdfSettings?.rightMarginA5 || pdfSettings?.rightMarginA5 === 0) {
  //   updates[`users/${uid}/pdfSettings/${id}/profiles/${pdfProfile}/rightMarginA5`] = pdfSettings.rightMarginA5;
  // }
  if (pdfSettings?.pageMode) {
    updates[`users/${uid}/pdfSettings/${id}/profiles/${pdfProfile}/pageMode`] = pdfSettings.pageMode;
  }
  if (pdfSettings?.profileName) {
    updates[`users/${uid}/pdfSettings/${id}/profiles/${pdfProfile}/profileName`] = pdfSettings.profileName;
  }
  if (pdfSettings?.logo && saveLogo) {
    updates[`users/${uid}/pdfSettings/${id}/profiles/${pdfProfile}/logo`] = pdfSettings.logo;
  }
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* savePdfSettings() {
  yield takeEvery(actions.UPLOAD_PDF_SETTINGS_REQUEST, function* () {
    try {
      yield put({
        type: actions.UPLOAD_PDF_SETTINGS_START_UPDATE,
      });
      // let response;
      const pdfSettings = yield select(getPdfSettingsFromStore);
      const pdfProfile = yield select(getSelectedPdfProfileFromStore);
      const mainUser = yield select(getMainUserFromStore);
      const selectedProfessionalPdfProfile = yield select(getSelectedProfessionalPdfProfileFromStore);
      if (pdfSettings.header.logoMode === 'withLogo') {
        const logoFile = yield select(getCustomHeaderLogo);
        yield call(
          saveCustomLogoOnDB,
          selectedProfessionalPdfProfile,
          pdfProfile,
          logoFile,
          mainUser,
        );
        // if (!response) {
        //   throw new Error('Error uploading file. No file provided.');
        // }
      }
      yield call(
        savePdfSettingsOnDB,
        selectedProfessionalPdfProfile,
        pdfProfile,
        pdfSettings,
        mainUser,
      );
      yield all([
        put({ type: actions.PDF_SETTINGS_FETCH_REQUEST }),
        put({
          type: actions.UPDATE_PDF_SETTINGS_ON_DB,
          payload: {
            pdfSettings,
          },
        }),
      ]);
    } catch (error) {
      console.warn(error);
    }
  });
}

function fetchOldPdfSettingsFromDB(mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  return get(child(dbRef, `/users/${uid}/pdf`));
}

function fetchPdfSettingsFromDB(mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  return get(child(dbRef, `/users/${uid}/pdfSettings`));
}

export function* getPdfSettings() {
  yield takeEvery([
    actions.PDF_SETTINGS_FETCH_REQUEST,
    // actions.CREATE_PDF_PROFILE_SUCCESS,
  ], function* () {
    try {
      let mainProfile = yield select(getProfileFromStore);
      if (_.isEmpty(mainProfile)) {
        yield take(profileActions.PROFILE_INFO_SUCCESS);
        mainProfile = yield select(getProfileFromStore);
      }
      const user = yield select(getUserFromStore);
      const mainUser = yield select(getMainUserFromStore);
      // const healthProfessional = yield select(getHealthProfessionalFromStore);
      const settings = yield call(fetchPdfSettingsFromDB, mainUser);
      const customUsers = yield select(getCustomUserFromStore);
      const newProfile = {
        ...defaultPdfSettings,
        profileName: 'Padrão',
      };
      // if (!settings.val() || (settings.val() && !settings.val()[user.uid] && healthProfessional)) {
      if (!settings.val()) {
        // No settings on DB.
        const customProfessionalsArr = customUsers.filter((el) => el.healthProfessional);
        const allProfessionalsArr = [
          ...customProfessionalsArr,
          { id: mainProfile.id },
        ];
        const oldSettings = yield call(fetchOldPdfSettingsFromDB, mainUser);
        if (oldSettings.val() && oldSettings.val().profiles) {
          // There are old profile settings.
          const profilesArray = _.map(oldSettings.val().profiles, (val, id) => (
            { ...val, key: id }
          ));
          for (let i = 0; i < profilesArray.length; i += 1) {
            for (let j = 0; j < allProfessionalsArr.length; j += 1) {
              yield call(
                savePdfSettingsOnDB,
                allProfessionalsArr[j].id,
                profilesArray[i].key,
                profilesArray[i],
                mainUser,
                true,
              );
            }
          }
        } else {
          // No old settings, creating default profile on new settings.
          yield call(
            savePdfSettingsOnDB,
            mainUser || user.uid,
            'default',
            newProfile,
            mainUser,
          );
        }
      } else {
        // There are settings.
        const normalized = { ...settings.val() };
        const customProfessionals = customUsers.filter((el) => el.healthProfessional);
        customProfessionals.forEach((el) => {
          if (!normalized[el.id]) {
            normalized[el.id] = {
              profiles: {
                default: { ...newProfile },
              },
            };
          }
        });
        Object.keys(normalized).forEach((userKey) => {
          const normalizedProfiles = _.map(normalized[userKey].profiles, (val, id) => (
            { ...val, key: id }
          ));
          normalized[userKey].profiles = normalizedProfiles;
        });
        yield put({
          type: actions.PDF_SETTINGS_FETCH_SUCCESS,
          payload: {
            allUsersPdfSettings: normalized,
          },
        });
      }

      // if (!settings.val()) {
      //   // No settings on DB.
      //   const newProfile = {
      //     ...defaultPdfSettings,
      //     profileName: 'Padrão',
      //   };
      //   yield call(savePdfSettingsOnDB, 'default', newProfile, mainUser);
      //   yield put({ type: actions.PDF_SETTINGS_FETCH_REQUEST });
      // } else if (settings.val() && !settings.val().profiles) {
      //   // There is settings but the OLD model (without profiles).
      //   const newProfile = {
      //     ...settings.val(),
      //     profileName: 'Padrão',
      //   };
      //   yield call(saveLogoOnDefaultProfileOnDB, 'default', settings.val().logo, mainUser);
      //   yield call(savePdfSettingsOnDB, 'default', newProfile, mainUser);
      //   yield put({ type: actions.PDF_SETTINGS_FETCH_REQUEST });
      // } else {
      //   // There is settings with profiles.
      //   const profilesArray = _.map(settings.val().profiles, (val, id) => (
      //     { ...val, key: id }
      //   ));
      //   yield put({
      //     type: actions.PDF_SETTINGS_FETCH_SUCCESS,
      //     payload: {
      //       pdfProfilesArr: profilesArray,
      //     },
      //   });
      // }
    } catch (error) {
      console.warn(error);
    }
  });
}

export function* checkPageSizeStatus() {
  yield takeEvery(actions.GET_PDF_GENERAL_SIZES, function* () {
    try {
      const data = yield localStorage.getItem('page_size');
      if (_.isNull(data)) {
        yield put({
          type: actions.SET_PDF_PAGE_SIZE,
          payload: 'A4',
        });
      } else {
        yield put({
          type: actions.SET_PDF_PAGE_SIZE,
          payload: data,
        });
      }
    } catch (error) {
      console.warn(error);
    }
  });
}

export function* setPageSize() {
  yield takeEvery(actions.SET_PDF_PAGE_SIZE, function* (action) {
    try {
      yield localStorage.setItem('page_size', action.payload);
      yield put({
        type: actions.SET_PDF_PAGE_SIZE_SUCCESS,
        value: action.payload,
      });
    } catch (error) {
      console.warn(error);
    }
  });
}

export function* checkPageOrientationStatus() {
  yield takeEvery(actions.GET_PDF_GENERAL_SIZES, function* () {
    try {
      const data = yield localStorage.getItem('page_orientation');
      const pageSize = yield localStorage.getItem('page_size');
      if (_.isNull(data)) {
        yield put({
          type: actions.SET_PDF_PAGE_ORIENTATION,
          payload: 'portrait',
        });
      } else if (!_.isNull(data) && pageSize === 'A5') {
        yield put({
          type: actions.SET_PDF_PAGE_ORIENTATION,
          payload: 'portrait',
        });
      } else {
        yield put({
          type: actions.SET_PDF_PAGE_ORIENTATION,
          payload: data,
        });
      }
    } catch (error) {
      console.warn(error);
    }
  });
}

export function* setPageOrientation() {
  yield takeEvery(actions.SET_PDF_PAGE_ORIENTATION, function* (action) {
    try {
      yield localStorage.setItem('page_orientation', action.payload);
      yield put({
        type: actions.SET_PDF_PAGE_ORIENTATION_SUCCESS,
        value: action.payload,
      });
    } catch (error) {
      console.warn(error);
    }
  });
}

function createPdfProfileOnDB(pushKey, id, pdfSettings, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  updates[`users/${uid}/pdfSettings/${id}/profiles/${pushKey}`] = pdfSettings;
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(pushKey);
  //     }
  //     return null;
  //   });
  // });
}

export function* createPdfProfile() {
  yield takeEvery(actions.CREATE_PDF_PROFILE_REQUEST, function* (action) {
    try {
      yield put({
        type: actions.CREATING_PDF_PROFILE,
      });
      const mainUser = yield select(getMainUserFromStore);
      const customUsers = yield select(getCustomUserFromStore);
      // const currentPdfSettings = yield select(getPdfSettingsFromStore);
      const selectedProfessionalPdfProfile = yield select(getSelectedProfessionalPdfProfileFromStore);
      const newProfile = {
        ...defaultPdfSettings,
        // ...currentPdfSettings,
        profileName: action.payload,
      };
      const db = getDatabase();
      const dbRef = ref(db);
      let uid;
      if (mainUser) {
        uid = mainUser;
      } else {
        const auth = getAuth();
        const { currentUser } = auth;
        ({ uid } = currentUser);
      }
      const pushKey = push(child(dbRef, `users/${uid}/pdfSettings/${selectedProfessionalPdfProfile}/profiles`)).key;
      yield call(
        createPdfProfileOnDB,
        pushKey,
        selectedProfessionalPdfProfile,
        newProfile,
        mainUser,
      );

      // After creating profile, fetching settings from DB,
      // to generate new 'pdfProfilesArr'.
      const settings = yield call(fetchPdfSettingsFromDB, mainUser);
      // const profilesArray = _.map(settings.val().profiles, (val, id) => (
      //   { ...val, key: id }
      // ));
      const normalized = { ...settings.val() };
      const customProfessionals = customUsers.filter((el) => el.healthProfessional);
      customProfessionals.forEach((el) => {
        if (!normalized[el.id]) {
          normalized[el.id] = {
            profiles: {
              default: { ...newProfile },
            },
          };
        }
      });
      Object.keys(normalized).forEach((userKey) => {
        const normalizedProfiles = _.map(normalized[userKey].profiles, (val, id) => (
          { ...val, key: id }
        ));
        normalized[userKey].profiles = normalizedProfiles;
      });
      yield all([
        put({
          type: actions.PDF_SETTINGS_FETCH_SUCCESS,
          payload: {
            // pdfProfilesArr: profilesArray,
            allUsersPdfSettings: normalized,
          },
        }),
        put({
          type: actions.CREATE_PDF_PROFILE_SUCCESS,
          payload: {
            pdfSettings: { ...newProfile, key: pushKey },
            key: pushKey,
          },
        }),
      ]);
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.CREATE_PDF_PROFILE_ERROR,
      });
    }
  });
}

export function* selectPdfProfileRequest() {
  yield takeEvery([
    actions.SELECT_PDF_PROFILE_REQUEST,
    actions.PDF_SETTINGS_FETCH_SUCCESS,
    actions.SELECT_PROFESSIONAL_PDF_PROFILE_REQUEST,
    // actions.CREATE_PDF_PROFILE_SUCCESS,
  ], function* (action) {
    try {
      let key = yield select(getSelectedPdfProfileFromStore);
      if (action.payload?.key) {
        ({ key } = action.payload);
      }
      if (action.type === 'SELECT_PROFESSIONAL_PDF_PROFILE_REQUEST') {
        key = 'default';
      }
      let mainProfile = yield select(getProfileFromStore);
      if (_.isEmpty(mainProfile)) {
        yield take(profileActions.PROFILE_INFO_SUCCESS);
        mainProfile = yield select(getProfileFromStore);
      }
      let selectedProfessionalPdfProfile = yield select(getSelectedProfessionalPdfProfileFromStore);
      const allUsersPdfSettings = yield select(getAllUsersPdfSettingsFromStore);
      const user = yield select(getUserFromStore);
      // const mainUser = yield select(getMainUserFromStore);
      // const healthProfessional = yield select(getHealthProfessionalFromStore);
      // const pdfProfilesArr = yield select(getPdfProfilesArrFromStore);
      const pdfSettingsOnDB = yield select(getPdfSettingsOnDBFromStore);
      const currentPdfSettings = yield select(getPdfSettingsFromStore);

      if (!selectedProfessionalPdfProfile) {
        selectedProfessionalPdfProfile = mainProfile.id;
        if (allUsersPdfSettings[user.uid]?.profiles) {
          selectedProfessionalPdfProfile = user.uid;
        }
      }

      let foundProfile = null;
      if (selectedProfessionalPdfProfile) {
        const foundUserPdfSettings = allUsersPdfSettings[selectedProfessionalPdfProfile]?.profiles || null;
        if (foundUserPdfSettings) {
          foundProfile = foundUserPdfSettings.find((el) => el.key === key);
        }
      }
      // foundProfile = pdfProfilesArr.find((el) => el.key === key);
      if (foundProfile) {
        const pdfSettings = {
          ...defaultPdfSettings,
          specialPrescriptionDate: currentPdfSettings?.specialPrescriptionDate || moment().tz('America/Sao_Paulo').format(),
          ...foundProfile,
          header: {
            ...defaultPdfSettings.header,
            ...foundProfile.header,
          },
          content: {
            ...defaultPdfSettings.content,
            ...foundProfile.content,
          },
          footer: {
            ...defaultPdfSettings.footer,
            ...foundProfile.footer,
          },
        };
        if (!pdfSettingsOnDB) {
          yield put({
            type: actions.UPDATE_PDF_SETTINGS_ON_DB,
            payload: {
              pdfSettings,
            },
          });
        }
        yield put({
          type: actions.SELECT_PDF_PROFILE_SUCCESS,
          payload: {
            pdfSettings,
            key,
            selectedProfessionalPdfProfile,
          },
        });
      }
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.SELECT_PDF_PROFILE_ERROR,
      });
    }
  });
}

function removePdfProfileOnDB(key, id, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  updates[`users/${uid}/pdfSettings/${id}/profiles/${key}`] = null;
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

function removeLogoOnDB(pdfProfile, id, mainUser) {
  const storage = getStorage();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const avatarImagesRef = sRef(storage, `public/${uid}/images/pdfSettings/${id}/profiles/${pdfProfile}/customLogo`);
  return deleteObject(avatarImagesRef);
}

export function* removePdfProfile() {
  yield takeEvery(actions.REMOVE_PDF_PROFILE_REQUEST, function* (action) {
    try {
      yield put({
        type: actions.REMOVING_PDF_PROFILE,
        payload: action.payload,
      });
      const mainUser = yield select(getMainUserFromStore);
      const allUsersPdfSettings = yield select(getAllUsersPdfSettingsFromStore);
      const selectedProfessionalPdfProfile = yield select(getSelectedProfessionalPdfProfileFromStore);
      yield call(
        removePdfProfileOnDB,
        action.payload,
        selectedProfessionalPdfProfile,
        mainUser,
      );
      // const pdfProfilesArr = yield select(getPdfProfilesArrFromStore);
      // const foundProfile = pdfProfilesArr.find((el) => el.key === action.payload);

      let foundProfile = null;
      if (selectedProfessionalPdfProfile) {
        const foundUserPdfSettings = allUsersPdfSettings[selectedProfessionalPdfProfile]?.profiles || null;
        if (foundUserPdfSettings) {
          foundProfile = foundUserPdfSettings.find((el) => el.key === action.payload);
        }
      }

      if (foundProfile?.logo) {
        yield call(
          removeLogoOnDB,
          action.payload,
          selectedProfessionalPdfProfile,
          mainUser,
        );
      }
      const key = yield select(getSelectedPdfProfileFromStore);
      if (key === action.payload) {
        yield put({
          type: actions.SELECT_PDF_PROFILE_REQUEST,
          payload: {
            key: 'default',
          },
        });
      }
      yield all([
        put({ type: actions.PDF_SETTINGS_FETCH_REQUEST }),
        put({
          type: actions.REMOVE_PDF_PROFILE_SUCCESS,
        }),
      ]);
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.REMOVE_PDF_PROFILE_ERROR,
      });
    }
  });
}

export function* discardPdfSettings() {
  yield takeEvery(actions.DISCARD_PDF_SETTINGS_CHANGES_REQUEST, function* () {
    try {
      const selectedProfile = yield select(getSelectedPdfProfileFromStore);
      const pdfSettingsOnDB = yield select(getPdfSettingsOnDBFromStore);
      // const pdfProfilesArr = yield select(getPdfProfilesArrFromStore);
      const selectedProfessionalPdfProfile = yield select(getSelectedProfessionalPdfProfileFromStore);
      const allUsersPdfSettings = yield select(getAllUsersPdfSettingsFromStore);
      // const foundProfile = pdfProfilesArr.find((el) => el.key === selectedProfile);
      let foundProfile = null;
      let foundUserPdfSettings = [];
      if (selectedProfessionalPdfProfile) {
        foundUserPdfSettings = allUsersPdfSettings[selectedProfessionalPdfProfile]?.profiles || null;
        if (foundUserPdfSettings) {
          foundProfile = foundUserPdfSettings.find((el) => el.key === selectedProfile);
        }
      }
      if (pdfSettingsOnDB?.key
        && !foundUserPdfSettings.some((el) => el.key === pdfSettingsOnDB.key)) {
        // Profile rendered on screen was removed.
        yield all([
          put({
            type: actions.UPDATE_PDF_SETTINGS_ON_DB,
            payload: {
              pdfSettings: foundProfile,
            },
          }),
          put({
            type: actions.SELECT_PDF_PROFILE_REQUEST,
            payload: {
              key: foundProfile.key,
            },
          }),
        ]);
      }

      if (pdfSettingsOnDB?.key) {
        yield put({
          type: actions.SELECT_PDF_PROFILE_REQUEST,
          payload: {
            key: pdfSettingsOnDB.key,
          },
        });
      }

      yield put({
        type: actions.DISCARD_PDF_SETTINGS_CHANGES_SUCCESS,
        // payload: {
        //   pdfSettings: foundProfile,
        // },
      });
    } catch (error) {
      console.warn(error);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(savePdfSettings),
    fork(getPdfSettings),
    fork(setPageSize),
    fork(checkPageSizeStatus),
    fork(setPageOrientation),
    fork(checkPageOrientationStatus),
    fork(createPdfProfile),
    fork(selectPdfProfileRequest),
    fork(removePdfProfile),
    fork(discardPdfSettings),
  ]);
}
