import _ from 'lodash';
import * as Sentry from '@sentry/react';
import { createBrowserHistory } from 'history';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import {
  persistStore,
  persistReducer,
  createTransform,
  createMigrate,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from './reducers';
import rootSaga from './sagas';

/* ------------------------------------ *
 * Uncomment if need to code split saga *
 * ------------------------------------ */
// import rootSaga from './rootSagas';

// function b64toBlob(b64Data, contentType = '', sliceSize = 512) {
//   const byteCharacters = atob(b64Data);
//   const byteArrays = [];
//   for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
//     const slice = byteCharacters.slice(offset, offset + sliceSize);
//     const byteNumbers = new Array(slice.length);
//     for (let i = 0; i < slice.length; i += 1) {
//       byteNumbers[i] = slice.charCodeAt(i);
//     }
//     const byteArray = new Uint8Array(byteNumbers);
//     byteArrays.push(byteArray);
//   }
//   const blob = new Blob(byteArrays, { type: contentType });
//   return blob;
// }

function saveOnlyValidRecords(newRecord) {
  let filteredNewRecord = _.cloneDeep(newRecord);
  if (newRecord?.files) {
    filteredNewRecord = _.omit(filteredNewRecord, 'files');
  }
  const emptyEditor = {
    text: { ops: [] },
    files: [],
  };
  const parsedEmptyEditor = {
    text: { ops: [{ insert: '\n' }] },
    files: [],
  };
  if (newRecord?.text) {
    let haveText = false;
    if (newRecord
      && newRecord.text
      && newRecord.text.ops
      && newRecord.text.ops.length > 0) {
      let textLength = false;
      newRecord.text.ops.forEach((item) => {
        if (item.insert) {
          if ((item.insert.image)
            || (item.insert.length > 0 && item.insert !== '\n')) {
            textLength = true;
          }
        }
      });
      if (!_.isEqual(newRecord.text.ops, emptyEditor.text.ops)
        && !_.isEqual(newRecord.text.ops, parsedEmptyEditor.text.ops)
        && textLength) {
        haveText = true;
      }
    }
    if (!haveText) {
      filteredNewRecord = _.omit(filteredNewRecord, 'text');
    }
  }
  Object.keys(newRecord).forEach((field) => {
    if (field.includes('text') && field !== 'text') {
      let haveCustomText = false;
      if (newRecord
        && newRecord?.[field]
        && newRecord[field].ops
        && newRecord[field].ops.length > 0) {
        let textLength = false;
        newRecord[field].ops.forEach((item) => {
          if (item.insert) {
            if (item.insert.length > 0 && item.insert !== '\n') {
              textLength = true;
            }
          }
        });
        if (!_.isEqual(newRecord[field].ops, emptyEditor.text.ops)
          && !_.isEqual(newRecord[field].ops, parsedEmptyEditor.text.ops)
          && textLength) {
          haveCustomText = true;
        }
      }
      if (!haveCustomText) {
        filteredNewRecord = _.omit(filteredNewRecord, field);
      }
    }
  });
  if (newRecord?.optical) {
    let isValidOptic = false;
    Object.keys(newRecord.optical).forEach((key) => {
      if (key.includes('field') && newRecord.optical[key]) {
        isValidOptic = true;
      }
    });
    if (!isValidOptic) {
      filteredNewRecord = _.omit(filteredNewRecord, 'optical');
    }
  }
  if (newRecord?.pediatric) {
    let isValidPediatric = false;
    if (Object.values(newRecord.pediatric).some((item) => item)) {
      isValidPediatric = true;
    }
    if (!isValidPediatric) {
      filteredNewRecord = _.omit(filteredNewRecord, 'pediatric');
    }
  }
  return filteredNewRecord;
}

const SetTransform = createTransform(
  // transform state on its way to being serialized and persisted.
  (inboundState, key) => {
    const newState = { ...inboundState };
    if (key === 'EntrancePersist') {
      Object.keys(inboundState).forEach((uid) => {
        if (inboundState[uid].newRecord) {
          const newFormattedRecord = { ...inboundState[uid].newRecord };
          Object.keys(newFormattedRecord).forEach((patientId) => {
            const validNewRecords = saveOnlyValidRecords(newFormattedRecord[patientId]);
            if (_.isEmpty(validNewRecords) || !patientId || patientId === 'null' || patientId === 'undefined') {
              delete newFormattedRecord[patientId];
            }
          });
          newState[uid].newRecord = newFormattedRecord;
        }
        if (inboundState[uid].newCertificate || inboundState[uid].newMedicalReport || inboundState[uid].newOtherDocument) {
          const newFormattedCertificate = inboundState[uid].newCertificate ? { ...inboundState[uid].newCertificate } : {};
          const newFormattedMedicalReport = inboundState[uid].newMedicalReport ? { ...inboundState[uid].newMedicalReport } : {};
          const newFormattedOtherDocument = inboundState[uid].newOtherDocument ? { ...inboundState[uid].newOtherDocument } : {};
          Object.keys(newFormattedCertificate).forEach((patientId) => {
            const validNewRecords = saveOnlyValidRecords(newFormattedCertificate[patientId]);
            if (_.isEmpty(validNewRecords) || !patientId || patientId === 'null' || patientId === 'undefined') {
              delete newFormattedCertificate[patientId];
            }
          });
          Object.keys(newFormattedMedicalReport).forEach((patientId) => {
            const validNewRecords = saveOnlyValidRecords(newFormattedMedicalReport[patientId]);
            if (_.isEmpty(validNewRecords) || !patientId || patientId === 'null' || patientId === 'undefined') {
              delete newFormattedMedicalReport[patientId];
            }
          });
          Object.keys(newFormattedOtherDocument).forEach((patientId) => {
            const validNewRecords = saveOnlyValidRecords(newFormattedOtherDocument[patientId]);
            if (_.isEmpty(validNewRecords) || !patientId || patientId === 'null' || patientId === 'undefined') {
              delete newFormattedOtherDocument[patientId];
            }
          });
          newState[uid].newCertificate = newFormattedCertificate;
          newState[uid].newMedicalReport = newFormattedMedicalReport;
          newState[uid].newOtherDocument = newFormattedOtherDocument;
        }
        if (inboundState[uid].newPrescription) {
          const newFormattedPrescription = inboundState[uid].newPrescription ? { ...inboundState[uid].newPrescription } : {};
          Object.keys(newFormattedPrescription).forEach((patientId) => {
            newFormattedPrescription[patientId] = newFormattedPrescription[patientId].filter((el) => el.name || el.description);
            if (newFormattedPrescription[patientId]?.length === 0 || !patientId || patientId === 'null' || patientId === 'undefined') {
              delete newFormattedPrescription[patientId];
            }
          });
          newState[uid].newPrescription = newFormattedPrescription;
        }
        if (inboundState[uid].newPrescriptionGeneralObs) {
          const newFormattedPrescription = inboundState[uid].newPrescriptionGeneralObs ? { ...inboundState[uid].newPrescriptionGeneralObs } : {};
          Object.keys(newFormattedPrescription).forEach((patientId) => {
            if (!newFormattedPrescription[patientId] || !patientId || patientId === 'null' || patientId === 'undefined') {
              delete newFormattedPrescription[patientId];
            }
          });
          newState[uid].newPrescriptionGeneralObs = newFormattedPrescription;
        }
      });
    }
    return ({ ...newState });
  },
  // transform state being rehydrated
  (outboundState) => {
    const newState = { ...outboundState };
    if (newState.newRecord) {
      Object.keys(newState.newRecord).forEach((item) => {
        if (newState.newRecord[item].files && newState.newRecord[item].files.length > 0) {
          newState.newRecord[item].files = [];
        }
      });
    }
    return newState;
  },
  // define which reducers this transform gets called for.
  {
    whitelist: [
      'EntrancePersist',
    ],
  },
);

/* ------------------------------------ *
 * Uncomment if need to code split saga *
 * ------------------------------------ */
// function createSagaInjector(runSaga, rootSagaParam) {
//   // Create a dictionary to keep track of injected sagas
//   store.injectedSagas = new Map();
//   const isInjected = (key) => store.injectedSagas.has(key);
//   const injectSaga = (key, saga) => {
//     // We won't run saga if it is already injected
//     if (isInjected(key)) return;
//     // Sagas return task when they executed, which can be used
//     // to cancel them
//     const task = runSaga(saga);
//     // Save the task if we want to cancel it in the future
//     store.injectedSagas.set(key, task);
//   };
//   // Inject the root saga as statically loaded file
//   injectSaga('root', rootSagaParam);
//   return injectSaga;
// }

const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const middlewares = [thunk, sagaMiddleware];
// const middlewares = [thunk];

const migrations = {
  174: (state) => {
    const newState = _.cloneDeep(state);
    if (newState?.EntrancePersist) {
      // Reseting 'EntrancePersist' state
      newState.EntrancePersist = {};
    }
    return newState;
  },
};

const persistConfig = {
  key: 'root',
  storage,
  transforms: [SetTransform],
  version: 174, // version 1.7.4
  migrate: createMigrate(migrations, { debug: false }),
  whitelist: [
    'EntrancePersist',
  ],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
});

const composeEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
  })
  : compose;

const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(...middlewares), sentryReduxEnhancer),
);
/* ------------------------------------ *
 * Uncomment if need to code split saga *
 * ------------------------------------ */
// // Add injectSaga method to our store
// store.injectSaga = createSagaInjector(sagaMiddleware.run, rootSaga);

sagaMiddleware.run(rootSaga);
const persistor = persistStore(store);
// persistor.purge();

export { store, history, persistor };
