import actions from './actions';
import authActions from '../auth/actions';

const initState = {
  // updatingMedicineModel: false,
  // removingMedicineModel: false,
  tomorrowAppointmentNotConfirmedCount: 0,
  fullMessagesSettings: {},
};

export default function messageReducer(state = initState, action) {
  switch (action.type) {
    case authActions.CLEAR_STATES:
      return {
        ...initState,
      };
    case actions.FETCH_MESSAGES_SETTINGS_SUCCESS:
      return {
        ...state,
        messagesSettingsPerUser: action.payload.messagesSettingsPerUser,
        fullMessagesSettings: action.payload.fullMessagesSettings,
        updatingSendAutomaticMessages: false,
      };
    case actions.UPDATING_SEND_AUTOMATIC_MESSAGES:
      return {
        ...state,
        updatingSendAutomaticMessages: true,
      };
    case actions.CHANGE_SEND_AUTOMATIC_MESSAGES_ERROR:
      return {
        ...state,
        updatingSendAutomaticMessages: null,
      };
    case actions.FETCH_MESSAGE_MODELS_SUCCESS:
      return {
        ...state,
        messageModels: action.payload,
      };
    case actions.FETCH_PATIENT_MOBILE_SUCCESS:
      return {
        ...state,
        patientMobile: {
          [action.payload.patientId]: action.payload.mobile,
        },
      };
    case actions.SET_TOMORROW_APPOINTMENTS_NOT_CONFIRMED_COUNT:
      return {
        ...state,
        tomorrowAppointmentNotConfirmedCount: action.payload,
      };
    case actions.SET_TWILIO_SETTINGS:
      return {
        ...state,
        twilioSettings: action.payload.data,
      };
    default:
      return state;
  }
}
