import React from 'react';
import moment from 'moment-timezone';
import { connect } from 'react-redux';

function CustomResponseMessage(props) {
  const {
    text,
    timestamp,
    appointmentInfo,
    telemedAppointmentData,
    userType,
  } = props;

  const renderTimestamp = () => {
    if (moment(timestamp).isSameOrAfter(new Date(), 'day')) {
      return moment(timestamp).format('HH:mm');
    }
    return moment(timestamp).format('DD/MM/YYYY HH:mm');
  };

  const renderCustomUserName = () => {
    if (userType === 'patient') {
      if (appointmentInfo?.professionalInfo) {
        return (
          <p className="chat-user-name">
            {`${appointmentInfo.professionalInfo.firstName} ${appointmentInfo.professionalInfo.lastName}`}
          </p>
        );
      }
      return (
        <p className="chat-user-name">
          Profissional
        </p>
      );
    }
    if (userType === 'professional') {
      if (telemedAppointmentData?.userInfo) {
        return (
          <p className="chat-user-name">
            {`${telemedAppointmentData.userInfo.firstName} ${telemedAppointmentData.userInfo.lastName}`}
          </p>
        );
      }
      return (
        <p className="chat-user-name">
          Paciente
        </p>
      );
    }
    return null;
  };

  return (
    <div className="rcw-response">
      <div className="rcw-message-text">
        {renderCustomUserName()}
        <p>{text}</p>
      </div>
      <span className="rcw-timestamp">
        {renderTimestamp()}
      </span>
    </div>
  );
}

export default connect(
  (state) => ({
    customUsers: state.CustomUsers.customUsers,
    profile: state.Profile.profile,
    userType: state.Telemed.userType,
  }),
  // customUsersActions,
)(CustomResponseMessage);
