import {
  getAuth,
} from 'firebase/auth';
import {
  getDatabase,
  ref,
  onValue,
  update,
  push,
  child,
  query as dbQuery,
  orderByChild,
  startAt,
  endAt,
  limitToFirst,
  get,
  increment,
  equalTo,
} from 'firebase/database';
import {
  getFirestore,
  doc,
  query as fsQuery,
  collection,
  where,
  orderBy,
  limit,
  startAfter,
  updateDoc,
  getDocsFromServer,
} from 'firebase/firestore';
import {
  getStorage,
  ref as sRef,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from 'firebase/storage';
import _ from 'lodash';
import cep from 'cep-promise';
import moment from 'moment-timezone';
import axios from 'axios';
import {
  all,
  takeEvery,
  takeLatest,
  take,
  fork,
  call,
  put,
  select,
} from 'redux-saga/effects';
import {
  datetime,
  rrulestr,
} from 'rrule';
import { notification } from '../../components';
import message from '../../components/feedback/message';
import actions from './actions';
import appActions from '../app/actions';

const ROOT_URL = process.env.REACT_APP_CLOUD_FUNCTIONS_ROOT_URL;

function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

const getUnifiedTokenStore = (state) => state.Auth.unified;

const getSelectedAddressFromStore = (state) => state.App.selectedAddress;

const getSelectedAgendaFromStore = (state) => state.Agenda.selectedAgenda;

const getMainUserFromStore = (state) => state.Auth.mainUser;

const getFormSettingsFromStore = (state) => state.Contacts.formSettings;

const getSearchInputFromStore = (state) => state.Contacts.searchInput;

const getSeectedIdFromStore = (state) => state.Contacts.seectedId;

const getPatientsOnDBFromStore = (state) => state.Contacts.patientsOnDB;

const getPatientsFromStore = (state) => state.Contacts.patients;

const getDeletedPatientsFromStore = (state) => state.Contacts.deletedPatients;

const getLastDocFromStore = (state) => state.Contacts.lastVisibleDoc;

const getLastDeletedDocFromStore = (state) => state.Contacts.lastVisibleDeletedDoc;

const getPatientDuplicateArrFromStore = (state) => state.Contacts.patientDuplicateArr;

const getPatientAvatarFromStore = (state) => state.Contacts.avatarFile;

const defaultBirthdayMessage = {
  ops: [
    {
      insert: 'Olá ',
    },
    {
      insert: {
        mention: {
          denotationChar: '#',
          id: 'patientName',
          index: '0',
          value: 'nome_do_paciente',
        },
      },
    },
    {
      insert: ', um feliz aniversário!',
    },
  ],
};

export function* addContactRequest() {
  yield takeEvery(actions.ADD_CONTACT_REQUEST, function* () {
    try {
      const unified = yield select(getUnifiedTokenStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      const mainUser = yield select(getMainUserFromStore);
      const patients = yield select(getPatientsFromStore);
      const db = getDatabase();
      const auth = getAuth();
      const { currentUser } = auth;
      let pushKey;
      if (unified
        && unified.id
        && addressUid
        && unified.address.some((a) => a === addressUid)) {
        pushKey = push(child(ref(db), `unified/${unified.id}/patients`)).key;
      } else if (mainUser) {
        pushKey = push(child(ref(db), `users/${mainUser}/patients`)).key;
      } else {
        pushKey = push(child(ref(db), `users/${currentUser.uid}/patients`)).key;
      }
      const newContact = {
        // id: new Date(),
        id: pushKey,
        cpf: '',
        firstName: '',
        avatar: '',
        lastName: '',
        mobile: '',
        phone: '',
        birthday: '',
        email: '',
        profession: '',
        name: '',
        address: '',
        addressNumber: '',
        complement: '',
        cep: '',
        neighborhood: '',
        city: '',
        state: '',
        gender: '',
        note: '',
        referral: '',
        requestingDoctor: '',
        requestingDoctorCrm: '',
        local: true,
        newContact: true,
        timeCreated: moment().tz('America/Sao_Paulo').format(),
        createdBy: currentUser.uid,
        active: true,
      };
      yield put({
        type: actions.ADD_CONTACT,
        patients: [...patients, newContact],
        selectedId: newContact.id,
      });
    } catch (error) {
      console.warn(error);
    }
  });
}
// export function* editContact() {
//   yield takeEvery(actions.EDIT_CONTACT, function* () {});
// }

function getIdToken() {
  const auth = getAuth();
  const { currentUser } = auth;
  return currentUser.getIdToken();
}

function deletePatientOnDB(id, addressUid, unified = {}, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  const updates = {};
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    updates[`unified/${unified.id}/patients/${id}/fullName`] = null;
  } else {
    let uid;
    if (mainUser) {
      uid = mainUser;
    } else {
      const auth = getAuth();
      const { currentUser } = auth;
      ({ uid } = currentUser);
    }
    updates[`users/${uid}/patients/${id}/fullName`] = null;
  }
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

function updateFirestoreIndex(id, addressUid, unified = {}, mainUser, active) {
  const fs = getFirestore();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    const docRef = doc(
      fs,
      'unified',
      unified.id,
      'queryPatients',
      id,
    );
    return updateDoc(docRef, {
      active,
    });
    // return fs.collection('unified').doc(unified.id).collection('queryPatients').doc(id)
    //   .update({
    //     active,
    //   });
  }
  const docRef = doc(
    fs,
    'professionals',
    uid,
    'queryPatients',
    id,
  );
  return updateDoc(docRef, {
    active,
  });
  // return fs.collection('professionals').doc(uid).collection('queryPatients').doc(id)
  //   .update({
  //     active,
  //   });
}

export function* deleteContact() {
  yield takeEvery(actions.DELETE_CONTACT, function* (action) {
    try {
      yield put({
        type: actions.START_DELETE_CONTACT,
        payload: action.payload,
      });
      const unified = yield select(getUnifiedTokenStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      const searchInput = yield select(getSearchInputFromStore);
      const mainUser = yield select(getMainUserFromStore);
      yield call(deletePatientOnDB, action.payload, addressUid, unified, mainUser);
      yield call(updateFirestoreIndex, action.payload, addressUid, unified, mainUser, false);
      yield all([
        yield put({
          type: actions.DELETE_CONTACT_SUCCESS,
        }),
        yield put({
          type: actions.SEARCH_PATIENTS_REQUEST,
          payload: {
            search: searchInput,
            requestMode: 'all',
          },
        }),
      ]);
    } catch (error) {
      console.warn(error);
    }
  });
}

function recoverPatientOnDB(contact, addressUid, unified = {}, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  const updates = {};
  const { id, firstName, lastName } = contact;
  if (!id || !firstName || !lastName) {
    throw new Error('Missing information from patient');
  }
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    updates[`unified/${unified.id}/patients/${id}/fullName`] = `${contact.firstName} ${contact.lastName}`;
  } else {
    let uid;
    if (mainUser) {
      uid = mainUser;
    } else {
      const auth = getAuth();
      const { currentUser } = auth;
      ({ uid } = currentUser);
    }
    updates[`users/${uid}/patients/${id}/fullName`] = `${contact.firstName} ${contact.lastName}`;
  }
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* recoverContact() {
  yield takeEvery(actions.RECOVER_CONTACT, function* (action) {
    try {
      const { id } = action.payload;
      yield put({
        type: actions.START_RECOVER_CONTACT,
        payload: id,
      });
      const unified = yield select(getUnifiedTokenStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      const searchInput = yield select(getSearchInputFromStore);
      const mainUser = yield select(getMainUserFromStore);
      yield call(recoverPatientOnDB, action.payload, addressUid, unified, mainUser);
      yield call(updateFirestoreIndex, id, addressUid, unified, mainUser, true);
      yield all([
        put({
          type: actions.RECOVER_CONTACT_SUCCESS,
        }),
        put({
          type: actions.SEARCH_PATIENTS_REQUEST,
          payload: {
            search: searchInput,
            requestMode: 'all',
          },
        }),
      ]);
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.RECOVER_CONTACT_ERROR,
      });
    }
  });
}

function formatBirthdayValue(birthday) {
  if (moment(birthday, 'MM-DD-YYYY', true).isValid() || moment(birthday, 'MM-DD-YYYY HH:mm:ss', true).isValid()) {
    return moment(birthday, 'MM-DD-YYYY').format('MM-DD-YYYY');
  }
  if (moment(birthday, 'DD/MM/YYYY', true).isValid() || moment(birthday, 'DD/MM/YYYY HH:mm:ss', true).isValid()) {
    return moment(birthday, 'DD/MM/YYYY').format('MM-DD-YYYY');
  }
  if (moment(birthday, 'YYYY-MM-DD', true).isValid() || moment(birthday, 'YYYY-MM-DD HH:mm:ss', true).isValid()) {
    return moment(birthday, 'YYYY-MM-DD').format('MM-DD-YYYY');
  }
  return '';
}

function savePatientOnDB(
  {
    contact,
    // appointment,
    // appointmentType,
  },
  seectedId,
  addressUid,
  agendaId,
  unified = {},
  mainUser,
  avatarUrl,
) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  const formatedContact = {
    ...contact,
    newContact: null,
    firstName: contact.firstName.replace(/\s+/g, ' ').replace(/^\s+|\s+$/g, ''),
    lastName: contact.lastName.replace(/\s+/g, ' ').replace(/^\s+|\s+$/g, ''),
    fullName: contact.fullName
      ? contact.fullName.toUpperCase()
        .replace(/\s+/g, ' ')
        .replace(/^\s+|\s+$/g, '')
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
      : null,
    searchLastName: contact.lastName.toUpperCase()
      .replace(/\s+/g, ' ')
      .replace(/^\s+|\s+$/g, '')
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, ''),
    cpf: contact.cpf ? contact.cpf.replace(/\D+/g, '') : '',
    spouseCpf: contact.spouseCpf ? contact.spouseCpf.replace(/\D+/g, '') : '',
    rg: contact.rg ? contact.rg.replace(/[^a-zA-Z0-9]+/g, '') : '',
    cep: contact.cep ? contact.cep.replace(/\D+/g, '') : '',
    lastEdited: moment().tz('America/Sao_Paulo').format(),
    whatsapp: contact.whatsapp ? contact.whatsapp.replace(/\D+/g, '') : '',
    // mobile: contact.mobile ? contact.mobile.replace(/\D+/g, '') : '',
    phone: contact.phone ? contact.phone.replace(/\D+/g, '') : '',
    phoneArr: contact.phoneArr ? contact.phoneArr.filter((el) => el.length > 0) : null,
    birthday: contact.birthday ? formatBirthdayValue(contact.birthday) : '',
    spouseBirthday: contact.spouseBirthday ? formatBirthdayValue(contact.spouseBirthday) : '',
    avatar: avatarUrl || (contact.avatar || ''),
    referral: contact.referral ? contact.referral.trim() : '',
    referralProfessional: contact.referral && contact.referralProfessional ? contact.referralProfessional : '',
    requestingDoctor: contact.requestingDoctor ? contact.requestingDoctor.trim() : '',
    requestingDoctorCrm: contact.requestingDoctor && contact.requestingDoctorCrm ? contact.requestingDoctorCrm.trim() : '',
    requestingDoctorProfessional: contact.requestingDoctor && contact.requestingDoctorProfessional ? contact.requestingDoctorProfessional : '',
  };
  if (formatedContact.local
    && formatedContact.phones
    && formatedContact.phones.length > 0
    && typeof formatedContact.phones[0] === 'string'
    && !formatedContact.phoneArr) {
    const arr = formatedContact.phones.filter((el) => el.length > 0);
    formatedContact.phoneArr = arr;
    delete formatedContact.phones;
  }
  if (formatedContact.phones && typeof formatedContact.phones[0] !== 'string') {
    const arr = formatedContact.phones.filter((el) => el.phone.length > 0);
    formatedContact.phones = arr;
  }
  // if (appointment?.id && appointmentType && appointment.user === seectedId) {
  //   const {
  //     firstName,
  //     lastName,
  //     birthday,
  //     phones,
  //     whatsapp,
  //     mobile,
  //     phone,
  //     phoneArr,
  //     avatar,
  //     cpf,
  //     local,
  //   } = formatedContact;
  //   const userInfo = {
  //     cpf: cpf || '',
  //     firstName,
  //     avatar: avatar || '',
  //     lastName,
  //     mobile: mobile || '',
  //     phone: phone || '',
  //     birthday: birthday || '',
  //     phoneArr: phoneArr || null,
  //     whatsapp: whatsapp || '',
  //     local: local || false,
  //     phones: phones || null,
  //   };
  //   updates[`requests/${uid}/${agendaId}/${appointmentType}/${appointment.id}/userInfo`] = userInfo;
  // }
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    updates[`unified/${unified.id}/patients/${seectedId}`] = formatedContact;
  } else {
    updates[`users/${uid}/patients/${seectedId}`] = formatedContact;
  }
  // return update(dbRef, updates);
  return new Promise((resolve, reject) => {
    update(dbRef, updates)
      .then(() => {
        // Data saved successfully!
        resolve(formatedContact);
      })
      .catch((error) => {
        // The write failed...
        reject(new Error(error));
      });
    // db.ref().update(updates, (error) => {
    //   if (error) {
    //     reject(new Error(error));
    //   } else {
    //     // resolve(true);
    //     resolve(formatedContact);
    //   }
    //   return null;
    // });
  });
}

function savePatientKeywords(contact, patientOnDB, addressUid, idToken, mainUser) {
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  if (!contact.firstName) {
    return false;
  }
  const bodyParameters = {
    uid,
    patientId: contact.id,
    firstName: contact.firstName,
    lastName: contact.lastName,
    addressUid,
  };
  const newFullName = contact.fullName
    ? contact.fullName.toUpperCase()
      .replace(/\s+/g, ' ')
      .replace(/^\s+|\s+$/g, '')
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
    : null;
  if (patientOnDB && newFullName === patientOnDB.fullName) {
    return true;
  }
  return axios.post(
    `${ROOT_URL}/generatePatientKeywords`,
    bodyParameters,
    config,
  );
}

function getStorageDownloadURL(
  seectedId,
  addressUid,
  unified = {},
  mainUser,
) {
  const storage = getStorage();
  // const storageRef = firebase.storage().ref();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    const storageRef = sRef(storage, `unified/${unified.id}/patients/${seectedId}/avatar`);
    return getDownloadURL(storageRef);
    // return storageRef.child(`unified/${unified.id}/patients/${seectedId}/avatar`).getDownloadURL();
  }
  const storageRef = sRef(storage, `users/${uid}/patients/${seectedId}/avatar`);
  return getDownloadURL(storageRef);
  // return storageRef.child(`users/${uid}/patients/${seectedId}/avatar`).getDownloadURL();
}

function uploadAvatarOnDB(
  patientAvatar,
  seectedId,
  addressUid,
  unified = {},
  mainUser,
) {
  const storage = getStorage();
  // const storageRef = firebase.storage().ref();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    if (patientAvatar === '') {
      const avatarImagesRef = sRef(storage, `unified/${unified.id}/patients/${seectedId}/avatar`);
      return deleteObject(avatarImagesRef);
      // return storageRef.child(`unified/${unified.id}/patients/${seectedId}/avatar`).delete();
    }
    const avatarImagesRef = sRef(storage, `unified/${unified.id}/patients/${seectedId}/avatar`);
    return uploadBytes(avatarImagesRef, patientAvatar);
    // return storageRef.child(`unified/${unified.id}/patients/${seectedId}/avatar`).put(patientAvatar);
  }
  if (patientAvatar === '') {
    const avatarImagesRef = sRef(storage, `users/${uid}/patients/${seectedId}/avatar`);
    return deleteObject(avatarImagesRef);
    // return storageRef.child(`users/${uid}/patients/${seectedId}/avatar`).delete();
  }
  const avatarImagesRef = sRef(storage, `users/${uid}/patients/${seectedId}/avatar`);
  return uploadBytes(avatarImagesRef, patientAvatar);
  // return storageRef.child(`users/${uid}/patients/${seectedId}/avatar`).put(patientAvatar);
}

export function* savePatient() {
  yield takeEvery(actions.SAVE_PATIENT_REQUEST, function* (action) {
    try {
      yield put({ type: actions.START_SAVE_PATIENT_UPLOAD });
      yield call(sleep, 500);
      const unifiedToken = yield select(getUnifiedTokenStore);
      const seectedId = yield select(getSeectedIdFromStore);
      const storePatients = yield select(getPatientsOnDBFromStore);
      const patientOnDB = storePatients.find((el) => el.id === seectedId);
      const addressUid = yield select(getSelectedAddressFromStore);
      const agendaId = yield select(getSelectedAgendaFromStore);
      const mainUser = yield select(getMainUserFromStore);
      const patientAvatar = yield select(getPatientAvatarFromStore);
      let avatarUrl = '';
      if (patientAvatar || patientAvatar === '') {
        const uploadAvatar = yield call(
          uploadAvatarOnDB,
          patientAvatar,
          seectedId,
          addressUid,
          unifiedToken,
          mainUser,
        );
        if (uploadAvatar) {
          avatarUrl = yield call(
            getStorageDownloadURL,
            seectedId,
            addressUid,
            unifiedToken,
            mainUser,
          );
        }
      }
      const savedContact = yield call(
        savePatientOnDB,
        action.payload,
        seectedId,
        addressUid,
        agendaId,
        unifiedToken,
        mainUser,
        avatarUrl,
      );
      yield put({
        type: actions.UPDATE_APPOINTMENTS_USER_INFO,
        payload: {
          contact: savedContact,
          seectedId,
          appointments: savedContact.appointments,
        },
      });
      const idToken = yield call(getIdToken);
      // yield call(savePatientKeywords, action.payload.contact, patientOnDB, idToken, mainUser);
      for (let i = 0; i <= 2; i += 1) {
        try {
          yield call(
            savePatientKeywords,
            action.payload.contact,
            patientOnDB,
            addressUid,
            idToken,
            mainUser,
          );
          break;
        } catch (err) {
          if (i <= 1) {
            yield call(sleep, 3000);
          } else if (i === 2) {
            throw new Error('Failed to run generatePatientKeywords.');
          }
        }
      }
      yield put({
        type: actions.FETCH_PATIENT_DATA,
        payload: {
          id: seectedId,
        },
      });
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.SAVE_PATIENT_ERROR,
      });
    }
  });
}

function updateAppointmentsUserInfoOnDB(seectedId, contact, addressUid, idToken, mainUser) {
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  const newContact = _.cloneDeep(contact);
  if (newContact.avatar) {
    delete newContact.avatar;
  }
  const bodyParameters = {
    uid,
    seectedId,
    contact: newContact,
    addressUid,
  };
  // return true;
  return axios.post(
    `${ROOT_URL}/updateAppointmentsUserInfo`,
    bodyParameters,
    config,
  );
}

export function* updateAppointmentsUserInfo() {
  yield takeEvery(actions.UPDATE_APPOINTMENTS_USER_INFO, function* (action) {
    try {
      const addressUid = yield select(getSelectedAddressFromStore);
      const idToken = yield call(getIdToken);
      const mainUser = yield select(getMainUserFromStore);
      const { seectedId, contact } = action.payload;
      if (seectedId && contact) {
        yield call(updateAppointmentsUserInfoOnDB, seectedId, contact, addressUid, idToken, mainUser);
      }
    } catch (error) {
      console.warn(error);
    }
  });
}

function fetchPatientDataFromDB(patientId, addressUid, unified = {}, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    return get(child(dbRef, `/unified/${unified.id}/patients/${patientId}`));
    // return db.ref(`/unified/${unified.id}/patients/${patientId}`)
    //   .once('value');
  }
  return get(child(dbRef, `/users/${uid}/patients/${patientId}`));
  // return db.ref(`/users/${uid}/patients/${patientId}`)
  //   .once('value');
}

function fetchUnifiedPatientIdFromDB(patientId, unified) {
  const db = getDatabase();
  const dbRef = ref(db);
  return get(child(dbRef, `/unified/${unified.id}/patientsIndex/${patientId}`));
  // return db.ref(`/unified/${unified.id}/patientsIndex/${patientId}`)
  //   .once('value');
}

function fetchReferralListFromDB(addressUid, unified = {}, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    return get(child(dbRef, `/unified/${unified.id}/referralList`));
    // return db.ref(`/unified/${unified.id}/referralList`)
    //   .once('value');
  }
  return get(child(dbRef, `/users/${uid}/referralList`));
  // return db.ref(`/users/${uid}/referralList`)
  //   .once('value');
}

function* fetchReferralList() {
  yield takeLatest(actions.FETCH_REFERRAL_LIST, function* () {
    try {
      const unified = yield select(getUnifiedTokenStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      const mainUser = yield select(getMainUserFromStore);
      const referralList = yield call(fetchReferralListFromDB, addressUid, unified, mainUser);
      let referralListArr = [];
      if (referralList.val()) {
        referralListArr = _.map(referralList.val(), (val, id) => (
          { ...val, id }
        ));
      }
      yield put({
        type: actions.REFERRAL_LIST_SUCCESS,
        payload: referralListArr.filter((el) => el.value !== 'Facebook' && el.value !== 'Instagram' && el.value !== 'WhatsApp'),
      });
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.REFERRAL_LIST_ERROR,
      });
    }
  });
}

function* fetchPatientDataRequest() {
  yield takeLatest(actions.FETCH_PATIENT_DATA, function* (action) {
    try {
      const unified = yield select(getUnifiedTokenStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      const agendaId = yield select(getSelectedAgendaFromStore);
      const mainUser = yield select(getMainUserFromStore);
      const searchInput = yield select(getSearchInputFromStore);
      let patient = yield call(
        fetchPatientDataFromDB,
        action.payload.id,
        addressUid,
        unified,
        mainUser,
      );
      if (!patient.val()
        && unified.id && unified.address.some((a) => a === addressUid)) {
        console.warn(`Fetching patient id (${action.payload.id}) from the unified patientIndex list.`);
        const newPatientId = yield call(fetchUnifiedPatientIdFromDB, action.payload.id, unified);
        if (newPatientId.val()) {
          patient = yield call(
            fetchPatientDataFromDB,
            newPatientId.val(),
            addressUid,
            unified,
            mainUser,
          );
        }
      }
      if (!patient.val()
        && typeof action.payload.id === 'string'
        && action.payload.id.length === 20
        && action.payload.appointment
        && action.payload.appointment.userInfo
        && action.payload.appointment.userInfo.firstName
        && action.payload.appointment.userInfo.lastName) {
        console.warn(`Patient id (${action.payload.id}) could not be found. Creating one to fulfil the id.`);
        yield call(
          savePatientOnDB,
          {
            contact: action.payload.appointment.userInfo,
            appointmentId: action.payload.appointment.id,
            appointmentType: action.payload.appointment.canceled ? 'canceled' : 'confirmed',
          },
          action.payload.id,
          addressUid,
          agendaId,
          unified,
          mainUser,
        );
        patient = yield call(
          fetchPatientDataFromDB,
          action.payload.id,
          addressUid,
          unified,
          mainUser,
        );
      }
      const contactModel = {
        cpf: '',
        firstName: '',
        avatar: '',
        lastName: '',
        mobile: '',
        phone: '',
        birthday: '',
        email: '',
        profession: '',
        name: '',
        address: '',
        addressNumber: '',
        complement: '',
        cep: '',
        neighborhood: '',
        city: '',
        state: '',
        gender: '',
        note: '',
        // local: true,
      };
      const newPatient = {
        ...contactModel,
        ...patient.val(),
        id: patient.key,
        active: true,
      };
      yield put({
        type: actions.PATIENTS_FETCH_SUCCESS,
        payload: {
          patients: [newPatient],
          // searchInput: action.payload.search,
          searchInput,
          seectedId: patient.key,
        },
      });
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.PATIENTS_FETCH_ERROR,
      });
    }
  });
}

function patientsCpfQueryFromDB(
  { search, append },
  storeSearch,
  addressUid,
  unified = {},
  mainUser,
) {
  const db = getDatabase();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  let startToUse = search.toUpperCase();
  startToUse = startToUse
    .replace(/\s+/g, ' ')
    .replace(/^\s+|\s+$/g, '')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^0-9a-z]/g, '');
  const endAtToUse = append ? storeSearch.toUpperCase() : startToUse;
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    const databaseRef = ref(db, `/unified/${unified.id}/patients`);
    const queryRef = dbQuery(
      databaseRef,
      orderByChild('cpf'),
      limitToFirst(append ? 21 : 20),
      startAt(startToUse),
      endAt(`${endAtToUse}\uf8ff`),
    );
    return new Promise((resolve) => {
      onValue(queryRef, resolve, { onlyOnce: true });
    });
    // return db.ref(`/unified/${unified.id}/patients`)
    //   .orderByChild('cpf')
    //   .limitToFirst(append ? 21 : 20)
    //   .startAt(startToUse)
    //   .endAt(`${endAt}\uf8ff`)
    //   .once('value');
  }
  const databaseRef = ref(db, `/users/${uid}/patients`);
  const queryRef = dbQuery(
    databaseRef,
    orderByChild('cpf'),
    limitToFirst(append ? 21 : 20),
    startAt(startToUse),
    endAt(`${endAtToUse}\uf8ff`),
  );
  return new Promise((resolve) => {
    onValue(queryRef, resolve, { onlyOnce: true });
  });
  // return db.ref(`/users/${uid}/patients`)
  //   .orderByChild('cpf')
  //   .limitToFirst(append ? 21 : 20)
  //   .startAt(startToUse)
  //   .endAt(`${endAt}\uf8ff`)
  //   .once('value');
}

function patientsFirestoreQueryFromDB(
  { search, append },
  storeSearch,
  lastDoc,
  addressUid,
  unified = {},
  mainUser,
  active,
) {
  const fs = getFirestore();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  let startToUse = append ? storeSearch.toLowerCase() : search.toLowerCase();
  startToUse = startToUse.replace(/\s+/g, ' ').replace(/^\s+|\s+$/g, '').normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  const paginateAt = append && lastDoc ? lastDoc : search.toLowerCase();
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    const colRef = collection(
      fs,
      'unified',
      unified.id,
      'queryPatients',
    );
    const q = fsQuery(
      colRef,
      where('keywords', 'array-contains', startToUse),
      where('active', '==', active),
      orderBy('keywords'),
      startAfter(paginateAt),
      limit(20),
    );
    return getDocsFromServer(q);
    // return fs.collection('unified').doc(unified.id).collection('queryPatients')
    //   .where('keywords', 'array-contains', startToUse)
    //   .where('active', '==', active)
    //   .orderBy('keywords')
    //   .startAfter(paginateAt)
    //   .limit(20)
    //   .get();
  }
  const colRef = collection(
    fs,
    'professionals',
    uid,
    'queryPatients',
  );
  const q = fsQuery(
    colRef,
    where('keywords', 'array-contains', startToUse),
    where('active', '==', active),
    orderBy('keywords'),
    startAfter(paginateAt),
    limit(20),
  );
  return getDocsFromServer(q);
  // return fs.collection('professionals').doc(uid).collection('queryPatients')
  //   .where('keywords', 'array-contains', startToUse)
  //   .where('active', '==', active)
  //   .orderBy('keywords')
  //   .startAfter(paginateAt)
  //   .limit(20)
  //   .get();
}

const isNumber = (value) => !Number.isNaN(Number(value));

function fetchPatientsPerIdFromDB(idArray = [], addressUid, unified = {}, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const promises = [];
  idArray.forEach((id) => {
    if (unified.id && unified.address.some((a) => a === addressUid)) {
      promises.push(
        get(child(dbRef, `unified/${unified.id}/patients/${id}`)),
        // db.ref(`unified/${unified.id}/patients/${id}`)
        //   .once('value'),
      );
    } else {
      promises.push(
        get(child(dbRef, `users/${uid}/patients/${id}`)),
        // db.ref(`users/${uid}/patients/${id}`)
        //   .once('value'),
      );
    }
  });
  return Promise.all(promises);
}

export function* searchActivePatientsRequest() {
  yield takeLatest(actions.SEARCH_PATIENTS_REQUEST, function* (action) {
    try {
      yield put({
        type: actions.SEARCHING_PATIENTS,
      });
      if (action.payload.append) {
        yield put({
          type: actions.SEARCHING_PATIENTS_APPEND,
        });
      }
      yield call(sleep, 1000);
      const unified = yield select(getUnifiedTokenStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      const storePatients = yield select(getPatientsFromStore);
      const storeSearch = yield select(getSearchInputFromStore);
      const mainUser = yield select(getMainUserFromStore);
      let noNextPage = false;
      let finalArr = [];
      let searchInput = '';
      // const patientsFullNameArr = [];
      // const patientsLastNameArr = [];
      const patientsCpfArr = [];
      const { requestMode } = action.payload;
      const normalizedSearch = action.payload.search
        .replace(/\s+/g, ' ')
        .replace(/^\s+|\s+$/g, '')
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/[^0-9a-zA-Z]/g, '');
      if (isNumber(normalizedSearch)) {
        const patientsCpf = yield call(
          patientsCpfQueryFromDB,
          action.payload,
          storeSearch,
          addressUid,
          unified,
          mainUser,
        );
        patientsCpf.forEach((p) => {
          if (p.val().fullName) {
            patientsCpfArr.push({
              ...p.val(),
              id: p.key,
              active: true,
            });
          }
        });
        if (action.payload.append) {
          finalArr.push(...storePatients);
          finalArr.push(...patientsCpfArr.slice(1, 21));
          searchInput = storeSearch;
        } else {
          finalArr = [...patientsCpfArr].slice(0, 20);
          searchInput = action.payload.search;
        }
        if (finalArr.length === storePatients.length) {
          noNextPage = true;
        }
        yield put({
          type: actions.PATIENTS_FETCH_SUCCESS,
          payload: {
            patients: finalArr,
            searchInput,
            noNextPage,
          },
        });
      } else if (requestMode === 'all' || requestMode === 'active') {
        const lastDoc = yield select(getLastDocFromStore);
        const patientsFirestoreArr = [];
        const querySnapshot = yield call(
          patientsFirestoreQueryFromDB,
          action.payload,
          storeSearch,
          lastDoc,
          addressUid,
          unified,
          mainUser,
          true,
        );
        const lastVisibleDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
        querySnapshot.forEach((document) => {
          patientsFirestoreArr.push(document.id);
        });
        const patientsArr = [];
        if (querySnapshot.empty) {
          noNextPage = true;
        } else {
          const promisesResponse = yield call(
            fetchPatientsPerIdFromDB,
            patientsFirestoreArr,
            addressUid,
            unified,
            mainUser,
          );
          promisesResponse.forEach((r, index) => {
            if (r.val()) {
              patientsArr.push({
                ...r.val(),
                id: r.key,
                active: true,
              });
            } else {
              console.warn(`Patient id (${patientsFirestoreArr[index]}) could not be downloaded`);
            }
          });
        }
        if (action.payload.append) {
          finalArr.push(...storePatients);
          finalArr.push(...patientsArr);
          searchInput = storeSearch;
        } else {
          finalArr = [...patientsArr];
          searchInput = action.payload.search;
        }
        yield put({
          type: actions.PATIENTS_FETCH_SUCCESS,
          payload: {
            patients: finalArr,
            searchInput,
            noNextPage,
            lastVisibleDoc,
          },
        });
      }
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.PATIENTS_FETCH_ERROR,
      });
    }
  });
}

export function* searchDeletedPatientsRequest() {
  yield takeLatest(actions.SEARCH_PATIENTS_REQUEST, function* (action) {
    try {
      yield put({
        type: actions.SEARCHING_PATIENTS,
      });
      if (action.payload.append) {
        yield put({
          type: actions.SEARCHING_PATIENTS_APPEND,
        });
      }
      yield call(sleep, 1000);
      const unified = yield select(getUnifiedTokenStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      const storePatients = yield select(getDeletedPatientsFromStore);
      const storeSearch = yield select(getSearchInputFromStore);
      const mainUser = yield select(getMainUserFromStore);
      let noNextPage = false;
      let finalArr = [];
      let searchInput = '';
      // const patientsFullNameArr = [];
      // const patientsLastNameArr = [];
      const patientsCpfArr = [];
      const { requestMode } = action.payload;
      const normalizedSearch = action.payload.search
        .replace(/\s+/g, ' ')
        .replace(/^\s+|\s+$/g, '')
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/[^0-9a-zA-Z]/g, '');
      if (isNumber(normalizedSearch)) {
        const patientsCpf = yield call(
          patientsCpfQueryFromDB,
          action.payload,
          storeSearch,
          addressUid,
          unified,
          mainUser,
        );
        patientsCpf.forEach((p) => {
          if (!p.val().fullName) {
            patientsCpfArr.push({
              ...p.val(),
              id: p.key,
              active: false,
            });
          }
        });
        if (action.payload.append) {
          finalArr.push(...storePatients);
          finalArr.push(...patientsCpfArr.slice(1, 21));
          searchInput = storeSearch;
        } else {
          finalArr = [...patientsCpfArr].slice(0, 20);
          searchInput = action.payload.search;
        }
        if (finalArr.length === storePatients.length) {
          noNextPage = true;
        }
        yield put({
          type: actions.DELETED_PATIENTS_FETCH_SUCCESS,
          payload: {
            patients: finalArr,
            searchInput,
            noNextPage,
          },
        });
      } else if (requestMode === 'all' || requestMode === 'deleted') {
        const lastDoc = yield select(getLastDeletedDocFromStore);
        const patientsFirestoreArr = [];
        const querySnapshot = yield call(
          patientsFirestoreQueryFromDB,
          action.payload,
          storeSearch,
          lastDoc,
          addressUid,
          unified,
          mainUser,
          false,
        );
        const lastVisibleDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
        querySnapshot.forEach((document) => {
          patientsFirestoreArr.push(document.id);
        });
        const patientsArr = [];
        if (querySnapshot.empty) {
          noNextPage = true;
        } else {
          const promisesResponse = yield call(
            fetchPatientsPerIdFromDB,
            patientsFirestoreArr,
            addressUid,
            unified,
            mainUser,
          );
          promisesResponse.forEach((r, index) => {
            if (r.val()) {
              patientsArr.push({
                ...r.val(),
                id: r.key,
                active: false,
              });
            } else {
              console.warn(`Patient id (${patientsFirestoreArr[index]}) could not be downloaded`);
            }
          });
        }
        if (action.payload.append) {
          finalArr.push(...storePatients);
          finalArr.push(...patientsArr);
          searchInput = storeSearch;
        } else {
          finalArr = [...patientsArr];
          searchInput = action.payload.search;
        }
        yield put({
          type: actions.DELETED_PATIENTS_FETCH_SUCCESS,
          payload: {
            patients: finalArr,
            searchInput,
            noNextPage,
            lastVisibleDoc,
          },
        });
      }
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.PATIENTS_FETCH_ERROR,
      });
    }
  });
}

function fetchZipData(code) {
  // const config = {
  //   headers: {
  //     // Authorization: `Bearer ${idToken}`,
  //     // 'Access-Control-Allow-Origin': '*',
  //     // 'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,OPTIONS',
  //     // 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
  //     // crossdomain: true,
  //   },
  // };
  return cep(code);
  // return axios.options(`https://viacep.com.br/ws/${code}/json`, config);
}

function setZipData(data) {
  const zipData = {
    // ...contact,
    // neighborhood: data.bairro ? data.bairro : '',
    // city: data.localidade ? data.localidade : '',
    // address: data.logradouro ? data.logradouro : '',
    // complement: data.complemento ? data.complemento : '',
    // state: data.uf ? data.uf : '',
    neighborhood: data.neighborhood ? data.neighborhood : '',
    city: data.city ? data.city : '',
    address: data.street ? data.street : '',
    complement: data.complemento ? data.complemento : '',
    state: data.state ? data.state : '',
  };
  return zipData;
}

function ascendingSort(contact1, contact2) {
  const name1 = contact1.name ? contact1.name.toUpperCase() : '~';
  const name2 = contact2.name ? contact2.name.toUpperCase() : '~';
  // eslint-disable-next-line
  return name1 > name2 ? 1 : name1 === name2 ? 0 : -1;
}

function* searchZipCodeRequest() {
  yield takeLatest(actions.SEARCH_ZIP_CODE_REQUEST, function* (action) {
    try {
      const response = yield call(fetchZipData, action.payload);
      const seectedId = yield select(getSeectedIdFromStore);
      const zipData = setZipData(response);
      yield put({
        type: actions.SEARCH_ZIP_CODE_SUCCESS,
        payload: {
          id: seectedId,
          zipData,
        },
      });
    } catch (error) {
      console.warn(error);
      // yield put({
      //   type: actions.PATIENTS_FETCH_ERROR,
      // });
    }
  });
}

function getPatientHistoryOnDB(patientId, addressUid, idToken, mainUser) {
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  const bodyParameters = {
    // payload,
    patientId,
    uid,
    addressUid,
  };
  return axios.post(`${ROOT_URL}/getPatientHistory`, bodyParameters, config);
}

export function* getPatientHistory() {
  yield takeEvery(actions.PATIENT_HISTORY_REQUEST, function* (action) {
    try {
      const idToken = yield call(getIdToken);
      const mainUser = yield select(getMainUserFromStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      const seectedId = yield select(getSeectedIdFromStore);
      const response = yield call(
        getPatientHistoryOnDB,
        // action.payload,
        action.payload || seectedId,
        addressUid,
        idToken,
        mainUser,
      );
      if (response) {
        const currentDate = moment().tz('America/Sao_Paulo');
        const filteredBatched = [];
        if (response?.data?.batched) {
          Object.keys(response.data.batched).forEach((key) => {
            const rule = rrulestr(response.data.batched[key].rrule);
            const ruleAfter = rule.after(datetime(currentDate.year(), currentDate.month() + 1, currentDate.date()));
            if (ruleAfter) {
              const firstRecurrenceDate = moment(
                ruleAfter,
              ).utcOffset(0).format('YYYY-MM-DD');
              filteredBatched.push({
                ...response.data.batched[key],
                key,
                time: `${firstRecurrenceDate} ${response.data.batched[key].appointmentModel.time.split(' ')[1]}`,
              });
            }
          });
        }
        yield put({
          type: actions.PATIENT_HISTORY_SUCCESS,
          payload: {
            // data: sorted,
            seectedId: action.payload || seectedId,
            data: response?.data?.appointments || {},
            batched: _.orderBy(filteredBatched, ['firstRecurrenceDate']),
            // requestedValue: action.payload,
          },
        });
      }
    } catch (error) {
      console.warn(error);
      yield put({ type: actions.PATIENT_HISTORY_ERROR });
    }
  });
}

function mergeCpfQueryFromDB(search, addressUid, unified = {}, mainUser) {
  const db = getDatabase();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    const databaseRef = ref(db, `/unified/${unified.id}/patients`);
    const queryRef = dbQuery(
      databaseRef,
      orderByChild('cpf'),
      limitToFirst(20),
      startAt(search),
      endAt(`${search}\uf8ff`),
    );
    return new Promise((resolve) => {
      onValue(queryRef, resolve, { onlyOnce: true });
    });
    // return db.ref(`/unified/${unified.id}/patients`)
    //   .orderByChild('cpf')
    //   .limitToFirst(20)
    //   .startAt(search)
    //   .endAt(`${search}\uf8ff`)
    //   .once('value');
  }
  const databaseRef = ref(db, `/users/${uid}/patients`);
  const queryRef = dbQuery(
    databaseRef,
    orderByChild('cpf'),
    limitToFirst(20),
    startAt(search),
    endAt(`${search}\uf8ff`),
  );
  return new Promise((resolve) => {
    onValue(queryRef, resolve, { onlyOnce: true });
  });
  // return db.ref(`/users/${uid}/patients`)
  //   .orderByChild('cpf')
  //   .limitToFirst(20)
  //   .startAt(search)
  //   .endAt(`${search}\uf8ff`)
  //   .once('value');
}

function mergePatientsQueryFromDB(search, mainUser) {
  const fs = getFirestore();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const formatedSearch = search.replace(/\s+/g, ' ').replace(/^\s+|\s+$/g, '').normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  const colRef = collection(
    fs,
    'professionals',
    uid,
    'queryPatients',
  );
  const q = fsQuery(
    colRef,
    where('keywords', 'array-contains', formatedSearch),
    where('active', '==', true),
    orderBy('keywords'),
    limit(20),
  );
  return getDocsFromServer(q);
  // return fs.collection('professionals').doc(uid).collection('queryPatients')
  //   .where('keywords', 'array-contains', formatedSearch)
  //   .where('active', '==', true)
  //   .orderBy('keywords')
  //   .limit(20)
  //   .get();
}

function nameCombination(array) {
  return array.reduce((ret, el, index, arr) => {
    const indexAux = index + 1;
    let retAux = ret;
    const n = arr.slice(indexAux);
    [2, 3].forEach((c) => {
      retAux = retAux.concat(n.map((j, i) => (
        [el].concat(n.slice(i)).slice(0, c).join(' ')
      )));
    });
    if (index === arr.length - 1) retAux.pop();
    return retAux;
  }, []);
}

function* searchMergeArray(arrToSearch, mainUser) {
  return yield all(arrToSearch.map((s) => call(mergePatientsQueryFromDB, s, mainUser)));
}

// function fetchRecordsPerIdFromDB(idArray = [], addressUid, unified = {}, mainUser) {
//   const db = firebase.database();
//   let uid;
//   if (mainUser) {
//     uid = mainUser;
//   } else {
//     const { currentUser } = firebase.auth();
//     ({ uid } = currentUser);
//   }
//   const promises = [];
//   idArray.forEach((id) => {
//     if (unified.id && unified.address.some((a) => a === addressUid)) {
//       promises.push(
//         db.ref(`unified/${unified.id}/records/${id}`)
//           .once('value'),
//       );
//     } else {
//       promises.push(
//         db.ref(`users/${uid}/records/${id}`)
//           .once('value'),
//       );
//     }
//   });
//   return Promise.all(promises);
// }

// function fetchPrescriptionsPerIdFromDB(idArray = [], addressUid, unified = {}, mainUser) {
//   const db = firebase.database();
//   let uid;
//   if (mainUser) {
//     uid = mainUser;
//   } else {
//     const { currentUser } = firebase.auth();
//     ({ uid } = currentUser);
//   }
//   const promises = [];
//   idArray.forEach((id) => {
//     if (unified.id && unified.address.some((a) => a === addressUid)) {
//       promises.push(
//         db.ref(`unified/${unified.id}/prescriptions/${id}`)
//           .once('value'),
//       );
//     } else {
//       promises.push(
//         db.ref(`users/${uid}/prescriptions/${id}`)
//           .once('value'),
//       );
//     }
//   });
//   return Promise.all(promises);
// }

function fetchPatientDataPerIdFromDB(mode, idArray = [], addressUid, unified = {}, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const promises = [];
  idArray.forEach((id) => {
    if (unified.id && unified.address.some((a) => a === addressUid)) {
      promises.push(
        get(child(dbRef, `unified/${unified.id}/${mode}/${id}`)),
        // db.ref(`unified/${unified.id}/${mode}/${id}`)
        //   .once('value'),
      );
    } else {
      promises.push(
        get(child(dbRef, `users/${uid}/${mode}/${id}`)),
        // db.ref(`users/${uid}/${mode}/${id}`)
        //   .once('value'),
      );
    }
  });
  return Promise.all(promises);
}

function fetchAppointmentsHistoryPerIdFromDB(idArray = [], addressUid, unified = {}, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const promises = [];
  idArray.forEach((id) => {
    if (unified.id && unified.address.some((a) => a === addressUid)) {
      promises.push(
        get(child(dbRef, `unified/${unified.id}/appointmentsHistory/${id}`)),
        // db.ref(`unified/${unified.id}/appointmentsHistory/${id}`)
        //   .once('value'),
      );
    } else {
      promises.push(
        get(child(dbRef, `users/${uid}/appointmentsHistory/${id}`)),
        // db.ref(`users/${uid}/appointmentsHistory/${id}`)
        //   .once('value'),
      );
    }
  });
  return Promise.all(promises);
}

function mergePatientsOnDB(payload, addressUid, idToken, mainUser) {
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  const bodyParameters = {
    ...payload,
    uid,
    addressUid,
  };
  return axios.post(`${ROOT_URL}/mergePatients`, bodyParameters, config);
}

export function* mergePatientRequest() {
  yield takeEvery(actions.FIND_POSSIBLE_MERGE_PATIENTS_REQUEST, function* (action) {
    try {
      yield put({ type: actions.START_SEARCHING_MERGE_PATIENTS });
      const unified = yield select(getUnifiedTokenStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      const mainUser = yield select(getMainUserFromStore);
      let continueConfirm = true;
      const patientsFirestoreArr = [];
      // mergePatientsArr: array to save potential merge patient's profile
      const mergePatientsArr = [];
      const { key, pending } = action.payload;
      if (pending[key] && pending[key].userInfo) {
        const { firstName, lastName, cpf } = pending[key].userInfo;
        if (cpf) {
          // Searching for patients with the same CPF to merge
          const cpfSearch = yield call(
            mergeCpfQueryFromDB,
            cpf,
            addressUid,
            unified,
            mainUser,
          );
          const cpfSearchArr = [];
          const cpfIdArr = [];
          cpfSearch.forEach((p) => {
            if (p.val().fullName) {
              cpfSearchArr.push({
                ...p.val(),
                id: p.key,
              });
              cpfIdArr.push(p.key);
            }
          });
          const [
            records,
            prescriptions,
            certificates,
            medicalReports,
            otherDocuments,
            appointmentsHistory,
          ] = yield all([
            call(fetchPatientDataPerIdFromDB, 'records', cpfIdArr, addressUid, unified, mainUser),
            call(fetchPatientDataPerIdFromDB, 'prescriptions', cpfIdArr, addressUid, unified, mainUser),
            call(fetchPatientDataPerIdFromDB, 'certificates', cpfIdArr, addressUid, unified, mainUser),
            call(fetchPatientDataPerIdFromDB, 'medicalReports', cpfIdArr, addressUid, unified, mainUser),
            call(fetchPatientDataPerIdFromDB, 'otherDocuments', cpfIdArr, addressUid, unified, mainUser),
            call(fetchAppointmentsHistoryPerIdFromDB, cpfIdArr, addressUid, unified, mainUser),
          ]);
          const recordsArr = [];
          records.forEach((el) => {
            recordsArr.push(el.val());
          });
          const prescriptionsArr = [];
          prescriptions.forEach((el) => {
            prescriptionsArr.push(el.val());
          });
          const certificatesArr = [];
          certificates.forEach((el) => {
            certificatesArr.push(el.val());
          });
          const medicalReportsArr = [];
          medicalReports.forEach((el) => {
            medicalReportsArr.push(el.val());
          });
          const otherDocumentsArr = [];
          otherDocuments.forEach((el) => {
            otherDocumentsArr.push(el.val());
          });
          const appointmentsHistoryArr = [];
          appointmentsHistory.forEach((el) => {
            appointmentsHistoryArr.push(el.val());
          });
          cpfSearchArr.forEach((el, index) => {
            mergePatientsArr.push({
              ...el,
              fullName: `${el.firstName} ${el.lastName}`,
              records: recordsArr[index],
              prescriptions: prescriptionsArr[index],
              certificates: certificatesArr[index],
              medicalReports: medicalReportsArr[index],
              otherDocuments: otherDocumentsArr[index],
              appointmentsHistory: appointmentsHistoryArr[index],
              foundBy: 'CPF',
            });
          });
        }
        const fullName = `${firstName} ${lastName}`.toLowerCase();
        // Searching for patients with equal fullName to merge
        const fullNameSearch = yield call(mergePatientsQueryFromDB, fullName, mainUser);
        if (fullNameSearch.empty) {
          // Using fullName did not find any patient.
          // Splitting name to query separately
          const splitName = fullName.split(' ');
          const nameArr = splitName.slice(0, 3);
          if (splitName.length > 3) {
            nameArr.push(splitName.slice(3, splitName.length - 1).join(' '));
            nameArr.push(splitName.slice(splitName.length - 1).join(' '));
          }
          const arrToSearch = nameCombination(nameArr);
          // Seaching using the splitted name
          const searchArrayResponse = yield call(searchMergeArray, arrToSearch, mainUser);
          searchArrayResponse.forEach((query) => {
            if (!query.empty) {
              query.forEach((documnt) => {
                patientsFirestoreArr.push(documnt.id);
              });
            }
          });
        } else {
          fullNameSearch.forEach((documnt) => {
            patientsFirestoreArr.push(documnt.id);
          });
        }
      }
      if (patientsFirestoreArr.length > 0) {
        // Found similar patients using the firestore indexes
        const nonDuplicateArr = patientsFirestoreArr.filter((value, index, self) => self.indexOf(value) === index);
        const [
          records,
          prescriptions,
          certificates,
          medicalReports,
          otherDocuments,
          appointmentsHistory,
        ] = yield all([
          call(fetchPatientDataPerIdFromDB, 'records', nonDuplicateArr, addressUid, unified, mainUser),
          call(fetchPatientDataPerIdFromDB, 'prescriptions', nonDuplicateArr, addressUid, unified, mainUser),
          call(fetchPatientDataPerIdFromDB, 'certificates', nonDuplicateArr, addressUid, unified, mainUser),
          call(fetchPatientDataPerIdFromDB, 'medicalReports', nonDuplicateArr, addressUid, unified, mainUser),
          call(fetchPatientDataPerIdFromDB, 'otherDocuments', nonDuplicateArr, addressUid, unified, mainUser),
          call(fetchAppointmentsHistoryPerIdFromDB, nonDuplicateArr, addressUid, unified, mainUser),
        ]);
        const recordsArr = [];
        records.forEach((el) => {
          recordsArr.push(el.val());
        });
        const prescriptionsArr = [];
        prescriptions.forEach((el) => {
          prescriptionsArr.push(el.val());
        });
        const certificatesArr = [];
        certificates.forEach((el) => {
          certificatesArr.push(el.val());
        });
        const medicalReportsArr = [];
        medicalReports.forEach((el) => {
          medicalReportsArr.push(el.val());
        });
        const otherDocumentsArr = [];
        otherDocuments.forEach((el) => {
          otherDocumentsArr.push(el.val());
        });
        const appointmentsHistoryArr = [];
        appointmentsHistory.forEach((el) => {
          appointmentsHistoryArr.push(el.val());
        });
        // Fecthing profiles of patients id found
        const mergeContactsResponse = yield call(
          fetchPatientsPerIdFromDB,
          nonDuplicateArr,
          addressUid,
          unified,
          mainUser,
        );
        mergeContactsResponse.forEach((r, index) => {
          if (r.val()) {
            mergePatientsArr.push({
              ...r.val(),
              id: r.key,
              fullName: `${r.val().firstName} ${r.val().lastName}`,
              records: recordsArr[index],
              prescriptions: prescriptionsArr[index],
              certificates: certificatesArr[index],
              medicalReports: medicalReportsArr[index],
              otherDocuments: otherDocumentsArr[index],
              appointmentsHistory: appointmentsHistoryArr[index],
              foundBy: 'Nome',
            });
          } else {
            console.warn(`Patient id (${nonDuplicateArr[index]}) could not be downloaded`);
          }
        });
      }
      // Removing duplicates from the mergePatientsArr
      const finalArr = _.uniqBy(mergePatientsArr, 'id').filter((el) => el.id !== pending[key].user);
      if (finalArr.length > 0) {
        // Showing to user the modal with possible merges
        yield put({
          type: actions.FIND_POSSIBLE_MERGE_PATIENTS_SUCCESS,
          payload: {
            arr: finalArr,
          },
        });
        const userResponse = yield take(actions.USER_RESPONDED_MERGE_REQUEST);
        if (userResponse.payload) {
          if (userResponse.payload !== 'confirmOnly') {
            // Merge needed
            yield put({
              type: actions.START_MERGING_PATIENTS,
            });
            const idToken = yield call(getIdToken);
            // Make the merge with the selected patients
            yield call(
              mergePatientsOnDB,
              userResponse.payload,
              addressUid,
              idToken,
              mainUser,
            );
            message.success('Pacientes juntados com sucesso.');
            if (userResponse.payload.mainPatientId) {
              yield put({
                type: actions.FETCH_PATIENT_DATA,
                payload: {
                  id: userResponse.payload.mainPatientId,
                },
              });
            }
          }
        } else {
          continueConfirm = false;
        }
      }
      yield put({
        type: actions.FINISHED_MERGE_PATIENT_PROCESS,
        payload: continueConfirm,
      });
    } catch (error) {
      console.warn(error);
      notification('error', 'Algo deu errado! Não foi possível juntar os pacientes.');
      yield all([
        put({ type: actions.MERGE_PATIENT_ERROR }),
        put({
          type: actions.FINISHED_MERGE_PATIENT_PROCESS,
          payload: false,
        }),
      ]);
    }
  });
}

function getFormSettingsFromDB(currentAddress, mainUser) {
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const db = getDatabase();
  const dbRef = ref(db);
  return get(child(dbRef, `settings/${uid}/${currentAddress}/formFields`));
  // return db.ref(`settings/${uid}/${currentAddress}/formFields`)
  //   .once('value');
}

export function* getFormSettingsRequest() {
  yield takeLatest(actions.GET_FORM_SETTINGS_REQUEST, function* (action) {
    let currentAddress = yield select(getSelectedAddressFromStore);
    try {
      if (!currentAddress) {
        yield take(appActions.SELECT_ADDRESS);
        currentAddress = yield select(getSelectedAddressFromStore);
      }
      const mainUser = yield select(getMainUserFromStore);
      const settings = yield call(getFormSettingsFromDB, currentAddress, mainUser);
      const defaultObj = {
        mobile: true,
        whatsapp: true,
        phoneArr: true,
        phones: true,
        email: true,
        privateAppointment: true,
        plans: true,
        gender: true,
        birthday: true,
        cpf: true,
        rg: true,
        profession: true,
        qtyChildren: true,
        fatherName: true,
        motherName: true,
        fatherAge: true,
        motherAge: true,
        fatherProfession: true,
        motherProfession: true,
        civilState: true,
        spouse: true,
        spouseBirthday: true,
        spouseCpf: true,
        spouseProfession: true,
        schooling: true,
        cep: true,
        neighborhood: true,
        address: true,
        complement: true,
        addressNumber: true,
        city: true,
        state: true,
        note: true,
        referral: true,
        requestingDoctor: true,
        print: {
          mobile: true,
          whatsapp: true,
          phoneArr: true,
          phones: true,
          email: true,
          privateAppointment: true,
          plans: true,
          gender: true,
          birthday: true,
          cpf: true,
          rg: true,
          profession: true,
          qtyChildren: true,
          fatherName: true,
          motherName: true,
          fatherAge: true,
          motherAge: true,
          fatherProfession: true,
          motherProfession: true,
          civilState: true,
          spouse: true,
          spouseBirthday: true,
          spouseCpf: true,
          spouseProfession: true,
          schooling: true,
          cep: true,
          neighborhood: true,
          address: true,
          complement: true,
          addressNumber: true,
          city: true,
          state: true,
          note: true,
          referral: true,
          requestingDoctor: true,
        },
      };
      let finalObj = {};
      if (settings.val()) {
        finalObj = {
          ...defaultObj,
          ...settings.val(),
        };
        if (settings.val().print) {
          const finalPrintObj = {
            ...defaultObj.print,
            ...settings.val().print,
          };
          finalObj.print = {
            ...finalPrintObj,
          };
        }
      } else {
        finalObj = {
          ...defaultObj,
        };
      }
      if (action.payload.closeForm) {
        yield all([
          put({
            type: actions.GET_FORM_SETTINGS_SUCCESS,
            payload: {
              formSettings: finalObj,
            },
          }),
          put({ type: actions.DISCARD_FORM_SETTINGS }),
        ]);
      } else {
        yield put({
          type: actions.GET_FORM_SETTINGS_SUCCESS,
          payload: {
            formSettings: finalObj,
          },
        });
      }
    } catch (error) {
      console.warn(error);
    }
  });
}

function saveFormSettingsOnDB(currentAddress, settings, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  updates[`settings/${uid}/${currentAddress}/formFields`] = settings;
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* saveFormSettings() {
  yield takeEvery(actions.SAVE_FORM_SETTINGS_REQUEST, function* () {
    try {
      yield put({
        type: actions.START_SAVING_FORM_SETTINGS,
      });
      yield call(sleep, 500);
      let currentAddress = yield select(getSelectedAddressFromStore);
      if (!currentAddress) {
        yield take(appActions.SELECT_ADDRESS);
        currentAddress = yield select(getSelectedAddressFromStore);
      }
      const settings = yield select(getFormSettingsFromStore);
      const mainUser = yield select(getMainUserFromStore);
      yield call(saveFormSettingsOnDB, currentAddress, settings, mainUser);
      yield put({
        type: actions.GET_FORM_SETTINGS_REQUEST,
        payload: {
          closeForm: true,
        },
      });
    } catch (error) {
      console.warn(error);
      yield put({ type: actions.SAVE_FORM_SETTINGS_ERROR });
    }
  });
}

function getPatientFinancialHistoryFromCloud(idToken, mainUser, addressId, patientId) {
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  const bodyParameters = {
    uid,
    addressId,
    patientId,
  };
  return axios.post(`${ROOT_URL}/getPatientFinancialHistory`, bodyParameters, config);
}

export function* getPatientFinancialHistory() {
  yield takeEvery(actions.GET_PATIENT_FINANCIAL_HISTORY_REQUEST, function* () {
    try {
      yield put({ type: actions.LOADING_PATIENT_FINANCIAL_HISTORY });
      const idToken = yield call(getIdToken);
      const mainUser = yield select(getMainUserFromStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      const seectedId = yield select(getSeectedIdFromStore);
      const { data } = yield call(
        getPatientFinancialHistoryFromCloud,
        idToken,
        mainUser,
        addressUid,
        seectedId,
      );
      if (data) {
        const sorted = _.orderBy(data, ['timestamp']);
        yield put({
          type: actions.GET_PATIENT_FINANCIAL_HISTORY_SUCCESS,
          payload: {
            list: sorted,
            seectedId,
          },
        });
      }
    } catch (error) {
      console.warn(error);
      yield put({ type: actions.GET_PATIENT_FINANCIAL_HISTORY_ERROR });
    }
  });
}

export function* checkForDuplicatePatientRequest() {
  yield takeLatest(actions.CHECK_FOR_DUPLICATE_PATIENT_REQUEST, function* (action) {
    try {
      const unified = yield select(getUnifiedTokenStore);
      const mainUser = yield select(getMainUserFromStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      const patientDuplicateArr = yield select(getPatientDuplicateArrFromStore);
      const { cpf } = action.payload;
      if (cpf && !patientDuplicateArr[cpf]) {
        // Searching for patients with the same CPF to merge
        const cpfSearch = yield call(
          mergeCpfQueryFromDB,
          cpf,
          addressUid,
          unified,
          mainUser,
        );
        const cpfSearchArr = [];
        cpfSearch.forEach((p) => {
          if (p.val().fullName) {
            cpfSearchArr.push({
              ...p.val(),
              id: p.key,
            });
          }
        });
        yield put({
          type: actions.CHECK_FOR_DUPLICATE_PATIENT_SUCCESS,
          payload: {
            cpfSearchArr,
            cpf,
            // seectedId,
          },
        });
      }
    } catch (error) {
      console.warn(error);
      yield put({ type: actions.CHECK_FOR_DUPLICATE_PATIENT_ERROR });
    }
  });
}

function getAvatarZapi(idToken, phone) {
  const config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  const bodyParameters = {
    phone,
  };
  return axios.post(`${ROOT_URL}/getPatientWhatsAppImage`, bodyParameters, config);
}

function getBlob(zapiResponse) {
  return zapiResponse.blob();
}

function getImageFromZapiLink(zapiResponse) {
  return fetch(zapiResponse);
}

export function* getWhatsappAvatar() {
  yield takeEvery(actions.AVATAR_WHATSAPP_FETCH_REQUEST, function* () {
    yield put({ type: actions.SAVE_PATIENT_AVATAR_WAITING });
    const seectedId = yield select(getSeectedIdFromStore);
    const patients = yield select(getPatientsFromStore);
    const idToken = yield call(getIdToken);
    const selectedPatient = patients.find((el) => el.id === seectedId);
    try {
      const zapiResponse = yield call(getAvatarZapi, idToken, selectedPatient?.mobile);
      if (zapiResponse?.data?.link && zapiResponse.data.link !== 'null') {
        const imageFromZapi = yield call(getImageFromZapiLink, zapiResponse?.data?.link);
        const blob = yield call(getBlob, imageFromZapi);
        if (blob) {
          yield put({
            type: actions.SAVE_PATIENT_AVATAR_FILE,
            payload: blob,
          });
        } else {
          yield put({ type: actions.AVATAR_WHATSAPP_FETCH_ERROR });
        }
      } else {
        yield put({ type: actions.AVATAR_WHATSAPP_FETCH_ERROR });
      }
    } catch (error) {
      console.warn(error);
      yield put({ type: actions.AVATAR_WHATSAPP_FETCH_ERROR });
    }
  });
}

function fetchBirthdaysFromDB(
  // { search, append },
  // storeSearch,
  addressUid,
  unified = {},
  mainUser,
) {
  const db = getDatabase();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const currentDate = moment().tz('America/Sao_Paulo').format('MM-DD');
  const startAtToUse = currentDate;
  const endAtToUse = startAtToUse;
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    const databaseRef = ref(db, `/unified/${unified.id}/patients`);
    const queryRef = dbQuery(
      databaseRef,
      orderByChild('birthday'),
      startAt(startAtToUse),
      endAt(`${endAtToUse}\uf8ff`),
    );
    return new Promise((resolve) => {
      onValue(queryRef, resolve, { onlyOnce: true });
    });
    // return db.ref(`/unified/${unified.id}/patients`)
    //   .orderByChild('birthday')
    //   .startAt(startAtToUse)
    //   .endAt(`${endAtToUse}\uf8ff`)
    //   .once('value');
  }
  // Example in case pagination is needed.
  // const lastItem = '-M6zLeccT73Er8u2IpLF';
  // if (lastItem) {
  //   return db.ref(`/users/${uid}/patients`)
  //     .orderByChild('birthday')
  //     .startAfter('04-18-1991', lastItem)
  //     .endAt(`${endAt}\uf8ff`)
  //     .limitToFirst(20)
  //     .once('value');
  // }
  const databaseRef = ref(db, `/users/${uid}/patients`);
  const queryRef = dbQuery(
    databaseRef,
    orderByChild('birthday'),
    startAt(startAtToUse),
    endAt(`${endAtToUse}\uf8ff`),
  );
  return new Promise((resolve) => {
    onValue(queryRef, resolve, { onlyOnce: true });
  });
  // return db.ref(`/users/${uid}/patients`)
  //   .orderByChild('birthday')
  //   // .limitToFirst(append ? 21 : 20)
  //   .startAt(startAtToUse)
  //   .endAt(`${endAtToUse}\uf8ff`)
  //   // .limitToFirst(20)
  //   .once('value');
}

export function* getBirthdaysList() {
  yield takeEvery(actions.FETCH_BIRTHDAYS_LIST_REQUEST, function* () {
    try {
      yield put({ type: actions.FETCHING_BIRTHDAYS_LIST });
      const unified = yield select(getUnifiedTokenStore);
      const mainUser = yield select(getMainUserFromStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      const patients = yield call(
        fetchBirthdaysFromDB,
        // storeSearch,
        addressUid,
        unified,
        mainUser,
      );
      const patientsArr = [];
      patients.forEach((p) => {
        if (p.val().fullName) {
          patientsArr.push({
            ...p.val(),
            id: p.key,
          });
        }
      });
      yield put({
        type: actions.FETCH_BIRTHDAYS_LIST_SUCCESS,
        payload: patientsArr,
      });
    } catch (error) {
      console.warn(error);
      yield put({ type: actions.FETCH_BIRTHDAYS_LIST_ERROR });
    }
  });
}

function saveBirthdayMessageOnDB(birthdayMessage, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  updates[`users/${uid}/birthdayMessage`] = birthdayMessage;
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* saveBirthdayMessage() {
  yield takeEvery(actions.SAVE_BIRTHDAY_MESSAGE_REQUEST, function* (action) {
    try {
      yield put({
        type: actions.START_SAVING_BIRTHDAY_MESSAGE,
      });
      yield call(sleep, 500);
      const mainUser = yield select(getMainUserFromStore);
      yield call(saveBirthdayMessageOnDB, action.payload?.useDefault ? defaultBirthdayMessage : action.payload, mainUser);
      yield all([
        yield put({
          type: actions.SAVE_BIRTHDAY_MESSAGE_SUCCESS,
        }),
        yield put({
          type: actions.FETCH_BIRTHDAY_MESSAGE_REQUEST,
        }),
      ]);
    } catch (error) {
      console.warn(error);
      yield put({ type: actions.SAVE_BIRTHDAY_MESSAGE_ERROR });
    }
  });
}

function getBirthdayMessageFromDB(mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  return get(child(dbRef, `users/${uid}/birthdayMessage`));
}

export function* getBirthdayMessage() {
  yield takeEvery(actions.FETCH_BIRTHDAY_MESSAGE_REQUEST, function* () {
    try {
      const mainUser = yield select(getMainUserFromStore);
      const birthdayMessage = yield call(getBirthdayMessageFromDB, mainUser);
      if (!birthdayMessage.val()) {
        yield call(saveBirthdayMessageOnDB, defaultBirthdayMessage, mainUser);
        yield put({
          type: actions.FETCH_BIRTHDAY_MESSAGE_REQUEST,
        });
      }
      yield put({
        type: actions.FETCH_BIRTHDAY_MESSAGE_SUCCESS,
        payload: birthdayMessage.val(),
      });
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.FETCH_BIRTHDAY_MESSAGE_ERROR,
      });
    }
  });
}

function persistErrorInfoOnDB(savedAllChanges, idArray, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  const updates = {};
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  updates[`errorLog/${uid}/savedAllChanges`] = JSON.stringify(savedAllChanges);
  updates[`errorLog/${uid}/idArray`] = JSON.stringify(idArray);
  return update(dbRef, updates);
}

export function* fetchUnsavedChangesPatientsListRequest() {
  yield takeEvery(actions.FETCH_UNSAVED_CHANGES_PATIENTS_LIST_REQUEST, function* (action) {
    try {
      yield put({ type: actions.START_FETCHING_UNSAVED_CHANGES_PATIENTS_LIST });
      const unified = yield select(getUnifiedTokenStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      const mainUser = yield select(getMainUserFromStore);
      const idArray = [];
      Object.keys(action.payload).forEach((patientId) => {
        let hasUnsavedChanges = false;
        Object.keys(action.payload[patientId]).forEach((key) => {
          if (!action.payload[patientId][key]) {
            hasUnsavedChanges = true;
          }
        });
        if (hasUnsavedChanges) {
          idArray.push(patientId);
        }
      });
      const patientsListResponse = yield call(fetchPatientsPerIdFromDB, idArray, addressUid, unified, mainUser);
      const patientsListArr = [];
      let persistErrorLog = false;
      patientsListResponse.forEach((r, index) => {
        if (r.val()) {
          patientsListArr.push({
            ...r.val(),
            id: r.key,
            fullName: `${r.val().firstName} ${r.val().lastName}`,
            active: true,
          });
        } else {
          console.warn(`Patient id (${idArray[index]}) could not be downloaded`);
          persistErrorLog = true;
        }
      });
      if (persistErrorLog) {
        yield call(persistErrorInfoOnDB, action.payload, idArray, mainUser);
      }
      yield put({
        type: actions.FETCH_UNSAVED_CHANGES_PATIENTS_LIST_SUCCESS,
        payload: {
          list: patientsListArr,
        },
      });
    } catch (error) {
      console.warn(error);
    }
  });
}

function fetchRequestingDoctorListFromDB(addressUid, unified = {}, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    return get(child(dbRef, `/unified/${unified.id}/requestingDoctorList`));
    // return db.ref(`/unified/${unified.id}/requestingDoctorList`)
    //   .once('value');
  }
  return get(child(dbRef, `/users/${uid}/requestingDoctorList`));
  // return db.ref(`/users/${uid}/requestingDoctorList`)
  //   .once('value');
}

function* fetchRequestingDoctorList() {
  yield takeLatest(actions.FETCH_REQUESTING_DOCTOR_LIST_REQUEST, function* () {
    try {
      const unified = yield select(getUnifiedTokenStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      const mainUser = yield select(getMainUserFromStore);
      const requestingDoctorList = yield call(fetchRequestingDoctorListFromDB, addressUid, unified, mainUser);
      let requestingDoctorListArr = [];
      if (requestingDoctorList.val()) {
        requestingDoctorListArr = _.map(requestingDoctorList.val(), (val, id) => (
          { ...val, id }
        ));
      }
      yield put({
        type: actions.FETCH_REQUESTING_DOCTOR_LIST_SUCCESS,
        payload: requestingDoctorListArr,
      });
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.FETCH_REQUESTING_DOCTOR_LIST_ERROR,
      });
    }
  });
}

function removeReferralHistoryOnDB(referral, seectedId, addressUid, unified = {}, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  const monthEntrance = moment(referral.timestamp).format('YYYY-MM');
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    updates[`unified/${uid}/patients/${seectedId}/referralHistory/${referral.key}`] = null;
    updates[`unified/${uid}/referralList/${referral.referralId}`] = {
      counter: increment(-1),
      monthly: {
        [monthEntrance]: {
          counter: increment(-1),
        },
      },
    };
  } else {
    updates[`users/${uid}/patients/${seectedId}/referralHistory/${referral.key}`] = null;
    updates[`users/${uid}/referralList/${referral.referralId}/counter`] = increment(-1);
    updates[`users/${uid}/referralListMonthly/${monthEntrance}/${referral.referralId}/counter`] = increment(-1);
    if (referral.referralProfessional) {
      updates[`users/${uid}/referralList/${referral.referralId}/professional/${referral.referralProfessional}/counter`] = increment(-1);
      updates[`users/${uid}/referralListMonthly/${monthEntrance}/${referral.referralId}/professional/${referral.referralProfessional}/counter`] = increment(-1);
    }
  }
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* removeReferralHistory() {
  yield takeEvery(actions.REMOVE_REFERRAL_HISTORY_REQUEST, function* (action) {
    try {
      yield put({
        type: actions.START_REMOVING_REFERRAL_HISTORY,
        payload: action.payload.key,
      });
      yield call(sleep, 500);
      const unified = yield select(getUnifiedTokenStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      const seectedId = yield select(getSeectedIdFromStore);
      const mainUser = yield select(getMainUserFromStore);
      const storePatients = yield select(getPatientsFromStore);
      const storePatientsOnDB = yield select(getPatientsOnDBFromStore);
      yield call(
        removeReferralHistoryOnDB,
        action.payload,
        seectedId,
        addressUid,
        unified,
        mainUser,
      );
      message.success('Indicação removida com sucesso');
      const newContacts = [];
      storePatients.forEach((contact) => {
        if (contact.id === seectedId) {
          const newContact = _.cloneDeep(contact);
          if (newContact.referralHistory?.[action.payload.key]) {
            delete newContact.referralHistory[action.payload.key];
          }
          newContacts.push(newContact);
        } else {
          newContacts.push(contact);
        }
      });
      const newContactsOnDB = [];
      storePatientsOnDB.forEach((contact) => {
        if (contact.id === seectedId) {
          const newContact = _.cloneDeep(contact);
          if (newContact.referralHistory?.[action.payload.key]) {
            delete newContact.referralHistory[action.payload.key];
          }
          newContactsOnDB.push(newContact);
        } else {
          newContactsOnDB.push(contact);
        }
      });
      yield all([
        yield put({
          type: actions.REMOVE_REFERRAL_HISTORY_SUCCESS,
        }),
        yield put({
          type: actions.SYNC_PATIENTS_AND_PATIENTS_ON_DB,
          payload: {
            patients: newContacts.sort(ascendingSort),
            patientsOnDB: newContactsOnDB.sort(ascendingSort),
          },
        }),
      ]);
    } catch (error) {
      console.warn(error);
      notification('error', 'Não foi possível remover a indicação', 'Tente novamente mais tarde.');
      yield put({ type: actions.REMOVE_REFERRAL_HISTORY_ERROR });
    }
  });
}

function removeRequestingDoctorHistoryOnDB(requestingDoctor, seectedId, addressUid, unified = {}, mainUser) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  const monthEntrance = moment(requestingDoctor.timestamp).format('YYYY-MM');
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    updates[`unified/${uid}/patients/${seectedId}/requestingDoctorHistory/${requestingDoctor.key}`] = null;
    updates[`unified/${uid}/requestingDoctorList/${requestingDoctor.requestingDoctorId}`] = {
      counter: increment(-1),
      monthly: {
        [monthEntrance]: {
          counter: increment(-1),
        },
      },
    };
  } else {
    updates[`users/${uid}/patients/${seectedId}/requestingDoctorHistory/${requestingDoctor.key}`] = null;
    updates[`users/${uid}/requestingDoctorList/${requestingDoctor.requestingDoctorId}/counter`] = increment(-1);
    updates[`users/${uid}/requestingDoctorListMonthly/${monthEntrance}/${requestingDoctor.requestingDoctorId}/counter`] = increment(-1);
    if (requestingDoctor.requestingDoctorProfessional) {
      updates[`users/${uid}/requestingDoctorList/${requestingDoctor.requestingDoctorId}/professional/${requestingDoctor.requestingDoctorProfessional}/counter`] = increment(-1);
      updates[`users/${uid}/requestingDoctorListMonthly/${monthEntrance}/${requestingDoctor.requestingDoctorId}/professional/${requestingDoctor.requestingDoctorProfessional}/counter`] = increment(-1);
    }
  }
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* removeRequestingDoctorHistory() {
  yield takeEvery(actions.REMOVE_REQUESTING_DOCTOR_HISTORY_REQUEST, function* (action) {
    try {
      yield put({
        type: actions.START_REMOVING_REQUESTING_DOCTOR_HISTORY,
        payload: action.payload.key,
      });
      yield call(sleep, 500);
      const unified = yield select(getUnifiedTokenStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      const seectedId = yield select(getSeectedIdFromStore);
      const mainUser = yield select(getMainUserFromStore);
      const storePatients = yield select(getPatientsFromStore);
      const storePatientsOnDB = yield select(getPatientsOnDBFromStore);
      yield call(
        removeRequestingDoctorHistoryOnDB,
        action.payload,
        seectedId,
        addressUid,
        unified,
        mainUser,
      );
      message.success('Médico solicitante removido com sucesso');
      const newContacts = [];
      storePatients.forEach((contact) => {
        if (contact.id === seectedId) {
          const newContact = _.cloneDeep(contact);
          if (newContact.requestingDoctorHistory?.[action.payload.key]) {
            delete newContact.requestingDoctorHistory[action.payload.key];
          }
          newContacts.push(newContact);
        } else {
          newContacts.push(contact);
        }
      });
      const newContactsOnDB = [];
      storePatientsOnDB.forEach((contact) => {
        if (contact.id === seectedId) {
          const newContact = _.cloneDeep(contact);
          if (newContact.requestingDoctorHistory?.[action.payload.key]) {
            delete newContact.requestingDoctorHistory[action.payload.key];
          }
          newContactsOnDB.push(newContact);
        } else {
          newContactsOnDB.push(contact);
        }
      });
      yield all([
        yield put({
          type: actions.REMOVE_REQUESTING_DOCTOR_HISTORY_SUCCESS,
        }),
        yield put({
          type: actions.SYNC_PATIENTS_AND_PATIENTS_ON_DB,
          payload: {
            patients: newContacts.sort(ascendingSort),
            patientsOnDB: newContactsOnDB.sort(ascendingSort),
          },
        }),
      ]);
    } catch (error) {
      console.warn(error);
      notification('error', 'Não foi possível remover a indicação', 'Tente novamente mais tarde.');
      yield put({ type: actions.REMOVE_REQUESTING_DOCTOR_HISTORY_ERROR });
    }
  });
}

// function getPatientsLogsFromDB(seectedId, addressUid, unified = {}, mainUser) {
//   const db = getDatabase();
//   let uid;
//   if (mainUser) {
//     uid = mainUser;
//   } else {
//     const auth = getAuth();
//     const { currentUser } = auth;
//     ({ uid } = currentUser);
//   }
//   if (unified.id && unified.address.some((a) => a === addressUid)) {
//     return new Promise((resolve) => {
//       onValue(ref(db, `unified/${unified.id}/patientsLogs/${seectedId}`), resolve, { onlyOnce: true });
//     });
//   }
//   return new Promise((resolve) => {
//     onValue(ref(db, `users/${uid}/patientsLogs/${seectedId}`), resolve, { onlyOnce: true });
//   });
// }

// export function* getPatientsLogs() {
//   yield takeEvery(actions.GET_PATIENTS_LOGS_REQUEST, function* () {
//     try {
//       const unified = yield select(getUnifiedTokenStore);
//       const addressUid = yield select(getSelectedAddressFromStore);
//       const mainUser = yield select(getMainUserFromStore);
//       const seectedId = yield select(getSeectedIdFromStore);
//       const records = yield call(getPatientsLogsFromDB, seectedId, addressUid, unified, mainUser);
//       console.log(records.val());
//       yield put({
//         type: actions.GET_PATIENTS_LOGS_SUCCESS,
//         // payload: {
//         //   graphs: {
//         //     pediatric: records.val(),
//         //   },
//         // },
//       });
//     } catch (error) {
//       console.warn(error);
//     }
//   });
// }

function fetchSpouseCpfFromDB(
  cpf,
  addressUid,
  unified = {},
  mainUser,
) {
  const db = getDatabase();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    const databaseRef = ref(db, `/unified/${unified.id}/patients`);
    const queryRef = dbQuery(
      databaseRef,
      orderByChild('cpf'),
      equalTo(cpf),
    );
    return new Promise((resolve) => {
      onValue(queryRef, resolve, { onlyOnce: true });
    });
  }
  const databaseRef = ref(db, `/users/${uid}/patients`);
  const queryRef = dbQuery(
    databaseRef,
    orderByChild('cpf'),
    equalTo(cpf),
  );
  return new Promise((resolve) => {
    onValue(queryRef, resolve, { onlyOnce: true });
  });
}

export function* getPatientSpouseRequest() {
  yield takeEvery(actions.SEARCH_SPOUSE_PROFILE_REQUEST, function* (action) {
    try {
      const unified = yield select(getUnifiedTokenStore);
      const mainUser = yield select(getMainUserFromStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      const seectedId = yield select(getSeectedIdFromStore);
      const patients = yield call(
        fetchSpouseCpfFromDB,
        action.payload,
        addressUid,
        unified,
        mainUser,
      );
      const patientsArr = [];
      patients.forEach((p) => {
        if (p.val().fullName) {
          patientsArr.push({
            ...p.val(),
            id: p.key,
            active: true,
          });
        }
      });
      yield put({
        type: actions.SEARCH_SPOUSE_PROFILE_SUCCESS,
        payload: {
          patientsArr,
          id: seectedId,
        },
      });
    } catch (error) {
      console.warn(error);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(addContactRequest),
    // fork(editContact),
    fork(deleteContact),
    fork(savePatient),
    fork(updateAppointmentsUserInfo),
    fork(fetchReferralList),
    fork(fetchPatientDataRequest),
    fork(searchActivePatientsRequest),
    fork(searchDeletedPatientsRequest),
    fork(searchZipCodeRequest),
    fork(getPatientHistory),
    fork(mergePatientRequest),
    fork(getFormSettingsRequest),
    fork(saveFormSettings),
    fork(getPatientFinancialHistory),
    fork(checkForDuplicatePatientRequest),
    fork(getWhatsappAvatar),
    fork(getBirthdaysList),
    fork(saveBirthdayMessage),
    fork(getBirthdayMessage),
    fork(fetchUnsavedChangesPatientsListRequest),
    fork(fetchRequestingDoctorList),
    fork(removeReferralHistory),
    fork(removeRequestingDoctorHistory),
    fork(recoverContact),
    fork(getPatientSpouseRequest),
  ]);
}
