import _ from 'lodash';
import React from 'react';
import {
  getAuth,
} from 'firebase/auth';
import {
  getFirestore,
  doc,
  setDoc,
  addDoc,
  getDocs,
  collection,
  onSnapshot,
  updateDoc,
  deleteDoc,
} from 'firebase/firestore';
import { eventChannel, channel } from 'redux-saga';
import {
  all,
  takeLatest,
  put,
  call,
  fork,
  select,
  takeEvery,
  take,
} from 'redux-saga/effects';
import axios from 'axios';
import moment from 'moment-timezone';
import { v1 as uuidv1 } from 'uuid';
import { Modal } from 'antd';
import actions from './actions';
import appActions from '../app/actions';
import { notification } from '../../components';

const ROOT_URL = process.env.REACT_APP_CLOUD_FUNCTIONS_ROOT_URL;

let peerConnection = null;
let dataChannel = null;
let localStream = null;
let remoteStream = null;

const configuration = {
  iceServers: [
    {
      urls: [
        'stun:stun1.l.google.com:19302',
        'stun:stun2.l.google.com:19302',
      ],
    },
  ],
  iceCandidatePoolSize: 10,
};

function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

const getMainUserFromStore = (state) => state.Auth.mainUser;

const getTelemedMainUserFromStore = (state) => state.Telemed.telemedMainUser;

const getUserTypeFromStore = (state) => state.Telemed.userType;

const getSessionUuidFromStore = (state) => state.Telemed.sessionUuid;

const getOwnMicMutedFromStore = (state) => state.Telemed.ownMicMuted;

const getUserMediaWebcamDisabledFromStore = (state) => state.Telemed.userMediaWebcamDisabled;

const getUserPatientMediaWebcamDisabledFromStore = (state) => state.Telemed.userPatientMediaWebcamDisabled;

const getNoVideoDevicesFromStore = (state) => state.Telemed.noVideoDevices;

const getNoAudioDevicesFromStore = (state) => state.Telemed.noAudioDevices;

const getDataChannelOpenFromStore = (state) => state.Telemed.dataChannelOpen;

const getCreateRoomAttemptsFromStore = (state) => state.Telemed.createRoomAttempts;

const getTelemedChatMessagesFromStore = (state) => state.Telemed.telemedChatMessages;

const getOtherPeerEndedCallFromStore = (state) => state.Telemed.otherPeerEndedCall;

const getHandshakeFromStore = (state) => state.Telemed.handshake;

const getRemotePeerHandshakeReceivedFromStore = (state) => state.Telemed.remotePeerHandshakeReceived;

const dataChannelMessagesChannel = channel();

function* watchDataChannelMessagesChannel() {
  while (true) {
    const action = yield take(dataChannelMessagesChannel);
    yield put(action);
  }
}

function validatePatientCpfOnCloudFunction({ cpf, queryConfirmedObj }) {
  return axios.post(
    `${ROOT_URL}/validatePatientCpf`,
    { cpf, queryConfirmedObj },
  );
}

export function* validatedPatientCpfRequest() {
  yield takeLatest(actions.VALIDATE_PATIENT_CPF_REQUEST, function* (action) {
    try {
      yield put({ type: actions.VALIDATING_PATIENT_CPF });
      yield call(validatePatientCpfOnCloudFunction, { ...action.payload });
      yield put({ type: actions.VALIDATE_PATIENT_CPF_SUCCESS });
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.VALIDATE_PATIENT_CPF_ERROR,
      });
    }
  });
}

function enumerateDevices() {
  return navigator.mediaDevices.enumerateDevices();
}

function getUserMedia(video = true, audio = true) {
  return navigator.mediaDevices.getUserMedia({
    video,
    audio,
  });
}

export function* openUserMedia() {
  yield takeLatest([
    actions.OPEN_USER_MEDIA_REQUEST,
    actions.OPEN_PATIENT_USER_MEDIA_REQUEST,
  ], function* (action) {
    try {
      if (action.type === 'OPEN_USER_MEDIA_REQUEST') {
        yield put({ type: actions.LOADING_OWN_WEBCAM });
      } else if (action.type === 'OPEN_PATIENT_USER_MEDIA_REQUEST') {
        yield put({ type: actions.LOADING_PATIENT_OWN_WEBCAM });
      }
      const availableDevices = yield call(enumerateDevices);
      const cams = availableDevices.filter((device) => device.kind === 'videoinput');
      const mics = availableDevices.filter((device) => device.kind === 'audioinput');
      const constraints = { video: cams.length > 0, audio: mics.length > 0 };
      if (cams.length === 0 && mics.length === 0) {
        // No video and audio device available
        throw new Error('No available media devices');
      }
      if (cams.length === 0) {
        // No cam
        yield put({ type: actions.NO_VIDEO_DEVICES });
      }
      if (mics.length === 0) {
        // No mic
        yield put({ type: actions.NO_AUDIO_DEVICES });
      }
      if (!localStream) {
        const stream = yield call(getUserMedia, constraints.video, constraints.audio);
        document.querySelector('#localVideo').srcObject = stream;
        if (action.type === 'OPEN_USER_MEDIA_REQUEST') {
          yield put({ type: actions.OPEN_USER_MEDIA_WEBCAM_SUCCESS });
        } else if (action.type === 'OPEN_PATIENT_USER_MEDIA_REQUEST') {
          yield put({ type: actions.OPEN_PATIENT_USER_MEDIA_WEBCAM_SUCCESS });
        }
        localStream = stream;
      }
      if (!remoteStream) {
        remoteStream = new MediaStream();
        document.querySelector('#remoteVideo').srcObject = remoteStream;
      }
    } catch (error) {
      console.warn(error);
      if (error?.message === 'No available media devices') {
        notification('error', 'Não foi identificado nenhum dispositivo de áudio ou vídeo');
        yield put({
          type: actions.NO_MEDIA_DEVICES,
        });
      } else if (error?.message === 'Permission denied') {
        Modal.info({
          title: 'O Meagenda não tem permissão para usar sua câmera e seu microfone',
          content: (
            <div>
              <img
                src="https://www.gstatic.com/meet/permissions_flow_meet_blocked_chrome_page_info_ltr_80ad378597e2a7cc8f65d34f0e0e7b9c.svg"
                alt="Ilustração do navegador mostrando onde corrigir as permissões bloqueadas."
                style={{
                  maxWidth: '100%',
                }}
              />
              <ul
                style={{
                  marginBottom: 0,
                  listStyle: 'decimal',
                }}
              >
                <li>
                  Acesse as informações da página no canto superior esquerdo, ao lado do endereço
                  <span>
                    <i style={{ fontWeight: 500 }}>&nbsp;meagenda.com.br&nbsp;</i>
                  </span>
                  na barra de endereço do seu navegador
                </li>
                <li>
                  Ative o microfone e a câmera
                </li>
              </ul>
            </div>
          ),
          icon: null,
          // footer: null,
          // closable: true,
        });
      } else {
        notification('error', 'Não foi possível acessar sua câmera e microfone', 'Tente novamente.');
      }
      yield put({
        type: actions.OPEN_USER_MEDIA_WEBCAM_ERROR,
      });
    }
  });
}

function createRoomListener(roomId, mainUser) {
  const fs = getFirestore();
  const roomRef = doc(
    fs,
    'telemed',
    mainUser,
    'rooms',
    roomId,
  );
  const listener = eventChannel((emit) => {
    const unsubscribe = onSnapshot(roomRef, (req) => {
      emit(req || null);
    });
    return unsubscribe;
  });
  return listener;
}

function createPeerConnectionGeneralListener(mode) {
  const listener = eventChannel((emit) => {
    peerConnection.addEventListener(mode, (event) => {
      emit(event);
    });
    return () => peerConnection.removeEventListener(mode, emit);
  });
  return listener;
}

export function* createConnectionStateChange() {
  yield takeLatest(actions.REGISTER_PEER_CONNECTION_LISTENERS, function* (action) {
    try {
      const { roomId } = action.payload;
      const peerConnectionListener = yield call(createPeerConnectionGeneralListener, 'connectionstatechange');
      yield takeEvery(peerConnectionListener, function* () {
        if (peerConnection.connectionState === 'disconnected' || peerConnection.connectionState === 'failed') {
          yield put({
            type: actions.END_TELEMED_ROOM_REQUEST,
            payload: { roomId, retry: true },
          });
        }
        if (peerConnection.connectionState === 'connected') {
          yield put({
            type: actions.PEER_CONNECTION_CONNECTED,
          });
        }
      });
      yield take([
        appActions.CANCEL_LISTENERS,
        actions.END_TELEMED_ROOM_REQUEST,
      ]);
      peerConnectionListener.close();
    } catch (error) {
      console.warn(error);
    }
  });
}

export function* createDataChannel() {
  yield takeLatest(actions.REGISTER_PEER_CONNECTION_LISTENERS, function* () {
    try {
      const peerConnectionListener = yield call(createPeerConnectionGeneralListener, 'datachannel');
      yield takeEvery(peerConnectionListener, function* (event) {
        dataChannel = event.channel;
        yield put({
          type: actions.CREATE_DATA_CHANNEL_SUCCESS,
        });
      });
      yield take([
        appActions.CANCEL_LISTENERS,
        actions.END_TELEMED_ROOM_REQUEST,
      ]);
      peerConnectionListener.close();
    } catch (error) {
      console.warn(error);
    }
  });
}

function createDataChannelGeneralListener(mode) {
  const listener = eventChannel((emit) => {
    dataChannel.addEventListener(mode, (event) => {
      emit(event);
    });
    return () => dataChannel.removeEventListener(mode, emit);
  });
  return listener;
}

export function* createDataChannelOpenStateChange() {
  yield takeLatest(actions.REGISTER_PEER_CONNECTION_LISTENERS, function* () {
    try {
      const dataChannelListener = yield call(createDataChannelGeneralListener, 'open');
      yield takeEvery(dataChannelListener, function* () {
        yield put({
          type: actions.DATA_CHANNEL_STATE_IS_OPEN,
        });
        const ownMicMuted = yield select(getOwnMicMutedFromStore);
        const userType = yield select(getUserTypeFromStore);
        const noVideoDevices = yield select(getNoVideoDevicesFromStore);
        const noAudioDevices = yield select(getNoAudioDevicesFromStore);
        const webcamOff = userType === 'patient'
          ? yield select(getUserPatientMediaWebcamDisabledFromStore)
          : yield select(getUserMediaWebcamDisabledFromStore);
        if (dataChannel && dataChannel.readyState === 'open') {
          // Send handshake before sending initial states
          let handshake = yield select(getHandshakeFromStore);
          let remotePeerHandshakeReceived = yield select(getRemotePeerHandshakeReceivedFromStore);
          try {
            while (!handshake || !remotePeerHandshakeReceived) {
              dataChannel.send(JSON.stringify({ type: 'handshake' }));
              yield call(sleep, 500);
              handshake = yield select(getHandshakeFromStore);
              remotePeerHandshakeReceived = yield select(getRemotePeerHandshakeReceivedFromStore);
            }
            if (ownMicMuted) {
              dataChannel.send(JSON.stringify({ type: 'micMuted' }));
            } else {
              dataChannel.send(JSON.stringify({ type: 'micUnmuted' }));
            }
            if (webcamOff) {
              dataChannel.send(JSON.stringify({ type: 'camOff' }));
            } else {
              dataChannel.send(JSON.stringify({ type: 'camOn' }));
            }
            if (noVideoDevices) {
              dataChannel.send(JSON.stringify({ type: 'camOff' }));
            }
            if (noAudioDevices) {
              dataChannel.send(JSON.stringify({ type: 'micMuted' }));
            }
          } catch (e) {
            console.warn(e);
          }
        }
      });
      yield take([
        appActions.CANCEL_LISTENERS,
        actions.END_TELEMED_ROOM_REQUEST,
      ]);
      dataChannelListener.close();
    } catch (error) {
      console.warn(error);
    }
  });
}

export function* createDataChannelCloseStateChange() {
  yield takeLatest(actions.REGISTER_PEER_CONNECTION_LISTENERS, function* () {
    try {
      const dataChannelListener = yield call(createDataChannelGeneralListener, 'close');
      yield takeEvery(dataChannelListener, function* () {
        yield put({
          type: actions.DATA_CHANNEL_STATE_IS_CLOSE,
        });
      });
      yield take([
        appActions.CANCEL_LISTENERS,
        actions.END_TELEMED_ROOM_REQUEST,
      ]);
      dataChannelListener.close();
    } catch (error) {
      console.warn(error);
    }
  });
}

export function* createDataChannelMessageListener() {
  yield takeLatest(actions.REGISTER_PEER_CONNECTION_LISTENERS, function* () {
    try {
      const dataChannelListener = yield call(createDataChannelGeneralListener, 'message');
      yield takeEvery(dataChannelListener, function* (event) {
        const message = JSON.parse(event.data);
        if (message?.type === 'handshake') {
          yield put({
            type: actions.HANDSHAKE_RECEIVED,
          });
          dataChannel.send(JSON.stringify({ type: 'remotePeerHandshakeReceived' }));
        }
        if (message?.type === 'remotePeerHandshakeReceived') {
          yield put({
            type: actions.REMOTE_PEER_HANDSHAKE_RECEIVED,
          });
        }
        if (message?.type === 'micMuted') {
          yield put({
            type: actions.REMOTE_MIC_MUTED,
          });
        }
        if (message?.type === 'micUnmuted') {
          yield put({
            type: actions.REMOTE_MIC_UNMUTED,
          });
        }
        if (message?.type === 'camOff') {
          yield put({
            type: actions.REMOTE_CAM_OFF,
          });
        }
        if (message?.type === 'camOn') {
          yield put({
            type: actions.REMOTE_CAM_ON,
          });
        }
        if (message?.type === 'chatMessage') {
          yield put({
            type: actions.RECEIVED_CHAT_MESSAGE,
            payload: { ...message.data },
          });
        }
        if (message?.type === 'endCall') {
          yield put({
            type: actions.OTHER_PEER_ENDED_CALL,
          });
        }
      });
      const actionTriggered = yield take([
        appActions.CANCEL_LISTENERS,
        actions.END_TELEMED_ROOM_REQUEST,
      ]);
      if (actionTriggered?.type === 'END_TELEMED_ROOM_REQUEST' && !actionTriggered.payload?.retry) {
        const dataChannelOpen = yield select(getDataChannelOpenFromStore);
        if (dataChannel && dataChannelOpen) {
          dataChannel.send(JSON.stringify({ type: 'endCall' }));
        }
      }
      dataChannelListener.close();
    } catch (error) {
      console.warn(error);
    }
  });
}

function createOffer() {
  const offerOptions = {
    offerToReceiveAudio: true,
    offerToReceiveVideo: true, // Force video receiving
  };
  return peerConnection.createOffer(offerOptions);
}

function setLocalDescription(offer) {
  return peerConnection.setLocalDescription(offer);
}

function createRoomWithOfferOnFirestore(roomId, mainUser, offer, sessionUuid) {
  const fs = getFirestore();
  const roomRef = doc(
    fs,
    'telemed',
    mainUser,
    'rooms',
    roomId,
  );
  const roomWithOffer = {
    offer: {
      type: offer.type,
      sdp: offer.sdp,
    },
    sessionUuid,
  };
  return setDoc(roomRef, roomWithOffer);
}

export function* createRoom() {
  yield takeLatest(actions.CREATE_TELEMED_ROOM_REQUEST, function* (action) {
    try {
      const mainUser = yield select(getMainUserFromStore);
      let uid;
      if (mainUser) {
        uid = mainUser;
      } else {
        const auth = getAuth();
        const { currentUser } = auth;
        ({ uid } = currentUser);
      }
      yield put({
        type: actions.SET_TELEMED_MAIN_USER,
        payload: uid,
      });
      const { roomId } = action.payload;
      const createRoomAttempts = yield select(getCreateRoomAttemptsFromStore);
      if (createRoomAttempts >= 3) {
        yield put({
          type: actions.END_TELEMED_ROOM_REQUEST,
          payload: { roomId },
        });
      } else {
        yield put({
          type: actions.CREATING_TELEMED_ROOM,
          payload: {
            // counter: newCreateRoomAttempts,
            counter: createRoomAttempts + 1,
          },
        });
        const roomListener = yield call(createRoomListener, roomId, uid);
        const sessionUuid = uuidv1();
        yield takeEvery(roomListener, function* (snapshot) {
          const currentSessionUuid = yield select(getSessionUuidFromStore);
          if (!snapshot.exists()) {
            if (currentSessionUuid) {
              // Need to clean and start again
              yield put({
                type: actions.END_TELEMED_ROOM_REQUEST,
                payload: { roomId, retry: true },
              });
            } else {
              // No room on DB and states are reset
              peerConnection = new RTCPeerConnection(configuration);
              dataChannel = peerConnection.createDataChannel(roomId);
              yield put({
                type: actions.REGISTER_PEER_CONNECTION_LISTENERS,
                payload: { roomId },
              });
              localStream.getTracks().forEach((track) => {
                peerConnection.addTrack(track, localStream);
              });
              yield put({
                type: actions.CREATE_PEER_CONNECTION_SUCCESS,
                payload: {
                  roomId,
                },
              });
              // Code for creating a room
              const offer = yield call(createOffer);
              yield call(setLocalDescription, offer);
              yield call(createRoomWithOfferOnFirestore, roomId, uid, offer, sessionUuid);
              yield put({
                type: actions.SET_SESSION_UUID,
                payload: {
                  sessionUuid,
                },
              });
              notification(
                'success',
                'Sala criada com sucesso',
                'Aguardando o paciente se conectar.',
              );
              // Code for creating a room above
            }
          } else if (snapshot.exists()) {
            const { sessionUuid: sessionUuidFirestore } = snapshot.data();
            if (sessionUuidFirestore === currentSessionUuid || sessionUuidFirestore === sessionUuid) {
              // It is the current room, no action required
            } else if (!currentSessionUuid) {
              yield put({
                type: actions.END_TELEMED_ROOM_REQUEST,
                payload: { roomId, retry: true },
              });
            }
          }
        });
        yield take([
          appActions.CANCEL_LISTENERS,
          actions.END_TELEMED_ROOM_REQUEST,
        ]);
        roomListener.close();
      }
    } catch (error) {
      console.warn(error);
      notification('error', 'Algo deu errado ao tentar criar uma sala', 'Tente novamente mais tarde.');
    }
  });
}

function createPeerConnectionCandidateListener(mode) {
  // Code for collecting ICE candidates
  const listener = eventChannel((emit) => {
    peerConnection.addEventListener(mode, (event) => {
      if (!event.candidate) {
        return;
      }
      emit(event.candidate.toJSON());
    });
    return () => peerConnection.removeEventListener(mode, emit);
  });
  return listener;
}

function addCallerCandidateOnFirestore(roomId, mainUser, eventCandidate) {
  const fs = getFirestore();
  const callerCandidatesCollection = collection(
    fs,
    'telemed',
    mainUser,
    'rooms',
    roomId,
    'callerCandidates',
  );
  return addDoc(callerCandidatesCollection, eventCandidate);
}

export function* getCallerEventCandidates() {
  yield takeLatest(actions.CREATE_PEER_CONNECTION_SUCCESS, function* (action) {
    try {
      const { roomId } = action.payload;
      const telemedMainUser = yield select(getTelemedMainUserFromStore);
      const peerConnectionCandidateListener = yield call(createPeerConnectionCandidateListener, 'icecandidate');
      yield takeEvery(peerConnectionCandidateListener, function* (eventCandidate) {
        // Getting ice candidates from eventListener and saving to firestore
        yield call(addCallerCandidateOnFirestore, roomId, telemedMainUser, eventCandidate);
      });
      yield take([
        appActions.CANCEL_LISTENERS,
        actions.END_TELEMED_ROOM_REQUEST,
      ]);
      peerConnectionCandidateListener.close();
    } catch (error) {
      console.warn(error);
      notification('error', 'Algo deu errado ao tentar se comunicar com o outro usuário', 'Tente novamente mais tarde.');
      yield put({
        type: actions.GET_EVENT_CANDIDATES_ERROR,
      });
    }
  });
}

function createPeerConnectionTrackListener() {
  const listener = eventChannel((emit) => {
    peerConnection.addEventListener('track', (event) => {
      event.streams[0].getTracks().forEach((track) => {
        remoteStream.addTrack(track);
      });
    });
    return () => peerConnection.removeEventListener('track', emit);
  });
  return listener;
}

export function* getRemoteTrack() {
  yield takeLatest([
    actions.CREATE_PEER_CONNECTION_SUCCESS,
    actions.JOINING_TELEMED_CREATE_PEER_CONNECTION_SUCCESS,
  ], function* () {
    try {
      const peerConnectionTrackListener = yield call(createPeerConnectionTrackListener);
      yield take([
        appActions.CANCEL_LISTENERS,
        actions.END_TELEMED_ROOM_REQUEST,
      ]);
      peerConnectionTrackListener.close();
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.GET_REMOTE_TRACK_ERROR,
      });
    }
  });
}

function createSessionDescriptionListener(roomId, mainUser) {
  const fs = getFirestore();
  const roomRef = doc(
    fs,
    'telemed',
    mainUser,
    'rooms',
    roomId,
  );
  const listener = eventChannel((emit) => {
    const unsubscribe = onSnapshot(roomRef, (req) => {
      emit(req || {});
    });
    return unsubscribe;
  });
  return listener;
}

function setRemoteDescription(rtcSessionDescription) {
  return peerConnection.setRemoteDescription(rtcSessionDescription);
}

export function* getSessionDescription() {
  yield takeLatest(actions.CREATE_PEER_CONNECTION_SUCCESS, function* (action) {
    try {
      const { roomId } = action.payload;
      const telemedMainUser = yield select(getTelemedMainUserFromStore);
      const sessionDescriptionListener = yield call(createSessionDescriptionListener, roomId, telemedMainUser);
      yield takeEvery(sessionDescriptionListener, function* (snapshot) {
        const data = snapshot.data();
        if (!peerConnection?.currentRemoteDescription && data && data.answer) {
          const rtcSessionDescription = new RTCSessionDescription(data.answer);
          yield call(setRemoteDescription, rtcSessionDescription);
        }
      });
      yield take([
        appActions.CANCEL_LISTENERS,
        actions.END_TELEMED_ROOM_REQUEST,
      ]);
      sessionDescriptionListener.close();
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.GET_SESSION_DESCRIPTION_ERROR,
      });
    }
  });
}

function createIceCalleeCandidatesListener(roomId, mainUser) {
  const fs = getFirestore();
  const calleeCandidatesCollection = collection(
    fs,
    'telemed',
    mainUser,
    'rooms',
    roomId,
    'calleeCandidates',
  );
  const listener = eventChannel((emit) => {
    const unsubscribe = onSnapshot(calleeCandidatesCollection, (req) => {
      emit(req || {});
    });
    return unsubscribe;
  });
  return listener;
}

function addIceCandidate(data) {
  return peerConnection.addIceCandidate(new RTCIceCandidate(data));
}

export function* getCalleeRemoteCandidates() {
  yield takeLatest(actions.CREATE_PEER_CONNECTION_SUCCESS, function* (action) {
    try {
      const { roomId } = action.payload;
      const telemedMainUser = yield select(getTelemedMainUserFromStore);
      const iceCandidatesListener = yield call(createIceCalleeCandidatesListener, roomId, telemedMainUser);
      yield takeEvery(iceCandidatesListener, function* (snapshot) {
        const iceCandidatesArr = [];
        snapshot.docChanges().forEach((change) => {
          if (change.type === 'added') {
            const data = change.doc.data();
            iceCandidatesArr.push(data);
          }
        });
        try {
          yield all(iceCandidatesArr.map((data) => call(addIceCandidate, data)));
        } catch (e) {
          console.warn(e);
        }
      });
      yield take([
        appActions.CANCEL_LISTENERS,
        actions.END_TELEMED_ROOM_REQUEST,
      ]);
      iceCandidatesListener.close();
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.GET_REMOTE_ICE_CANDIDATES_ERROR,
      });
    }
  });
}

function createAnswer() {
  return peerConnection.createAnswer();
}

function updateRoomWithAnswerOnFirestore(roomId, mainUser, answer) {
  const fs = getFirestore();
  const roomRef = doc(
    fs,
    'telemed',
    mainUser,
    'rooms',
    roomId,
  );
  const roomWithAnswer = {
    answer: {
      type: answer.type,
      sdp: answer.sdp,
    },
  };
  return updateDoc(roomRef, roomWithAnswer);
}

export function* joinRoomById() {
  yield takeLatest(actions.JOIN_TELEMED_ROOM_REQUEST, function* (action) {
    try {
      yield put({ type: actions.JOINING_TELEMED_ROOM });
      const { roomId, mainUser } = action.payload;
      yield put({
        type: actions.SET_TELEMED_MAIN_USER,
        payload: mainUser,
      });
      const roomListener = yield call(createRoomListener, roomId, mainUser);
      yield takeEvery(roomListener, function* (snapshot) {
        if (snapshot.exists()) {
          peerConnection = new RTCPeerConnection(configuration);
          dataChannel = peerConnection.createDataChannel(roomId);
          yield put({
            type: actions.REGISTER_PEER_CONNECTION_LISTENERS,
            payload: { roomId },
          });
          localStream.getTracks().forEach((track) => {
            peerConnection.addTrack(track, localStream);
          });
          yield put({
            type: actions.JOINING_TELEMED_CREATE_PEER_CONNECTION_SUCCESS,
            payload: {
              roomId,
              mainUser,
            },
          });

          // Code for creating SDP answer below
          const { offer, sessionUuid } = snapshot.data();
          const rtcSessionDescription = new RTCSessionDescription(offer);
          yield call(setRemoteDescription, rtcSessionDescription);
          const answer = yield call(createAnswer);
          yield call(setLocalDescription, answer);
          yield call(updateRoomWithAnswerOnFirestore, roomId, mainUser, answer);
          yield put({
            type: actions.JOIN_TELEMED_ROOM_SUCCESS,
            payload: {
              roomId,
              mainUser,
              sessionUuid,
            },
          });
        }
      });
      yield take([
        appActions.CANCEL_LISTENERS,
        actions.END_TELEMED_ROOM_REQUEST,
        actions.JOINING_TELEMED_CREATE_PEER_CONNECTION_SUCCESS,
      ]);
      roomListener.close();
    } catch (error) {
      console.warn(error);
      notification('error', 'Algo deu errado ao tentar entrar na sala', 'Tente novamente mais tarde.');
    }
  });
}

export function* checkRoomSessionUuid() {
  yield takeLatest(actions.JOIN_TELEMED_ROOM_SUCCESS, function* (action) {
    try {
      yield put({ type: actions.JOINING_TELEMED_ROOM });
      const { roomId, mainUser, sessionUuid: currentSessionUuid } = action.payload;
      const roomListener = yield call(createRoomListener, roomId, mainUser);
      yield takeEvery(roomListener, function* (snapshot) {
        if (snapshot.exists()) {
          // Code for creating SDP answer below
          const { sessionUuid } = snapshot.data();
          if ((currentSessionUuid && currentSessionUuid !== sessionUuid) || !sessionUuid) {
            // The caller already have a different 'sessionUuid', need to retry.
            yield put({
              type: actions.END_TELEMED_ROOM_REQUEST,
              payload: { roomId, retry: true },
            });
          }
        } else {
          yield put({
            type: actions.END_TELEMED_ROOM_REQUEST,
            payload: { roomId, retry: true },
          });
        }
      });
      yield take([
        appActions.CANCEL_LISTENERS,
        actions.END_TELEMED_ROOM_REQUEST,
      ]);
      roomListener.close();
    } catch (error) {
      console.warn(error);
      notification('error', 'Algo deu errado ao tentar entrar na sala', 'Tente novamente mais tarde.');
    }
  });
}

function addCalleeCandidateOnFirestore(roomId, mainUser, eventCandidate) {
  const fs = getFirestore();
  const calleeCandidatesCollection = collection(
    fs,
    'telemed',
    mainUser,
    'rooms',
    roomId,
    'calleeCandidates',
  );
  return addDoc(calleeCandidatesCollection, eventCandidate);
}

export function* getCalleeEventCandidates() {
  yield takeLatest(actions.JOINING_TELEMED_CREATE_PEER_CONNECTION_SUCCESS, function* (action) {
    try {
      const { roomId } = action.payload;
      const telemedMainUser = yield select(getTelemedMainUserFromStore);
      const peerConnectionCandidateListener = yield call(createPeerConnectionCandidateListener, 'icecandidate');
      yield takeEvery(peerConnectionCandidateListener, function* (eventCandidate) {
        // Getting ice candidates from eventListener and saving to firestore
        yield call(addCalleeCandidateOnFirestore, roomId, telemedMainUser, eventCandidate);
      });
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.GET_EVENT_CANDIDATES_ERROR,
      });
    }
  });
}

function createIceCallerCandidatesListener(roomId, mainUser) {
  const fs = getFirestore();
  const callerCandidatesCollection = collection(
    fs,
    'telemed',
    mainUser,
    'rooms',
    roomId,
    'callerCandidates',
  );
  const listener = eventChannel((emit) => {
    const unsubscribe = onSnapshot(callerCandidatesCollection, (req) => {
      emit(req || {});
    });
    return unsubscribe;
  });
  return listener;
}

export function* getCallerRemoteCandidates() {
  yield takeLatest(actions.JOINING_TELEMED_CREATE_PEER_CONNECTION_SUCCESS, function* (action) {
    try {
      const { roomId } = action.payload;
      const telemedMainUser = yield select(getTelemedMainUserFromStore);
      const iceCandidatesListener = yield call(createIceCallerCandidatesListener, roomId, telemedMainUser);
      yield takeEvery(iceCandidatesListener, function* (snapshot) {
        const iceCandidatesArr = [];
        snapshot.docChanges().forEach((change) => {
          if (change.type === 'added') {
            const data = change.doc.data();
            iceCandidatesArr.push(data);
          }
        });
        yield all(iceCandidatesArr.map((data) => call(addIceCandidate, data)));
      });
      yield take([
        appActions.CANCEL_LISTENERS,
        actions.END_TELEMED_ROOM_REQUEST,
      ]);
      iceCandidatesListener.close();
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.GET_REMOTE_ICE_CANDIDATES_ERROR,
      });
    }
  });
}

export function* stopOwnWebcam() {
  yield takeLatest(actions.STOP_OWN_WEBCAM_REQUEST, function* () {
    try {
      const videoTracks = document.querySelector('#localVideo').srcObject.getVideoTracks();
      videoTracks.forEach((track) => {
        if (track?.enabled) {
          // eslint-disable-next-line no-param-reassign
          track.enabled = false;
        }
      });
      const userType = yield select(getUserTypeFromStore);
      const dataChannelOpen = yield select(getDataChannelOpenFromStore);
      if (dataChannel && dataChannelOpen) {
        dataChannel.send(JSON.stringify({ type: 'camOff' }));
      }
      if (userType === 'patient') {
        yield put({
          type: actions.STOP_OWN_PATIENT_WEBCAM_SUCCESS,
        });
      } else {
        yield put({
          type: actions.STOP_OWN_WEBCAM_SUCCESS,
        });
      }
    } catch (error) {
      console.warn(error);
    }
  });
}

export function* startOwnWebcam() {
  yield takeLatest(actions.START_OWN_WEBCAM_REQUEST, function* () {
    try {
      const videoTracks = document.querySelector('#localVideo').srcObject.getVideoTracks();
      videoTracks.forEach((track) => {
        if (!track?.enabled) {
          // eslint-disable-next-line no-param-reassign
          track.enabled = true;
        }
      });
      const userType = yield select(getUserTypeFromStore);
      const dataChannelOpen = yield select(getDataChannelOpenFromStore);
      if (dataChannel && dataChannelOpen) {
        dataChannel.send(JSON.stringify({ type: 'camOn' }));
      }
      if (userType === 'patient') {
        yield put({
          type: actions.START_OWN_PATIENT_WEBCAM_SUCCESS,
        });
      } else {
        yield put({
          type: actions.START_OWN_WEBCAM_SUCCESS,
        });
      }
    } catch (error) {
      console.warn(error);
    }
  });
}

export function* muteOwnMic() {
  yield takeLatest(actions.MUTE_OWN_MIC_REQUEST, function* () {
    try {
      const audioTracks = document.querySelector('#localVideo').srcObject.getAudioTracks();
      audioTracks.forEach((track) => {
        if (track?.enabled) {
          // eslint-disable-next-line no-param-reassign
          track.enabled = false;
        }
      });
      const dataChannelOpen = yield select(getDataChannelOpenFromStore);
      if (dataChannel && dataChannelOpen) {
        dataChannel.send(JSON.stringify({ type: 'micMuted' }));
      }
      yield put({
        type: actions.MUTE_OWN_MIC_SUCCESS,
      });
    } catch (error) {
      console.warn(error);
    }
  });
}

export function* unmuteOwnMic() {
  yield takeLatest(actions.UNMUTE_OWN_MIC_REQUEST, function* () {
    try {
      const audioTracks = document.querySelector('#localVideo').srcObject.getAudioTracks();
      audioTracks.forEach((track) => {
        if (!track?.enabled) {
          // eslint-disable-next-line no-param-reassign
          track.enabled = true;
        }
      });
      const dataChannelOpen = yield select(getDataChannelOpenFromStore);
      if (dataChannel && dataChannelOpen) {
        dataChannel.send(JSON.stringify({ type: 'micUnmuted' }));
      }
      yield put({
        type: actions.UNMUTE_OWN_MIC_SUCCESS,
      });
    } catch (error) {
      console.warn(error);
    }
  });
}

export function* sendChatMessage() {
  yield takeLatest(actions.SEND_CHAT_MESSAGE, function* (action) {
    try {
      const dataChannelOpen = yield select(getDataChannelOpenFromStore);
      if (dataChannel && dataChannelOpen) {
        const chatMessages = yield select(getTelemedChatMessagesFromStore);
        const data = {
          ...action.payload,
          id: uuidv1(),
          sendingNewMessage: true,
          timestamp: moment().tz('America/Sao_Paulo').format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        };
        yield put({
          type: actions.SAVE_CHAT_MESSAGES,
          payload: {
            messages: _.uniqBy([data, ...chatMessages], 'id'),
          },
        });
        dataChannel.send(JSON.stringify({
          type: 'chatMessage',
          data,
        }));
      }
    } catch (error) {
      console.warn(error);
    }
  });
}

export function* receivedChatMessage() {
  yield takeLatest(actions.RECEIVED_CHAT_MESSAGE, function* (action) {
    try {
      const chatMessages = yield select(getTelemedChatMessagesFromStore);
      const data = {
        ...action.payload,
        timestamp: moment().tz('America/Sao_Paulo').format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
      };
      if (data.sendingNewMessage) {
        delete data.sendingNewMessage;
        dataChannel.send(JSON.stringify({
          type: 'chatMessage',
          data,
        }));
      }
      yield put({
        type: actions.SAVE_CHAT_MESSAGES,
        payload: {
          messages: _.uniqBy([data, ...chatMessages], 'id'),
        },
      });
    } catch (error) {
      console.warn(error);
    }
  });
}

function getCalleeCandidatesFromFirestore(roomId, mainUser) {
  const fs = getFirestore();
  const calleeCandidatesCollection = collection(
    fs,
    'telemed',
    mainUser,
    'rooms',
    roomId,
    'calleeCandidates',
  );
  return getDocs(calleeCandidatesCollection);
}

function deleteCandidate(candidate) {
  return deleteDoc(candidate.ref);
}

function getCallerCandidatesFromFirestore(roomId, mainUser) {
  const fs = getFirestore();
  const callerCandidatesCollection = collection(
    fs,
    'telemed',
    mainUser,
    'rooms',
    roomId,
    'callerCandidates',
  );
  return getDocs(callerCandidatesCollection);
}

function deleteRoom(roomId, mainUser) {
  const fs = getFirestore();
  const roomRef = doc(
    fs,
    'telemed',
    mainUser,
    'rooms',
    roomId,
  );
  return deleteDoc(roomRef);
}

export function* hangUp() {
  yield takeLatest(actions.END_TELEMED_ROOM_REQUEST, function* (action) {
    try {
      const { roomId, retry } = action.payload;
      yield put({
        type: actions.ENDING_TELEMED_ROOM,
        payload: retry ? 'retry' : true,
      });
      let shouldRetry = retry;
      const otherPeerEndedCall = yield select(getOtherPeerEndedCallFromStore);
      if (otherPeerEndedCall) {
        shouldRetry = false;
      }
      if (!shouldRetry) {
        const tracks = document.querySelector('#localVideo').srcObject.getTracks();
        tracks.forEach((track) => {
          track.stop();
        });
        localStream = null;
      }
      if (remoteStream) {
        remoteStream.getTracks().forEach((track) => track.stop());
        if (shouldRetry) {
          remoteStream = new MediaStream();
          document.querySelector('#remoteVideo').srcObject = remoteStream;
        } else {
          remoteStream = null;
        }
      }
      if (peerConnection) {
        peerConnection.close();
        peerConnection = null;
      }
      if (dataChannel) {
        if ((dataChannel.readyState === 'open' || dataChannel.readyState === 'connecting') && dataChannel.close) {
          dataChannel.close();
        }
        dataChannel = null;
        yield put({
          type: actions.DATA_CHANNEL_STATE_IS_CLOSE,
        });
      }
      // Delete room on hangup
      if (roomId) {
        const telemedMainUser = yield select(getTelemedMainUserFromStore);
        const calleeCandidates = yield call(getCalleeCandidatesFromFirestore, roomId, telemedMainUser);
        const calleeCandidatesArr = [];
        calleeCandidates.forEach(async (candidate) => {
          calleeCandidatesArr.push(candidate);
        });
        yield all(calleeCandidatesArr.map((candidate) => call(deleteCandidate, candidate)));
        const callerCandidates = yield call(getCallerCandidatesFromFirestore, roomId, telemedMainUser);
        const callerCandidatesArr = [];
        callerCandidates.forEach(async (candidate) => {
          callerCandidatesArr.push(candidate);
        });
        yield all(callerCandidatesArr.map((candidate) => call(deleteCandidate, candidate)));
        yield call(deleteRoom, roomId, telemedMainUser);
        const userType = yield select(getUserTypeFromStore);
        if (shouldRetry) {
          if (userType === 'patient') {
            yield put({
              type: actions.JOIN_TELEMED_ROOM_REQUEST,
              payload: { roomId, mainUser: telemedMainUser },
            });
          } else {
            yield put({
              type: actions.CREATE_TELEMED_ROOM_REQUEST,
              payload: { roomId },
            });
          }
        } else {
          yield put({ type: actions.END_TELEMED_ROOM_SUCCESS });
        }
      }
    } catch (error) {
      console.warn(error);
      notification('error', 'Algo deu errado ao tentar encerrar a chamada', 'Tente novamente mais tarde.');
      yield put({
        type: actions.END_TELEMED_ROOM_ERROR,
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(validatedPatientCpfRequest),
    fork(openUserMedia),
    fork(createRoom),
    fork(createConnectionStateChange),
    fork(createDataChannel),
    fork(createDataChannelOpenStateChange),
    fork(createDataChannelCloseStateChange),
    fork(createDataChannelMessageListener),
    fork(getCallerEventCandidates),
    fork(getRemoteTrack),
    fork(getSessionDescription),
    fork(getCalleeRemoteCandidates),
    fork(joinRoomById),
    fork(checkRoomSessionUuid),
    fork(getCalleeEventCandidates),
    fork(getCallerRemoteCandidates),
    fork(stopOwnWebcam),
    fork(startOwnWebcam),
    fork(muteOwnMic),
    fork(unmuteOwnMic),
    fork(sendChatMessage),
    fork(receivedChatMessage),
    fork(hangUp),
    fork(watchDataChannelMessagesChannel),
  ]);
}
