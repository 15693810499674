import actions from './actions';
import authActions from '../auth/actions';

const initState = {
  exams: {},
  customExamItens: {},
  updatingExams: false,
  removingExams: false,
  examRequests: false,
  preventRequesterNotification: false,
  removecSuccess: undefined,
  examHistory: {},
  newExamVisible: false,
  requestingExame: false,
  printedOrDownloadedExam: false,
  lastExam: {},
  noNextExamPage: {},
  loadingUnimedExams: false,
  unimedExams: {},
  unimedExamsInterectedWithRequest: null,
  unimedDocuments: {},
  updatingCustomExamItens: false,
  updatingModel: false,
};

export default function rulesReducer(state = initState, action) {
  switch (action.type) {
    case authActions.CLEAR_STATES:
      return {
        ...initState,
      };
    case actions.RESET_EXAMS:
      return {
        ...state,
        exams: {},
        customExamItens: {},
      };
    case actions.EXAMS_FETCH_SUCCESS:
      return {
        ...state,
        exams: {
          ...state.exams,
          ...action.payload.exams,
        },
        customExamItens: {
          ...state.customExamItens,
          ...action.payload.customExamItens,
        },
        updatingExams: false,
        removingExams: false,
        updatingCustomExamItens: false,
      };
    case actions.EXAMS_FETCH_OR_UPDATING_WAITING:
      return {
        ...state,
        updatingExams: true,
        updatingCustomExamItens: true,
      };
    case actions.EXAMS_FETCH_ERROR:
      return {
        ...state,
        exams: {
          ...state.exams,
          [action.payload.address]: [],
        },
        customExamItens: {
          ...state.customExamItens,
          [action.payload.address]: [],
        },
        updatingExams: false,
        updatingCustomExamItens: false,
      };
    case actions.REMOVING_EXAMS:
      return {
        ...state,
        removingExams: true,
        removecSuccess: undefined,
      };
    case actions.REMOVE_EXAMS_SUCCESS:
      return {
        ...state,
        removingExams: false,
        removecSuccess: true,
      };
    case actions.REMOVE_EXAMS_ERROR:
      return {
        ...state,
        removingExams: false,
        removecSuccess: false,
      };
    case actions.EXAMS_REQUESTS_LISTENER_SUCCESS:
      return {
        ...state,
        examRequests: action.payload.examRequests,
      };
    case actions.CREATE_EXAMS_REQUEST:
      return {
        ...state,
        // preventRequesterNotification: true,
      };
    case actions.CREATE_EXAMS_SUCCESS:
      return {
        ...state,
        updatingExams: false,
      };
    case actions.CREATE_EXAMS_ERROR:
      return {
        ...state,
        // preventRequesterNotification: 'error',
      };
    case actions.ENABLE_NOTIFICATION:
      return {
        ...state,
        preventRequesterNotification: false,
      };
    case actions.DISABLE_NOTIFICATION:
      return {
        ...state,
        preventRequesterNotification: true,
      };
    case actions.FINALIZE_EXAMS_WAITING:
      return {
        ...state,
        waitingFinalize: true,
      };
    case actions.FINALIZE_EXAMS_SUCCESS:
      return {
        ...state,
        waitingFinalize: false,
        exameFinalizaError: false,
      };
    case actions.FINALIZE_EXAMS_ERROR:
      return {
        ...state,
        waitingFinalize: false,
        exameFinalizaError: true,
      };
    case actions.EXAMS_HISTORY_WAITING:
      return {
        ...state,
        waitingExamHistory: true,
        exameFinalizaError: false,
      };
    case actions.EXAMS_HISTORY_REQUEST:
      return {
        ...state,
        exameFinalizaError: false,
      };
    case actions.EXAMS_HISTORY_SUCCESS:
      return {
        ...state,
        waitingExamHistory: false,
        examHistory: {
          ...state.examHistory,
          [action.payload.id]: action.payload.examHistory,
        },
        lastExam: {
          ...state.lastExam,
          [action.payload.id]: action.payload.lastExam,
        },
        noNextExamPage: {
          ...state.noNextExamPage,
          [action.payload.id]: action.payload.noNextExamPage,
        },
        // patientId: action.payload.patientId,
      };
    // case actions.EXANS_HISTORY_CLEAR:
    //   return {
    //     ...state,
    //     examHistory: {},
    //     patientId: null,
    //   };
    case actions.EXAMS_HISTORY_ERROR:
      return {
        ...state,
        waitingExamHistory: false,
      };
    case actions.TOGGLE_NEW_EXAM_MODAL:
      return {
        ...state,
        newExamVisible: action.payload,
      };
    case actions.REQUEST_EXAMS_WAITING:
      return {
        ...state,
        requestingExame: action.payload,
        preventRequesterNotification: true,
      };
    case actions.REQUEST_EXAMS_SUCCESS:
      return {
        ...state,
        requestingExame: false,
        // preventRequesterNotification: true,
      };
    case actions.REQUEST_EXAMS_SUCCESS_SECREATARY:
      return {
        ...state,
        requestingExame: false,
      };
    case actions.REQUEST_EXAMS_ERROR:
      return {
        ...state,
        requestingExame: null,
      };
    case actions.USER_PRINTED_OR_DOWNLOADED_EXAM:
      return {
        ...state,
        printedOrDownloadedExam: action.payload,
      };
    case actions.REQUEST_UNIMED_EXAMS_RESULTS_LOADING:
      return {
        ...state,
        unimedExamsMissingCpf: false,
        loadingUnimedExams: true,
        unimedExams: {
          ...state.unimedExams,
          [action.payload.id]: {
            unimedExamsResults: null,
            error: '',
          },
        },
      };
    case actions.REQUEST_UNIMED_EXAMS_RESULTS_SUCCESS:
      return {
        ...state,
        loadingUnimedExams: false,
        unimedAnexoError: false,
        unimedExams: {
          ...state.unimedExams,
          ...action.payload,
        },
      };
    case actions.DOCUMENT_UNIMED_REQUEST_WAITING:
      return {
        ...state,
        unimedAnexoError: false,
        unimedDocuments: {
          ...state.unimedDocuments,
          ...action.payload,
        },
      };
    case actions.DOCUMENT_UNIMED_REQUEST_SUCCESS:
      return {
        ...state,
        unimedAnexoError: false,
        unimedDocuments: {
          ...state.unimedDocuments,
          ...action.payload,
        },
      };
    case actions.DOCUMENT_UNIMED_BUTTON_PRESSED:
      return {
        ...state,
        unimedResultButtonPressed: !state.unimedResultButtonPressed,
      };
    case actions.DOCUMENT_UNIMED_REQUEST_ERROR:
      return {
        ...state,
        unimedAnexoError: true,
      };
    case actions.UNIMED_EXAMS_MISSING_CPF:
      return {
        ...state,
        unimedExamsMissingCpf: true,
        loadingUnimedExams: false,
      };
    case actions.CREATE_CUSTOM_EXAM_ITENS:
      return {
        ...state,
        updatingCustomExamItens: true,
      };
    case actions.CREATE_CUSTOM_EXAM_ITENS_SUCCESS:
      return {
        ...state,
        updatingCustomExamItens: false,
      };
    case actions.CREATE_CUSTOM_EXAM_ITENS_ERROR:
      return {
        ...state,
        updatingCustomExamItens: false,
      };
    case actions.DUPLICATE_MODEL_WAITING:
      return {
        ...state,
        updatingModel: true,
      };
    case actions.DUPLICATE_MODEL_SUCCESS:
      return {
        ...state,
        updatingModel: false,
      };
    case actions.DUPLICATE_MODEL_ERROR:
      return {
        ...state,
        updatingModel: false,
      };
    default:
      return state;
  }
}
