import React, {
  useState,
  useEffect,
  useRef,
} from 'react';
import { connect } from 'react-redux';
import Icon, {
  VideoCameraOutlined,
  AudioOutlined,
  UserOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
  MinusOutlined,
} from '@ant-design/icons';
import {
  Button,
  Modal,
  Badge,
  Popover,
  Typography,
} from 'antd';
import Draggable from 'react-draggable';
import TelemedVideos from '../telemedVideos';
import TelemedChat from '../telemedChat';
import telemedActions from '../../redux/telemed/actions';
import './style.css';

const { Text } = Typography;

function WhatsAppSvg() {
  return (
    <svg viewBox="0 0 512 512" width="1em" height="1em" fill="currentColor">
      {/* eslint-disable-next-line max-len */}
      <path d="m435.921875 74.351562c-48.097656-47.917968-112.082031-74.3242182-180.179687-74.351562-67.945313 0-132.03125 26.382812-180.445313 74.289062-48.5 47.988282-75.234375 111.761719-75.296875 179.339844v.078125.046875c.0078125 40.902344 10.753906 82.164063 31.152344 119.828125l-30.453125 138.417969 140.011719-31.847656c35.460937 17.871094 75.027343 27.292968 114.933593 27.308594h.101563c67.933594 0 132.019531-26.386719 180.441406-74.296876 48.542969-48.027343 75.289062-111.71875 75.320312-179.339843.019532-67.144531-26.820312-130.882813-75.585937-179.472657zm-180.179687 393.148438h-.089844c-35.832032-.015625-71.335938-9.011719-102.667969-26.023438l-6.621094-3.59375-93.101562 21.175782 20.222656-91.90625-3.898437-6.722656c-19.382813-33.425782-29.625-70.324219-29.625-106.71875.074218-117.800782 96.863281-213.75 215.773437-213.75 57.445313.023437 111.421875 22.292968 151.984375 62.699218 41.175781 41.03125 63.84375 94.710938 63.824219 151.152344-.046875 117.828125-96.855469 213.6875-215.800781 213.6875zm0 0" />
      {/* eslint-disable-next-line max-len */}
      <path d="m186.152344 141.863281h-11.210938c-3.902344 0-10.238281 1.460938-15.597656 7.292969-5.363281 5.835938-20.476562 19.941406-20.476562 48.628906s20.964843 56.40625 23.886718 60.300782c2.925782 3.890624 40.46875 64.640624 99.929688 88.011718 49.417968 19.421875 59.476562 15.558594 70.199218 14.585938 10.726563-.96875 34.613282-14.101563 39.488282-27.714844s4.875-25.285156 3.414062-27.722656c-1.464844-2.429688-5.367187-3.886719-11.214844-6.800782-5.851562-2.917968-34.523437-17.261718-39.886718-19.210937-5.363282-1.941406-9.261719-2.914063-13.164063 2.925781-3.902343 5.828125-15.390625 19.3125-18.804687 23.203125-3.410156 3.894531-6.824219 4.382813-12.675782 1.464844-5.851562-2.925781-24.5-9.191406-46.847656-29.050781-17.394531-15.457032-29.464844-35.167969-32.878906-41.003906-3.410156-5.832032-.363281-8.988282 2.570312-11.898438 2.628907-2.609375 6.179688-6.179688 9.105469-9.582031 2.921875-3.40625 3.753907-5.835938 5.707031-9.726563 1.949219-3.890625.972657-7.296875-.488281-10.210937-1.464843-2.917969-12.691406-31.75-17.894531-43.28125h.003906c-4.382812-9.710938-8.996094-10.039063-13.164062-10.210938zm0 0" />
    </svg>
  );
}

function renderDraggable(modal, disabled, bounds, onStart, draggleRef) {
  return (
    <Draggable
      disabled={disabled}
      bounds={bounds}
      nodeRef={draggleRef}
      onStart={(event, uiData) => onStart(event, uiData)}
    >
      <div ref={draggleRef}>{modal}</div>
    </Draggable>
  );
}

function TelemedModal({
  openTelemedModal,
  setOpenTelemedModal,
  openUserMediaWebcam,
  userMediaAvailable,
  userMediaWebcamDisabled,
  createTelemedRoom,
  roomId,
  // joinedTelemedRoom,
  peerConnectionConnected,
  dataChannelOpen,
  ownMicMuted,
  localStreamAvailable,
  telemedAppointmentData,
  userMediaError,
  noVideoDevices,
  noAudioDevices,
}) {
  const [width, setWidth] = useState(0);
  const [modalSize, setModalSize] = useState('normal');
  const [showChat, setShowChat] = useState(false);
  const [initiatedCall, setInitiatedCall] = useState(false);
  const [minimized, setMinimized] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const draggleRef = useRef(null);

  useEffect(() => {
    updateWindowDimensions();
    window.addEventListener('resize', updateWindowDimensions);

    return () => {
      window.removeEventListener('resize', updateWindowDimensions);
    };
  }, []);

  const updateWindowDimensions = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    if (openTelemedModal) {
      openUserMediaWebcam();
    }
  }, [openTelemedModal, openUserMediaWebcam]);

  useEffect(() => {
    if (!openTelemedModal) {
      setInitiatedCall(false);
      setMinimized(false);
    }
  }, [openTelemedModal]);

  useEffect(() => {
    if (peerConnectionConnected && dataChannelOpen) {
      setInitiatedCall(true);
    }
  }, [peerConnectionConnected, dataChannelOpen]);

  useEffect(() => {
    if (openTelemedModal && !initiatedCall && localStreamAvailable) {
      createTelemedRoom(roomId);
    }
  }, [
    initiatedCall,
    openTelemedModal,
    createTelemedRoom,
    roomId,
    localStreamAvailable,
  ]);

  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  const renderTopRightButtons = () => {
    const elements = [];
    elements.push(
      <Button
        key="minus"
        className="custom-close-button minus"
        type="text"
        icon={<MinusOutlined />}
        onClick={() => setMinimized(true)}
      />,
    );
    if (width > 600) {
      if (modalSize === 'normal') {
        elements.push(
          <Button
            key="normal"
            className="custom-close-button"
            type="text"
            icon={<FullscreenExitOutlined />}
            onClick={() => setModalSize('small')}
          />,
        );
      } else {
        elements.push(
          <Button
            key="small"
            className="custom-close-button"
            type="text"
            icon={<FullscreenOutlined />}
            onClick={() => setModalSize('normal')}
          />,
        );
      }
    }
    return (
      <div className="custom-close-button-wrapper">
        {elements}
      </div>
    );
  };

  const renderPopoverTitle = () => {
    if (peerConnectionConnected && dataChannelOpen) {
      return (
        <div>
          <Badge status="success" style={{ marginRight: '5px' }} />
          <span>Chamada iniciada</span>
        </div>
      );
    }
    if (minimized && roomId) {
      return (
        <div>
          <Badge status="processing" style={{ marginRight: '5px' }} />
          <span>Aguardando conexão...</span>
        </div>
      );
    }
    return null;
  };

  const renderPopoverContent = () => {
    const elements = [];
    if (userMediaAvailable && !userMediaError) {
      if (!noVideoDevices && !userMediaWebcamDisabled) {
        elements.push(
          <div key="cam" style={{ display: 'flex' }}>
            <VideoCameraOutlined />
            <p style={{ marginBottom: 0, marginLeft: '5px' }}>
              Câmera ligada
            </p>
          </div>,
        );
      }
      if (!noAudioDevices && !ownMicMuted) {
        elements.push(
          <div key="mic" style={{ display: 'flex' }}>
            <AudioOutlined />
            <p style={{ marginBottom: 0, marginLeft: '5px' }}>
              Microfone ativado
            </p>
          </div>,
        );
      }
    }
    return elements;
  };

  const renderButtonIcons = () => {
    const elements = [];
    if (peerConnectionConnected && dataChannelOpen) {
      elements.push(
        <Badge key="success" status="success" />,
      );
    } else if (minimized && roomId) {
      elements.push(
        <Badge
          key="waiting"
          status="processing"
          // color="#333"
        />,
      );
    }
    if (userMediaAvailable && !userMediaError) {
      if (!noVideoDevices && !userMediaWebcamDisabled) {
        elements.push(
          <VideoCameraOutlined key="cam" />,
        );
      }
      if (!noAudioDevices && !ownMicMuted) {
        elements.push(
          <AudioOutlined key="mic" />,
        );
      }
    }
    return elements;
  };

  const renderHeaderInfo = () => {
    const elements = [];
    elements.push(<UserOutlined key="user-icon" style={{ color: 'gray', marginRight: 10, fontSize: 20 }} />);
    if (telemedAppointmentData?.userInfo?.firstName) {
      if (telemedAppointmentData?.userInfo?.lastName) {
        elements.push(
          <Text key="name" style={{ fontSize: 22 }} strong>
            {`${telemedAppointmentData?.userInfo?.firstName} ${telemedAppointmentData?.userInfo?.lastName}`}
          </Text>,
        );
      } else {
        elements.push(<Text key="name" style={{ fontSize: 22 }} strong>{telemedAppointmentData?.userInfo?.firstName}</Text>);
      }
    }
    if (telemedAppointmentData?.userInfo?.whatsapp) {
      elements.push(
        <span key="answer-whatsapp">
          <Button
            type="primary"
            className="telemedModal-whatsapp-button"
            onClick={() => { window.open(`https://wa.me/${telemedAppointmentData.userInfo.whatsapp.includes('+') ? '' : '55'}${telemedAppointmentData.userInfo.whatsapp}`, '_blank'); }}
          >
            <Icon
              component={WhatsAppSvg}
              style={{
                fontSize: '14px',
                color: 'white',
              }}
            />
          </Button>
        </span>,
      );
    }
    return elements;
  };

  return (
    <div className="telemed-button-container">
      {/* {!openTelemedModal && roomId ? ( */}
      {minimized && roomId ? (
        <Popover content={renderPopoverContent()} title={renderPopoverTitle()}>
          <Button
            onClick={() => setMinimized(false)}
            size="large"
            // style={{ backgroundColor: '#1890ff' }}
            style={{
              transform: 'scale(1)',
              animation: 'pulse 2s infinite',
            }}
          >
            {renderButtonIcons()}
            Teleconsulta
          </Button>
        </Popover>
      ) : null}
      <Modal
        // className="telemed-modal"
        wrapClassName={`telemed-modal-wrapper ${modalSize}`}
        title={(
          <div
            style={{
              width: '100%',
              cursor: 'move',
            }}
            onMouseOver={() => {
              if (disabled) {
                setDisabled(false);
              }
            }}
            onMouseOut={() => {
              setDisabled(true);
            }}
            // fix eslintjsx-a11y/mouse-events-have-key-events
            // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/mouse-events-have-key-events.md
            onFocus={() => {}}
            onBlur={() => {}}
            // end
          >
            {renderHeaderInfo()}
          </div>
        )}
        centered
        forceRender
        open={openTelemedModal && !minimized}
        onCancel={() => setOpenTelemedModal(false, roomId || null)}
        footer={null}
        mask={width <= 600}
        maskClosable={false}
        zIndex={1750}
        width={modalSize === 'normal' ? 'calc(100vw - 45px)' : 420}
        closable={false}
        // closable={renderTopRightButtons}
        // closeIcon={renderTopRightButtons()}
        styles={{
          body: {
            paddingBottom: width <= 937 || modalSize === 'small' ? '32px' : 0,
            // height: '420px',
            // height: 'auto',
            height: modalSize === 'normal' ? '690px' : '360px',
          },
        }}
        modalRender={(modal) => {
          if (width <= 600) {
            return modal;
          }
          return renderDraggable(modal, disabled, bounds, onStart, draggleRef);
        }}
      >
        {renderTopRightButtons()}
        <div className="telemed-modal-container">
          <TelemedVideos
            userMediaAvailable={userMediaAvailable}
            userMediaWebcamDisabled={userMediaWebcamDisabled}
            professional
            modalSize={modalSize}
            // showChatIcon={width <= 937 || modalSize === 'small'}
            showChatIcon
            showChat={showChat}
            setShowChat={setShowChat}
            smallScreenMode={width <= 937}
            telemedAppointmentData={telemedAppointmentData}
            accessUserMedia={openUserMediaWebcam}
            isModal
            isModalOpen={openTelemedModal}
            initiatedCall={initiatedCall}
          />
          {/* {peerConnectionConnected && dataChannelOpen ? (
          ) : null} */}
          {showChat && (
            <div className={`telemedModal-side-pannel ${width <= 937 || modalSize === 'small' ? 'smallScreenMode' : ''}`}>
              <TelemedChat
                userMediaAvailable={userMediaAvailable}
                disabled={!peerConnectionConnected || !dataChannelOpen}
                hideHeader
                smallScreenMode={width <= 937 || modalSize === 'small'}
                showChat={showChat}
                telemedAppointmentData={telemedAppointmentData}
                initiatedCall={initiatedCall}
              />
            </div>
          )}
          {/* <Button
            onClick={() => endTelemedRoom(roomId)}
          >
            Encerrar
          </Button> */}
        </div>
      </Modal>
    </div>
  );
}

export default connect((state) => ({
  sessionUuid: state.Telemed.sessionUuid,
  openTelemedModal: state.Telemed.openTelemedModal,
  loadingOwnWebcam: state.Telemed.loadingOwnWebcam,
  userMediaAvailable: state.Telemed.userMediaAvailable,
  userMediaWebcamDisabled: state.Telemed.userMediaWebcamDisabled,
  localStreamAvailable: state.Telemed.localStreamAvailable,
  roomId: state.Telemed.roomId,
  joinedTelemedRoom: state.Telemed.joinedTelemedRoom,
  peerConnectionConnected: state.Telemed.peerConnectionConnected,
  dataChannelOpen: state.Telemed.dataChannelOpen,
  ownMicMuted: state.Telemed.ownMicMuted,
  remoteMicMuted: state.Telemed.remoteMicMuted,
  remoteCamOff: state.Telemed.remoteCamOff,
  telemedAppointmentData: state.Telemed.telemedAppointmentData,
  userMediaError: state.Telemed.userMediaError,
  noMediaDevices: state.Telemed.noMediaDevices,
  noVideoDevices: state.Telemed.noVideoDevices,
  noAudioDevices: state.Telemed.noAudioDevices,
}), {
  ...telemedActions,
})(TelemedModal);
