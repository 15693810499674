import _ from 'lodash';
import moment from 'moment-timezone';
import axios from 'axios';
import {
  getAuth,
} from 'firebase/auth';
import {
  getDatabase,
  ref,
  onValue,
  update,
  push,
  child,
} from 'firebase/database';
import {
  getFirestore,
  doc,
  getDocFromServer,
} from 'firebase/firestore';
import { eventChannel } from 'redux-saga';
import {
  all,
  takeLatest,
  fork,
  call,
  put,
  takeEvery,
  select,
  take,
} from 'redux-saga/effects';
import { notification } from '../../components';
import authActions from '../auth/actions';
import appActions from '../app/actions';
import profileActions from '../profile/actions';
import customUsersActions from '../customUsers/actions';
import actions from './actions';

function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

const defaultOnCreateAppointmentMessage = {
  title: '✅ Agendamento realizado',
  active: true,
  isDefault: true,
  value: {
    ops: [
      {
        insert: 'Olá *',
      },
      {
        insert: {
          mention: {
            denotationChar: '#',
            id: 'patientName',
            value: 'nome_do_paciente',
          },
        },
      },
      {
        insert: '*!\nSeu horário com o(a) Profissional *',
      },
      {
        insert: {
          mention: {
            denotationChar: '#',
            id: 'professionalName',
            value: 'nome_profissional',
          },
        },
      },
      {
        insert: '* foi agendado pela clínica para o dia *',
      },
      {
        insert: {
          mention: {
            denotationChar: '#',
            id: 'appointmentDate',
            value: 'data_atendimento',
          },
        },
      },
      {
        insert: ', no endereço ',
      },
      {
        insert: {
          mention: {
            denotationChar: '#',
            id: 'address',
            value: 'local_agendamento',
          },
        },
      },
      {
        insert: '*.\n\nSe os dados do seu agendamento estiverem certos, mande um OK.\n\n```meagenda® Sistema Clínico```\n',
      },
    ],
  },
};

const defaultConfirmationLinkMessage = {
  title: '✅ Confirmar presença',
  active: true,
  isDefault: true,
  value: {
    ops: [
      {
        insert: 'Olá *',
      },
      {
        insert: {
          mention: {
            denotationChar: '#',
            id: 'patientName',
            value: 'nome_do_paciente',
          },
        },
      },
      {
        insert: '*!\nEstou passando aqui para informar que seu horário com o(a) Profissional *',
      },
      {
        insert: {
          mention: {
            denotationChar: '#',
            id: 'professionalName',
            value: 'nome_profissional',
          },
        },
      },
      {
        insert: ' é dia ',
      },
      {
        insert: {
          mention: {
            denotationChar: '#',
            id: 'appointmentDate',
            value: 'data_atendimento',
          },
        },
      },
      {
        insert: '*. Confirme ou cancele entrando no site abaixo.\n\n',
      },
      {
        insert: {
          mention: {
            denotationChar: '#',
            id: 'queryConfirmed',
            value: 'link_confirmação',
          },
        },
      },
      {
        insert: '\n\nCaso não consiga clicar no link, me adicione aos contatos ou mande qualquer mensagem no chat.\n\n```meagenda® Sistema Clínico```\n',
      },
    ],
  },
};

const defaultCancelation = {
  title: '🚨 Cancelar',
  active: true,
  isDefault: true,
  value: {
    ops: [
      {
        insert: 'Olá *',
      },
      {
        insert: {
          mention: {
            denotationChar: '#',
            id: 'patientName',
            value: 'nome_do_paciente',
          },
        },
      },
      {
        insert: '*, seu horário com o(a) Profissional ',
      },
      {
        insert: {
          mention: {
            denotationChar: '#',
            id: 'professionalName',
            value: 'nome_profissional',
          },
        },
      },
      {
        insert: ' para o dia ',
      },
      {
        insert: {
          mention: {
            denotationChar: '#',
            id: 'appointmentDate',
            value: 'data_atendimento',
          },
        },
      },
      {
        insert: ' , foi *CANCELADO* pela clínica.\n\nSe não quiser mais receber mensagens, mande SAIR\n\n```meagenda® Sistema Clínico```\n',
      },
    ],
  },
};

const defaultTimeChange = {
  title: '⚠️ Mudança de horário',
  active: true,
  isDefault: true,
  value: {
    ops: [
      {
        insert: 'Olá *',
      },
      {
        insert: {
          mention: {
            denotationChar: '#',
            id: 'patientName',
            value: 'nome_do_paciente',
          },
        },
      },
      {
        insert: '*, seu horário com o(a) Profissional ',
      },
      {
        insert: {
          mention: {
            denotationChar: '#',
            id: 'professionalName',
            value: 'nome_profissional',
          },
        },
      },
      {
        insert: ' foi *REAGENDADO* pela clínica para o dia *',
      },
      {
        insert: {
          mention: {
            denotationChar: '#',
            id: 'appointmentDate',
            value: 'data_atendimento',
          },
        },
      },
      {
        insert: '* .\n\nSe não quiser mais receber mensagens, mande SAIR.\n\n```meagenda® Sistema Clínico```\n',
      },
    ],
  },
};

const defaultAcceptedOnlineAppointment = {
  title: '✅ Agendamento online confirmado',
  active: true,
  isDefault: true,
  value: {
    ops: [
      {
        insert: 'Olá *',
      },
      {
        insert: {
          mention: {
            denotationChar: '#',
            id: 'patientName',
            value: 'nome_do_paciente',
          },
        },
      },
      {
        insert: '* !\nSeu agendamento online com o(a) Profissional *',
      },
      {
        insert: {
          mention: {
            denotationChar: '#',
            id: 'professionalName',
            value: 'nome_profissional',
          },
        },
      },
      {
        insert: '* foi *CONFIRMADO* pela clínica para o dia ',
      },
      {
        insert: {
          mention: {
            denotationChar: '#',
            id: 'appointmentDate',
            value: 'data_atendimento',
          },
        },
      },
      {
        insert: ' , no endereço *',
      },
      {
        insert: {
          mention: {
            denotationChar: '#',
            id: 'address',
            value: 'local_agendamento',
          },
        },
      },
      {
        insert: '*.\n\nSe os dados do seu agendamento estiverem certos, mande um OK.\n\n```meagenda® Sistema Clínico```\n',
      },
    ],
  },
};

const defaultOnlineCanceled = {
  title: '🚨 Cancelar',
  active: true,
  isDefault: true,
  value: {
    ops: [
      {
        insert: 'Olá *',
      },
      {
        insert: {
          mention: {
            denotationChar: '#',
            id: 'patientName',
            value: 'nome_do_paciente',
          },
        },
      },
      {
        insert: '* !\nSeu agendamento online com o(a) Profissional *',
      },
      {
        insert: {
          mention: {
            denotationChar: '#',
            id: 'professionalName',
            value: 'nome_profissional',
          },
        },
      },
      {
        insert: '* foi *CANCELADO* pela clínica para o dia ',
      },
      {
        insert: {
          mention: {
            denotationChar: '#',
            id: 'appointmentDate',
            value: 'data_atendimento',
          },
        },
      },
      {
        insert: ' , no endereço',
      },
      {
        insert: {
          mention: {
            denotationChar: '#',
            id: 'address',
            value: 'local_agendamento',
          },
        },
      },
      {
        insert: '.\n\nSe os dados do seu agendamento estiverem certos, mande um OK.\n\n```meagenda® Sistema Clínico```\n',
      },
    ],
  },
};

const defaultTelemedOnCreateAppointmentMessage = {
  title: '✅ Agendamento realizado',
  active: true,
  isDefault: true,
  value: {
    ops: [
      {
        insert: 'Olá *',
      },
      {
        insert: {
          mention: {
            denotationChar: '#',
            id: 'patientName',
            value: 'nome_do_paciente',
          },
        },
      },
      {
        insert: '*!\nSua *teleconsulta* com o(a) Profissional *',
      },
      {
        insert: {
          mention: {
            denotationChar: '#',
            id: 'professionalName',
            value: 'nome_profissional',
          },
        },
      },
      {
        insert: '* foi agendado pela clínica para o dia *',
      },
      {
        insert: {
          mention: {
            denotationChar: '#',
            id: 'appointmentDate',
            value: 'data_atendimento',
          },
        },
      },
      {
        insert: '*.\n\nSe os dados do seu agendamento estiverem certos, mande um OK.\n\n```meagenda® Sistema Clínico```\n',
      },
    ],
  },
};

const defaultTelemedCancelation = {
  title: '🚨 Cancelar',
  active: true,
  isDefault: true,
  value: {
    ops: [
      {
        insert: 'Olá *',
      },
      {
        insert: {
          mention: {
            denotationChar: '#',
            id: 'patientName',
            value: 'nome_do_paciente',
          },
        },
      },
      {
        insert: '*, Sua *teleconsulta* com o(a) Profissional ',
      },
      {
        insert: {
          mention: {
            denotationChar: '#',
            id: 'professionalName',
            value: 'nome_profissional',
          },
        },
      },
      {
        insert: ' para o dia ',
      },
      {
        insert: {
          mention: {
            denotationChar: '#',
            id: 'appointmentDate',
            value: 'data_atendimento',
          },
        },
      },
      {
        insert: ' , foi *CANCELADO* pela clínica.\n\n```meagenda® Sistema Clínico```\n',
      },
    ],
  },
};

const defaultTelemedTimeChange = {
  title: '⚠️ Mudança de horário',
  active: true,
  isDefault: true,
  value: {
    ops: [
      {
        insert: 'Olá *',
      },
      {
        insert: {
          mention: {
            denotationChar: '#',
            id: 'patientName',
            value: 'nome_do_paciente',
          },
        },
      },
      {
        insert: '*!\nSua *teleconsulta* com o(a) Profissional *',
      },
      {
        insert: {
          mention: {
            denotationChar: '#',
            id: 'professionalName',
            value: 'nome_profissional',
          },
        },
      },
      {
        insert: '* foi *REAGENDADO* pela clínica para o dia *',
      },
      {
        insert: {
          mention: {
            denotationChar: '#',
            id: 'appointmentDate',
            value: 'data_atendimento',
          },
        },
      },
      {
        insert: '* .\n\n```meagenda® Sistema Clínico```\n',
      },
    ],
  },
};

const defaultTelemedConfirmationLinkMessage = {
  title: '✅ Confirmar presença',
  active: true,
  isDefault: true,
  value: {
    ops: [
      {
        insert: 'Olá *',
      },
      {
        insert: {
          mention: {
            denotationChar: '#',
            id: 'patientName',
            value: 'nome_do_paciente',
          },
        },
      },
      {
        insert: '!\nEstou passando aqui para informar que sua *teleconsulta* com o(a) Profissional*',
      },
      {
        insert: {
          mention: {
            denotationChar: '#',
            id: 'professionalName',
            value: 'nome_profissional',
          },
        },
      },
      {
        insert: ' é dia ',
      },
      {
        insert: {
          mention: {
            denotationChar: '#',
            id: 'appointmentDate',
            value: 'data_atendimento',
          },
        },
      },
      {
        insert: '*. Confirme ou cancele entrando no site abaixo.\n\n',
      },
      {
        insert: {
          mention: {
            denotationChar: '#',
            id: 'queryConfirmed',
            value: 'link_confirmação',
          },
        },
      },
      {
        insert: '\n\nCaso não consiga clicar no link, me adicione aos contatos ou mande qualquer mensagem no chat.\n\n```meagenda® Sistema Clínico```\n',
      },
    ],
  },
};

const defaultTelemedCompliance = {
  title: '📄 Enviar Termo de Consentimento',
  active: true,
  isDefault: true,
  value: {
    ops: [
      {
        insert: 'Olá *',
      },
      {
        insert: {
          mention: {
            denotationChar: '#',
            id: 'patientName',
            value: 'nome_do_paciente',
          },
        },
      },
      {
        insert: '*!\nEstou passando aqui para informar que sua *teleconsulta com o(a) Profissional ',
      },
      {
        insert: {
          mention: {
            denotationChar: '#',
            id: 'professionalName',
            value: 'nome_profissional',
          },
        },
      },
      {
        insert: ' é dia 27/04 de 2023, às 10:50.*\n\nLink da consulta:\n',
      },
      {
        insert: {
          mention: {
            denotationChar: '#',
            id: 'query2HoursBefore',
            value: 'link_telemed',
          },
        },
      },
      {
        insert: ' \n\n```meagenda® Sistema Clínico```\n',
      },
    ],
  },
};

const defaultExtra = {
  title: '⚠️ Exemplo Jejum',
  active: true,
  isDefault: true,
  value: {
    ops: [
      {
        insert: 'Atenção *',
      },
      {
        insert: {
          mention: {
            denotationChar: '#',
            id: 'patientName',
            value: 'nome_do_paciente',
          },
        },
      },
      {
        insert: '* !\nSeu agendamento com o(a) Profissional *',
      },
      {
        insert: {
          mention: {
            denotationChar: '#',
            id: 'professionalName',
            value: 'nome_profissional',
          },
        },
      },
      {
        insert: '* precisa ser realizado em *JEJUM*, pedimos que não faça a injestão de alimentos sólidos *12 horas* antes do atendimento do dia ',
      },
      {
        insert: {
          mention: {
            denotationChar: '#',
            id: 'appointmentDate',
            value: 'data_atendimento',
          },
        },
      },
      {
        insert: '.\n\nAtenciosamente!\n\n```meagenda® Sistema Clínico```\n',
      },
    ],
  },
};

const typeListLabels = {
  manualAppointmentMessagesModel: 'Agendamento',
  onlineAppointmentMessagesModel: 'Agendamento Online',
  telemedMessagesModel: 'Teleconsulta',
  extraMessagesModel: 'Extras',
};

const messageModelsList = {
  messagesTypeList: {
    manualAppointmentMessagesModel: ['id0', 'id1', 'id2', 'id3'],
    onlineAppointmentMessagesModel: ['id4', 'id5'],
    telemedMessagesModel: ['id6', 'id7', 'id8', 'id9', 'id10'],
    extraMessagesModel: ['id11'],
  },
  messages: {
    id0: defaultOnCreateAppointmentMessage,
    id1: defaultConfirmationLinkMessage,
    id2: defaultCancelation,
    id3: defaultTimeChange,
    id4: defaultAcceptedOnlineAppointment,
    id5: defaultOnlineCanceled,
    id6: defaultTelemedConfirmationLinkMessage,
    id7: defaultTelemedCancelation,
    id8: defaultTelemedTimeChange,
    id9: defaultTelemedOnCreateAppointmentMessage,
    id10: defaultTelemedCompliance,
    id11: defaultExtra,
  },
};

const ROOT_URL = process.env.REACT_APP_CLOUD_FUNCTIONS_ROOT_URL;

const getUnifiedTokenStore = (state) => state.Auth.unified;

const getSelectedAddressFromStore = (state) => state.App.selectedAddress;

const getIsProfessionalFromStore = (state) => state.Auth.professional;

const getMainUserFromStore = (state) => state.Auth.mainUser;

const getProfileFromStore = (state) => state.Profile.profile;

const getMessageModelsFromStore = (state) => state.Messages.messageModels;

const getSelectedAgendaFromStore = (state) => state.Agenda.selectedAgenda;

const getCustomUsersFromStore = (state) => state.CustomUsers.customUsers;

const getCustomUsersInitialFetchFromStore = (state) => state.CustomUsers.initialFetch;

function createMessagesSettingsListener(mainUser) {
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const db = getDatabase();
  const listener = eventChannel((emit) => {
    const proceduresRef = ref(db, `users/${uid}/messagesSettings`);
    const unsubscribe = onValue(proceduresRef, (req) => (
      emit(req.val() || {})
    ));
    return () => unsubscribe();
  });
  return listener;
}

function getTwilioSettingsFromDB(mainUser) {
  const fs = getFirestore();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const userRef = doc(
    fs,
    'professionals',
    uid,
    'twilio',
    'users',
  );
  return getDocFromServer(userRef);
}

function normalizeSendAutomaticMessagesOnDB(data, mainUser) {
  const dbRef = ref(getDatabase());
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  Object.keys(data).forEach((userId) => {
    if (!data[userId]) {
      updates[`users/${uid}/messagesSettings/perUser/${userId}/sendAutomaticMessages`] = false;
    }
  });
  return update(dbRef, updates);
}

export function* getMessagesSettingsRequest() {
  yield takeEvery([
    authActions.LOGIN_SUCCESS,
    // actions.FETCH_MESSAGES_SETTINGS_REQUEST,
  ], function* () {
    try {
      const professional = yield select(getIsProfessionalFromStore);
      if (professional) {
        const mainUser = yield select(getMainUserFromStore);
        let customUsers = yield select(getCustomUsersFromStore);
        let customUsersInitialFetch = yield select(getCustomUsersInitialFetchFromStore);
        if (!customUsersInitialFetch) {
          yield take(customUsersActions.CUSTOM_USERS_FETCH_SUCCESS);
          customUsersInitialFetch = true;
        }
        let profile = yield select(getProfileFromStore);
        if (_.isEmpty(profile)) {
          yield take(profileActions.PROFILE_INFO_SUCCESS);
          profile = yield select(getProfileFromStore);
        }
        const twilioSettings = yield call(getTwilioSettingsFromDB, mainUser);
        const messagesListener = yield call(createMessagesSettingsListener, mainUser);
        yield takeEvery(messagesListener, function* (messagesSettings) {
          customUsers = yield select(getCustomUsersFromStore);
          const healthProfessionals = customUsers.filter((el) => el.healthProfessional);
          healthProfessionals.push(profile);
          if (twilioSettings.data()) {
            yield put({
              type: actions.SET_TWILIO_SETTINGS,
              payload: {
                data: twilioSettings.data(),
              },
            });
            yield call(normalizeSendAutomaticMessagesOnDB, twilioSettings.data());
          }
          const defaultObj = {
            sendAutomaticMessages: false,
            defaultSendMessageState: true,
          };
          let messagesSettingsPerUser = { ...defaultObj };
          const fullMessagesSettings = {};
          if (messagesSettings) {
            defaultObj.defaultSendMessageState = messagesSettings.defaultSendMessageState || true;
            messagesSettingsPerUser = { ...defaultObj };
            healthProfessionals.forEach((user) => {
              if (messagesSettings?.perUser?.[user.id]) {
                fullMessagesSettings[user.id] = {
                  ...defaultObj,
                  ...messagesSettings.perUser[user.id],
                };
                const auth = getAuth();
                const { currentUser } = auth;
                const { uid } = currentUser;
                if (user.id === uid) {
                  messagesSettingsPerUser = {
                    ...defaultObj,
                    ...messagesSettings.perUser[uid],
                  };
                }
              } else {
                fullMessagesSettings[user.id] = {
                  ...defaultObj,
                };
              }
            });
            yield put({
              type: actions.FETCH_MESSAGES_SETTINGS_SUCCESS,
              payload: {
                messagesSettingsPerUser,
                fullMessagesSettings,
              },
            });
          } else {
            yield put({
              type: actions.FETCH_MESSAGES_SETTINGS_SUCCESS,
              payload: {
                messagesSettingsPerUser,
                fullMessagesSettings,
              },
            });
          }
        });
        const actionTriggered = yield take([
          appActions.CANCEL_LISTENERS,
        ]);
        messagesListener.close();
        if (actionTriggered?.type === 'CANCEL_LISTENERS') {
          // It is the general "cancel all listeners".
          yield put({
            type: appActions.CANCEL_LISTENERS_SUCCESS,
          });
        }
      }
    } catch (error) {
      console.warn(error);
    }
  });
}

function changeSendAutomaticMessagesOnDB({ value, userId }, mainUser) {
  const dbRef = ref(getDatabase());
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  updates[`users/${uid}/messagesSettings/perUser/${userId}/sendAutomaticMessages`] = value;
  // return true;
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* changeSendAutomaticMessagesRequest() {
  yield takeLatest(actions.CHANGE_SEND_AUTOMATIC_MESSAGES, function* (action) {
    try {
      yield put({ type: actions.UPDATING_SEND_AUTOMATIC_MESSAGES });
      const mainUser = yield select(getMainUserFromStore);
      yield call(changeSendAutomaticMessagesOnDB, action.payload, mainUser);
      // yield put({
      //   type: actions.FETCH_MESSAGES_SETTINGS_REQUEST,
      // });
    } catch (error) {
      console.warn(error);
      notification(
        'error',
        'Algo deu errado',
        'Tente novamente mais tarde.',
      );
      yield put({
        type: actions.CHANGE_SEND_AUTOMATIC_MESSAGES_ERROR,
      });
    }
  });
}

function changeDefaultSendMessageStateOnDB({ value, userId }, mainUser) {
  const dbRef = ref(getDatabase());
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  updates[`users/${uid}/messagesSettings/perUser/${userId}/defaultSendMessageState`] = value;
  // return true;
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* changeDefaultSendMessageStateRequest() {
  yield takeLatest(actions.CHANGE_DEFAULT_SEND_MESSAGES_STATE, function* (action) {
    try {
      yield put({ type: actions.UPDATING_SEND_AUTOMATIC_MESSAGES });
      const mainUser = yield select(getMainUserFromStore);
      yield call(changeDefaultSendMessageStateOnDB, action.payload, mainUser);
      // yield put({
      //   type: actions.FETCH_MESSAGES_SETTINGS_REQUEST,
      // });
    } catch (error) {
      console.warn(error);
      notification(
        'error',
        'Algo deu errado',
        'Tente novamente mais tarde.',
      );
      yield put({
        type: actions.CHANGE_DEFAULT_SEND_MESSAGES_STATE_ERROR,
      });
    }
  });
}

function deleteMessageModelOnDB(payload, mainUser, storedMessages) {
  const dbRef = ref(getDatabase());
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  if (payload && storedMessages?.messagesTypeList) {
    Object.keys(storedMessages.messagesTypeList).forEach((key) => {
      if (storedMessages.messagesTypeList[key].includes(payload)) {
        updates[`users/${uid}/messageModels/messagesTypeList/${key}`] = storedMessages.messagesTypeList[key].filter((item) => item !== payload);
        updates[`users/${uid}/messageModels/messages/${payload}`] = null;
      }
    });
  }
  return update(dbRef, updates);
  // return new Promise((resolve, reject) => {
  //   db.ref().update(updates, (error) => {
  //     if (error) {
  //       reject(new Error(error));
  //     } else {
  //       resolve(true);
  //     }
  //     return null;
  //   });
  // });
}

export function* deleteMessageModel() {
  yield takeEvery(actions.DELETE_MESSAGE_MODEL, function* (action) {
    try {
      const mainUser = yield select(getMainUserFromStore);
      const storedMessages = yield select(getMessageModelsFromStore);
      yield call(
        deleteMessageModelOnDB,
        action.payload,
        mainUser,
        storedMessages,
      );
      yield put({
        type: actions.FETCH_MESSAGE_MODELS_REQUEST,
      });
    } catch (error) {
      console.warn(error);
    }
  });
}

function saveMessageModelsOnDB(payload, mainUser, messageUid, storedMessages) {
  const db = getDatabase();
  const dbRef = ref(db);
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const updates = {};
  if (!payload) {
    // It does not have any message, need to save defaults
    updates[`users/${uid}/messageModels`] = messageModelsList;
  } else if (!messageUid && payload?.content?.modelType && storedMessages?.messagesTypeList) {
    // It is going to create a new message, pre-requisites are:
    //   1- No id provided, need to create new pushKey;
    //   2- Default models already created.
    const pushKey = push(child(ref(db), `users/${uid}/messageModels/messages`)).key;
    // Append the new id to its selected modelType
    const newMessageList = [...storedMessages.messagesTypeList[payload.content.modelType], pushKey];
    updates[`users/${uid}/messageModels/messages/${pushKey}`] = payload.content;
    updates[`users/${uid}/messageModels/messagesTypeList/${payload.content.modelType}`] = newMessageList;
  } else if (messageUid && !storedMessages.messagesTypeList[payload.content.modelType].includes(messageUid)) {
    // Updating existing message changing its model type, need to iterate through models searching for the message id
    Object.keys(storedMessages.messagesTypeList).forEach((item) => {
      if (storedMessages.messagesTypeList[item].includes(messageUid)) {
        // If the id is found, need to remove it from the array
        const filtered = storedMessages.messagesTypeList[item].filter((id) => (id !== messageUid));
        updates[`users/${uid}/messageModels/messagesTypeList/${item}`] = filtered;
      }
    });
    // After removing the id from the old model, now we place it on the new model list
    const newMessageList = [...storedMessages.messagesTypeList[payload.content.modelType], messageUid];
    updates[`users/${uid}/messageModels/messages/${messageUid}`] = payload.content;
    updates[`users/${uid}/messageModels/messagesTypeList/${payload.content.modelType}`] = newMessageList;
  } else if (messageUid) {
    // Updating existing message without changing its model type
    updates[`users/${uid}/messageModels/messages/${messageUid}`] = payload.content;
  } else {
    throw new Error('Problema para atualizar o modelo de mensagem');
  }
  return update(dbRef, updates);
}

export function* saveMessageModels() {
  yield takeEvery(actions.SAVE_MESSAGE_MODELS_REQUEST, function* (action) {
    try {
      yield put({
        type: actions.START_SAVING_MESSAGE_MODEL,
      });
      yield call(sleep, 500);
      const mainUser = yield select(getMainUserFromStore);
      const messageUid = action.payload?.content?.id;
      const storedMessages = yield select(getMessageModelsFromStore);
      yield call(
        saveMessageModelsOnDB,
        action.payload?.useDefault ? true : action.payload,
        mainUser,
        messageUid,
        storedMessages,
      );
      yield all([
        yield put({
          type: actions.SAVE_MESSAGE_MODELS_SUCCESS,
        }),
        yield put({
          type: actions.FETCH_MESSAGE_MODELS_REQUEST,
        }),
      ]);
    } catch (error) {
      console.warn(error);
      yield put({ type: actions.SAVE_BIRTHDAY_MESSAGE_ERROR });
    }
  });
}

function getMessageModelsFromDB(mainUser) {
  const db = getDatabase();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  return new Promise((resolve) => {
    onValue(ref(db, `users/${uid}/messageModels`), resolve, { onlyOnce: true });
  });
}

export function* getMessageModels() {
  yield takeEvery(actions.FETCH_MESSAGE_MODELS_REQUEST, function* () {
    try {
      const mainUser = yield select(getMainUserFromStore);
      const confirmationMessage = yield call(getMessageModelsFromDB, mainUser);
      if (!confirmationMessage.val()) {
        yield call(saveMessageModelsOnDB, null, mainUser, null, null);
        yield put({
          type: actions.FETCH_MESSAGE_MODELS_REQUEST,
        });
      } else {
        const responseObject = confirmationMessage.val();
        responseObject.messages = Object.keys(responseObject?.messages).map((key) => {
          let auxType = '';
          Object.keys(responseObject.messagesTypeList).some((typeKey) => {
            if (responseObject.messagesTypeList[typeKey].includes(key)) {
              auxType = typeKey;
              return true;
            }
            return false;
          });
          return ({
            ...responseObject.messages[key],
            id: key,
            modelType: auxType,
            modelTypeTitle: typeListLabels[auxType],
          });
        });
        responseObject.messages = _.orderBy(responseObject.messages, ['modelTypeTitle', 'id']);
        yield put({
          type: actions.FETCH_MESSAGE_MODELS_SUCCESS,
          payload: responseObject,
        });
      }
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.FETCH_MESSAGE_MODELS_ERROR,
      });
    }
  });
}

function getPatientMobileFromDB(patientId, mainUser, addressUid, unified = {}) {
  const db = getDatabase();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  if (unified.id && unified.address.some((a) => a === addressUid)) {
    return new Promise((resolve) => {
      onValue(ref(db, `unified/${unified.id}/patients/${patientId}/mobile`), resolve, { onlyOnce: true });
    });
  }
  return new Promise((resolve) => {
    onValue(ref(db, `users/${uid}/patients/${patientId}/mobile`), resolve, { onlyOnce: true });
  });
}

export function* fetchPatientMobile() {
  yield takeEvery(actions.FETCH_PATIENT_MOBILE_REQUEST, function* (action) {
    try {
      const mainUser = yield select(getMainUserFromStore);
      const unified = yield select(getUnifiedTokenStore);
      const addressUid = yield select(getSelectedAddressFromStore);
      const mobile = yield call(getPatientMobileFromDB, action.payload.patientId, mainUser, addressUid, unified);
      yield put({
        type: actions.FETCH_PATIENT_MOBILE_SUCCESS,
        payload: {
          patientId: action.payload.patientId,
          mobile: mobile.val(),
        },
      });
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.FETCH_PATIENT_MOBILE_ERROR,
      });
    }
  });
}

function writeQueryConfirmedOnDB(event, addressUid, agendaId, mainUser, idToken, unified = {}) {
  const auth = getAuth();
  const { currentUser } = auth;
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    ({ uid } = currentUser);
  }
  const config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  const queryObj = {
    time: event.sendMessages ? event.time : moment(event.time, 'YYYY-MM-DD HH:mm').subtract(6, 'months').format('YYYY-MM-DD'),
    address: addressUid,
    agenda: agendaId,
    mainUser: mainUser || null,
    professional: event.professional || uid,
    appointment: event.id,
    patient: event.user,
    userLocal: true,
    unifiedToken: unified.id && unified.address.some((a) => a === addressUid) ? unified.id : null,
    manuallySendMessage: event.manuallySendMessage || null,
  };
  if (event.isVideoCall && event.videoCallData && event.videoCallData.videoCallLink) {
    queryObj.videoCallLink = true;
  }
  return axios.post(
    `${ROOT_URL}/manuallyCreateQueryConfirmed`,
    {
      queryObj,
      key: event.queryConfirmedKey,
    },
    config,
  );
}

function getQueryConfirmedFromDB(event) {
  const db = getDatabase();
  return new Promise((resolve) => {
    onValue(ref(db, `queryConfirmed/${event?.queryConfirmedKey}`), resolve, { onlyOnce: true });
  });
}

function getIdToken() {
  const auth = getAuth();
  const { currentUser } = auth;
  return currentUser.getIdToken();
}

export function* checkIfQueryConfirmedExists() {
  yield takeEvery(actions.CHECK_IF_QUERYCONFIRMED_EXISTS, function* (action) {
    try {
      const { payload } = action;
      const { event } = payload;
      const queryConfirmedObject = yield call(getQueryConfirmedFromDB, event);
      const queryConfirmedObjectValue = queryConfirmedObject.val();
      if (queryConfirmedObjectValue) {
        yield put({
          type: actions.CHECK_IF_QUERYCONFIRMED_EXISTS_TRUE,
        });
      } else {
        const mainUser = yield select(getMainUserFromStore);
        const unified = yield select(getUnifiedTokenStore);
        const agendaId = yield select(getSelectedAgendaFromStore);
        const addressUid = yield select(getSelectedAddressFromStore);
        const idToken = yield call(getIdToken);
        yield call(writeQueryConfirmedOnDB, event, addressUid, agendaId, mainUser, idToken, unified);
      }
    } catch (error) {
      console.warn(error);
      yield put({
        type: actions.CHECK_IF_QUERYCONFIRMED_EXISTS_ERROR,
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getMessagesSettingsRequest),
    fork(changeSendAutomaticMessagesRequest),
    fork(changeDefaultSendMessageStateRequest),
    fork(saveMessageModels),
    fork(getMessageModels),
    fork(deleteMessageModel),
    fork(fetchPatientMobile),
    fork(checkIfQueryConfirmedExists),
  ]);
}
