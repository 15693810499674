import _ from 'lodash';
import React, {
  lazy,
  Suspense,
} from 'react';
import {
  Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { siteConfig } from './settings';
import PageLoading from './components/uielements/pageLoading';
import TelemedModal from './components/telemedModal';
import componentLoader from './helpers/componentLoader';

// import Dashboard from './containers/App/App';
import asyncComponent from './helpers/AsyncFunc';

const Dashboard = lazy(() => componentLoader(() => import('./containers/App/App'), 3));

function PrivateRoute({
  path,
  component: Component,
  isLoggedIn,
  isProfessional,
  professionalArea,
  patientArea,
  maintenance,
  ...rest
}) {
  return (
    <Route
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      render={(props) => {
        const { pathname } = props.location;
        if (maintenance?.active && pathname !== '/maintenance') {
          return (
            <Redirect
              // to="maintenance"
              to={{
                pathname: '/maintenance',
                state: { from: props.location },
              }}
            />
          );
        }
        if (_.isNull(isLoggedIn) || _.isNull(isProfessional)) {
          return null;
        }
        if (professionalArea && isLoggedIn && isProfessional) {
          if (path === '/dashboard') {
            // eslint-disable-next-line react/jsx-props-no-spreading
            return <Dashboard {...props} />;
          }
          // eslint-disable-next-line react/jsx-props-no-spreading
          return <Component {...props} />;
        }
        if (professionalArea && isLoggedIn && !isProfessional) {
          return <Redirect to="/" />;
        }
        if (patientArea && isLoggedIn) {
          // eslint-disable-next-line react/jsx-props-no-spreading
          return <Component {...props} />;
        }
        if (patientArea && !isLoggedIn) {
          return <Redirect to="/" />;
        }

        // if (pathname === '/dashboard' || pathname === '/dashboard/') {
        // // if (pathname && pathname.includes('/dashboard')) {
        //   if (isLoggedIn && isProfessional) {
        //     return <Component {...props} />;
        //   }
        // } else if (isLoggedIn) {
        //   return <Component {...props} />;
        // }
        // if (patientArea) {
        //   return <Redirect to="/" />;
        // }
        return (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
}

class PublicRoute extends React.Component {
  constructor(props) {
    super(props);
    this.pathname = null;
  }

  shouldComponentUpdate(nextProps) {
    const {
      isLoggedIn,
      component,
    } = this.props;
    if (this.pathname === '/profile' || this.pathname === '/profile/') {
      if (nextProps.isLoggedIn
        && isLoggedIn !== nextProps.isLoggedIn
        && !_.isNull(isLoggedIn)) {
        return false;
      }
      if (isLoggedIn === nextProps.isLoggedIn
        && component !== nextProps.component
        && (nextProps.location.pathname === '/profile' || nextProps.location.pathname === '/profile/')) {
        return false;
      }
    }
    // if (this.pathname
    //   && (this.pathname === '/profile'
    //     || this.pathname === '/profile/')
    //   && nextProps.isLoggedIn
    //   && isLoggedIn !== nextProps.isLoggedIn
    //   && !_.isNull(isLoggedIn)) {
    //   // User has logged in, but no need to refresh page
    //   return false;
    // }
    // if (_.isNull(isLoggedIn) && nextProps.isLoggedIn === false) {
    //   // Redux updated the auth status and the user isn't logged in
    //   return false;
    // }
    return true;
  }

  render() {
    const {
      component: Component, isLoggedIn, isProfessional, restricted, maintenance, ...rest
    } = this.props;
    return (
      <Route
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
        render={(props) => {
          const { pathname, state } = props.location;
          this.pathname = pathname;
          if (maintenance?.active && pathname !== '/maintenance') {
            return (
              <Redirect
                to={{
                  pathname: '/maintenance',
                  state: { from: props.location },
                }}
              />
            );
          }
          if (!maintenance?.active && pathname === '/maintenance') {
            if (state?.from) {
              return <Redirect to={{ ...state.from }} />;
            }
            return <Redirect to="/" />;
          }
          if (_.isNull(isLoggedIn)) {
            return null;
          }
          if (isLoggedIn && isProfessional && restricted) {
            return (
              <Redirect
                to={{
                  pathname: '/dashboard',
                  state: { from: props.location },
                }}
              />
            );
          }
          if (isLoggedIn && !isProfessional && restricted) {
            return <Redirect to="/" />;
            // redirecionar pro "dashboard" do paciente,
            // talvez a pagina de perfil dele?
          }
          // eslint-disable-next-line react/jsx-props-no-spreading
          return <Component {...props} />;
        }}
      />
    );
  }
}

const renderPageTitle = (firstName, lastName, customUserProfile, chatBadgeCount) => {
  if (!firstName || !lastName) {
    return siteConfig.siteName;
  }
  let text = `${firstName} ${lastName}`;
  if (customUserProfile && customUserProfile.fullName && (customUserProfile.customHealthProfessional || customUserProfile.healthProfessional)) {
    text = `${customUserProfile.fullName}`;
  }
  if (chatBadgeCount) {
    text = `(${chatBadgeCount}) ${text}`;
  }
  return text;
};

function renderReloadApp(reloadApp) {
  if (reloadApp) {
    return (
      <div
        style={{
          backgroundColor: 'rgba(0, 0, 0, 0.45)',
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 2000,
        }}
      >
        <div
          style={{
            position: 'fixed',
            bottom: 30,
            right: 30,
            // zIndex: 2,
            borderRadius: '4px',
            border: '1px solid #e8e8e8',
            backgroundColor: '#fff',
            padding: '20px',
            boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.5)',
          }}
        >
          <p style={{ marginBottom: 0, color: '#555' }}>Baixando nova versão...</p>
        </div>
      </div>
    );
  }
  return null;
}

function PublicRoutes({
  history,
  isLoggedIn,
  isProfessional,
  reloadApp,
  firstName,
  lastName,
  customUserProfile,
  chatBadgeCount,
  maintenance,
}) {
  return (
    <Suspense
      fallback={(
        <PageLoading />
      )}
    >
      {_.isUndefined(maintenance) ? (
        <PageLoading />
      ) : (
        <>
          {renderReloadApp(reloadApp)}
          <TelemedModal />
          <Helmet defer={false}>
            <meta charSet="utf-8" />
            <meta name="theme-color" content="#FFF" />
            <title>{renderPageTitle(firstName, lastName, customUserProfile, chatBadgeCount)}</title>
          </Helmet>
          <Router
            history={history}
          >
            <Switch>
              <PublicRoute
                component={asyncComponent(() => import('./containers/Page/maintenance'))}
                maintenance={maintenance}
                path="/maintenance"
                exact
              />
              <PublicRoute
                component={asyncComponent(() => import('./containers/Page/home'))}
                maintenance={maintenance}
                path="/"
                exact
              />
              <PublicRoute
                restricted
                component={asyncComponent(() => import('./containers/Page/signin'))}
                maintenance={maintenance}
                path="/signin"
                isLoggedIn={isLoggedIn}
                isProfessional={isProfessional}
              />
              {/* <PublicRoute
                restricted
                component={asyncComponent(() => import('./containers/Page/signup'))}
                path="/signup"
                isLoggedIn={isLoggedIn}
                isProfessional={isProfessional}
                // exact
              /> */}
              <PublicRoute
                restricted
                component={asyncComponent(() => import('./containers/Page/signup'))}
                maintenance={maintenance}
                path="/hiddensignup"
                isLoggedIn={isLoggedIn}
                isProfessional={isProfessional}
              />
              <PublicRoute
                component={asyncComponent(() => import('./containers/Page/list'))}
                maintenance={maintenance}
                path="/list"
                isLoggedIn={isLoggedIn}
                isProfessional={isProfessional}
              />
              <PublicRoute
                component={asyncComponent(() => import('./containers/Page/singleConfirmationPage'))}
                maintenance={maintenance}
                path="/c"
                isLoggedIn={isLoggedIn}
                isProfessional={isProfessional}
              />
              <PublicRoute
                component={asyncComponent(() => import('./containers/Page/telemedLinkPage'))}
                maintenance={maintenance}
                path="/t"
                isLoggedIn={isLoggedIn}
                isProfessional={isProfessional}
              />
              <PublicRoute
                component={asyncComponent(() => import('./containers/Page/checkFacebookData'))}
                maintenance={maintenance}
                path="/deletion"
                isLoggedIn={isLoggedIn}
                isProfessional={isProfessional}
              />
              <PublicRoute
                component={asyncComponent(() => import('./containers/Page/profile'))}
                maintenance={maintenance}
                path="/profile"
                isLoggedIn={isLoggedIn}
                isProfessional={isProfessional}
              />
              <PublicRoute
                component={asyncComponent(() => import('./containers/Page/termsPrivacy'))}
                maintenance={maintenance}
                path="/terms"
                isLoggedIn={isLoggedIn}
                isProfessional={isProfessional}
              />
              <PublicRoute
                component={asyncComponent(() => import('./containers/About/index'))}
                maintenance={maintenance}
                path="/about"
                isLoggedIn={isLoggedIn}
                isProfessional={isProfessional}
              />
              {/* <PublicRoute
                restricted
                component={asyncComponent(() => import('./containers/Page/registerUser'))}
                path="/registerUser"
                isLoggedIn={isLoggedIn}
                isProfessional={isProfessional}
              /> */}
              <PublicRoute
                restricted
                component={asyncComponent(() => import('./containers/Page/customUserSignin'))}
                maintenance={maintenance}
                path="/customUserSignin"
                isLoggedIn={isLoggedIn}
                isProfessional={isProfessional}
              />
              <PrivateRoute
                path="/dashboard"
                component={Dashboard}
                // component={lazy(() => componentLoader(() => import('./containers/App/App'), 3))}
                // component={asyncComponent(() => import('./containers/App/App'))}
                maintenance={maintenance}
                isLoggedIn={isLoggedIn}
                isProfessional={isProfessional}
                professionalArea
              />
              <PrivateRoute
                path="/confirmation"
                component={asyncComponent(() => import('./containers/Page/confirmation'))}
                maintenance={maintenance}
                isLoggedIn={isLoggedIn}
                patientArea
              />
              <PrivateRoute
                path="/appointments"
                isLoggedIn={isLoggedIn}
                isProfessional={isProfessional}
                component={asyncComponent(() => import('./containers/Page/appointments'))}
                maintenance={maintenance}
                patientArea
              />
              <Route component={asyncComponent(() => import('./containers/Page/home'))} />
            </Switch>
          </Router>
        </>
      )}
    </Suspense>
  );
}

export default connect((state) => ({
  isLoggedIn: state.Auth.user,
  isProfessional: state.Auth.professional,
  reloadApp: state.App.reloadApp,
  firstName: state.Profile.profile.firstName,
  lastName: state.Profile.profile.lastName,
  customUserProfile: state.Profile.customUserProfile,
  chatBadgeCount: state.Chat.chatBadgeCount,
  maintenance: state.App.maintenance,
}))(PublicRoutes);
