import styled from 'styled-components';

const WidgetWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  .waiting-connection,
  .connection-established {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .waiting-connection {
    top: 0;
    bottom: 0;
  }

  .connection-established {
    top: 8px;
  }

  .waiting-connection p {
    margin-bottom: 10px;
    text-align: center;
    padding: 0 30px;
    color: #555;
  }

  .connection-established p {
    margin-bottom: 0;
    color: #777;
  }

  .connection-established p,
  .connection-established .anticon {
    font-size: 12px;
  }

  .rcw-launcher {
    display: none;
  }

  .rcw-widget-container {
    position: inherit;
    margin: 0;
    height: 100%;
    max-width: ${(props) => (props.smallScreenMode ? '100%' : '370px')};
    background-color: #fff;
    border-radius: 10px;
  }

  .rcw-conversation-container {
    box-shadow: none;
    border: 1px solid rgb(217, 217, 217);
    height: 100%;
    opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  }

  .rcw-launcher > img.rcw-open-launcher {
    height: 26px;
    width: 26px;
  }

  .rcw-launcher > img.rcw-close-launcher {
    width: 18px;
  }
  
  /* .rcw-widget-container {
    margin: ${(props) => (props.bigMargin ? '0 20px 67px 0' : '0 20px 14px 0')};
    transition: all 500ms;
  } */

  .rcw-conversation-container {
    background-color: ${(props) => (props.disabled ? '#e3e3e3' : '#fff')};
    overflow: hidden;
  }
  
  .rcw-messages-container {
    background-color: transparent;
    height: calc(100% - 45px);
    box-sizing: border-box;
    max-height: 100%;
  }

  .rcw-launcher,
  .rcw-conversation-container > .rcw-header {
    background-color: #1890ff;
  }

  .rcw-header {
    display: ${(props) => (props.hideHeader ? 'none' : 'flex')};
  }

  .rcw-message-text p {
    margin-bottom: 0;
  }

  .rcw-sender {
    box-sizing: border-box;
    font-size: 14px;

    & > * {
      box-sizing: border-box;
    }
  }
`;

export default WidgetWrapper;
