import _ from 'lodash';
import {
  getAuth,
} from 'firebase/auth';
import {
  getDatabase,
  ref,
  child,
  push,
  query as dbQuery,
  orderByChild,
  startAt,
  get,
} from 'firebase/database';
import { eventChannel } from 'redux-saga';
import {
  all,
  takeLatest,
  put,
  call,
  fork,
  select,
  take,
  takeEvery,
} from 'redux-saga/effects';
import moment from 'moment-timezone';
import axios from 'axios';
import {
  rrulestr,
  datetime,
} from 'rrule';
import actions from './actions';
import agendaActions from '../agenda/actions';
import { notification } from '../../components';

const ROOT_URL = process.env.REACT_APP_CLOUD_FUNCTIONS_ROOT_URL;

const getMainUserFromStore = (state) => state.Auth.mainUser;

const getSelectedAddressFromStore = (state) => state.App.selectedAddress;

const getSelectedAgendaFromStore = (state) => state.Agenda.selectedAgenda;

// const getFullBatchedAppointmentsArrFromStore = (state) => state.Agenda.fullBatchedAppointmentsArr;

// const getPersistedBatchedAppointmentsFromStore = (state) => state.Agenda.persistedBatchedAppointments;

function requestAppointmentsFromDB(search, mainUser) {
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  return axios.post(
    `${ROOT_URL}/reportAppointments`,
    {
      ...search,
      professional: uid,
    },
  );
}

function getBatchedFromDB(startDate, agendaId, mainUser) {
  const db = getDatabase();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const databaseRef = ref(db, `batchedAppointments/${uid}/${agendaId}/batched`);
  const queryRef = dbQuery(
    databaseRef,
    orderByChild('queryTimestamp'),
    startAt(startDate),
  );
  return get(queryRef);
}

function getPersistedFromDB(startDate, agendaId, mainUser) {
  const db = getDatabase();
  let uid;
  if (mainUser) {
    uid = mainUser;
  } else {
    const auth = getAuth();
    const { currentUser } = auth;
    ({ uid } = currentUser);
  }
  const databaseRef = ref(db, `batchedAppointments/${uid}/${agendaId}/persisted`);
  const queryRef = dbQuery(
    databaseRef,
    orderByChild('time'),
    startAt(startDate),
  );
  return get(queryRef);
}

export function* requestAppointments() {
  yield takeLatest(actions.APPOINTMENTS_INFO_REQUEST, function* (action) {
    try {
      yield put({ type: actions.APPOINTMENTS_INFO_WAITING });
      const currentAddress = yield select(getSelectedAddressFromStore);
      const mainUser = yield select(getMainUserFromStore);
      let agendaId = yield select(getSelectedAgendaFromStore);
      if (_.isEmpty(agendaId)) {
        yield take(agendaActions.SELECT_AGENDA);
        agendaId = yield select(getSelectedAgendaFromStore);
      }
      // let fullBatchedAppointmentsArr = yield select(getFullBatchedAppointmentsArrFromStore);
      // if (_.isUndefined(fullBatchedAppointmentsArr)) {
      //   yield take(agendaActions.SET_FULL_BATCHED_APPOINTMENTS_ARR);
      //   fullBatchedAppointmentsArr = yield select(getFullBatchedAppointmentsArrFromStore);
      // }
      // console.log('fullBatchedAppointmentsArr', fullBatchedAppointmentsArr);
      // let persistedBatchedAppointments = yield select(getPersistedBatchedAppointmentsFromStore);
      // if (_.isUndefined(persistedBatchedAppointments)) {
      //   yield take(agendaActions.SET_PERSISTED_BATCHED_APPOINTMENTS);
      //   persistedBatchedAppointments = yield select(getPersistedBatchedAppointmentsFromStore);
      // }
      // console.log('persistedBatchedAppointments', persistedBatchedAppointments);
      const { data } = yield call(requestAppointmentsFromDB, {
        date: moment(action.payload.date).tz('America/Sao_Paulo').format('YYYY-MM-DD'),
        endDate: moment(action.payload.endDate).tz('America/Sao_Paulo').format('YYYY-MM-DD'),
        agenda: agendaId,
        addressId: currentAddress,
      }, mainUser);
      const db = getDatabase();
      let uid;
      if (mainUser) {
        uid = mainUser;
      } else {
        const auth = getAuth();
        const { currentUser } = auth;
        ({ uid } = currentUser);
      }
      const startDate = moment(action.payload.date).tz('America/Sao_Paulo');
      const endDate = moment(action.payload.endDate).tz('America/Sao_Paulo');
      const batched = yield call(
        getBatchedFromDB,
        startDate.format('YYYY-MM-DD'),
        agendaId,
        mainUser,
      );
      const persisted = yield call(
        getPersistedFromDB,
        startDate.format('YYYY-MM-DD'),
        agendaId,
        mainUser,
      );
      let fullBatchedAppointmentsArr = [];
      if (batched.val()) {
        fullBatchedAppointmentsArr = _.map(batched.val(), (val, id) => ({
          ...val,
          batchedId: id,
        }));
      }
      let persistedBatchedAppointments = {};
      if (persisted.val()) {
        persistedBatchedAppointments = persisted.val();
      }
      const batchedDatesEvents = [];
      fullBatchedAppointmentsArr.forEach((el) => {
        const rule = rrulestr(el.rrule);
        const ruleDtstart = moment(rule.origOptions.dtstart).format('YYYY-MM-DD');
        const batchedDates = rule.between(datetime(startDate.year(), startDate.month() + 1, startDate.date()), datetime(endDate.year(), endDate.month() + 1, endDate.date()), true);
        const duration = moment.duration(el.appointmentModel.duration).asMinutes() || 15;
        const timeStr = moment(el.appointmentModel.time, 'YYYY-MM-DD HH:mm').format('HH:mm');
        batchedDates.forEach((date) => {
          const dateStr = moment(date).utcOffset(0).format('YYYY-MM-DD');
          if (dateStr >= ruleDtstart) {
            let alreadyPersisted = false;
            if (persistedBatchedAppointments) {
              alreadyPersisted = Object.values(persistedBatchedAppointments).some((obj) => {
                const splittedTime = obj.time.split(' ')[0];
                if (splittedTime === dateStr && el.batchedId === obj.batchedId) {
                  return true;
                }
                return false;
              });
            }
            if (!alreadyPersisted) {
              const startTime = moment(`${dateStr} ${timeStr}`, 'YYYY-MM-DD HH:mm').toDate();
              const endTime = moment(`${dateStr} ${timeStr}`, 'YYYY-MM-DD HH:mm').add(duration, 'm').toDate();
              const pushKey = push(child(ref(db), `/requests/${uid}/${agendaId}/confirmed`)).key;
              if (el.blocked) {
                // Do nothing.
              } else {
                batchedDatesEvents.push({
                  key: pushKey,
                  appointmentInfo: {
                    ...el.appointmentModel,
                    batchedId: el.batchedId,
                    id: pushKey,
                    time: `${dateStr} ${timeStr}`,
                    start: startTime,
                    end: endTime,
                    allDay: false,
                    blockAll: false,
                    type: 'confirmed',
                  },
                });
              }
            }
          }
        });
      });
      yield put({
        type: actions.APPOINTMENTS_INFO_SUCCED,
        payload: {
          data: [...Object.values(data).filter((el) => el.mode !== 'blocked'), ...batchedDatesEvents],
        },
      });
    } catch (error) {
      console.warn(error);
      notification('error', 'Algo deu errado ao gerar o relatório', 'Tente novamente mais tarde.');
      yield put({
        type: actions.APPOINTMENTS_INFO_ERROR,
      });
    }
  });
}

function createWebWorkerListener(worker) {
  const listener = eventChannel((emit) => {
    worker.addEventListener('message', emit);
    return () => worker.removeEventListener('message', emit);
  });
  return listener;
}

export function* startPdfWebWorkerRequest() {
  yield takeLatest(actions.START_PDF_WEB_WORKER_REQUEST, function* (action) {
    try {
      const { params } = action.payload;
      yield put({
        type: actions.START_PDF_WEB_WORKER,
        payload: {
          params,
        },
      });
    } catch (error) {
      console.warn(error);
    }
  });
}

export function* startPdfWebWorker() {
  yield takeLatest(actions.START_PDF_WEB_WORKER, function* (action) {
    try {
      const timestamp = moment().format();
      const { params } = action.payload;
      const stacks = params.appointments.length > 750 ? Math.floor(params.appointments.length / 500) : 0;
      const durationInSeconds = (params.appointments.length * (30 + (15 * stacks))) / 500;
      const estimatedEndTime = moment(timestamp).add(durationInSeconds, 'seconds').format();
      yield put({
        type: actions.RENDERING_PDF_WITH_WEB_WORKER,
        payload: {
          startTime: timestamp,
          estimatedEndTime,
          pdfName: `Relatório ${params.startDate} até ${params.endDate}.pdf`,
          pdfDescription: `Neste período foram registrado(s) ${params.appointments.length} agendamento(s)`,
        },
      });
      if (params.appointments.length > 100) {
        notification(
          'info',
          'O relatório está sendo gerado',
          'Você pode continuar utilizando o Meagenda normalmente. Quando o arquivo ficar pronto, aparecerá uma notificação. Não feche ou recarregue esta aba do navegador.',
          20,
        );
      }
      const worker = new Worker(new URL('./workers/pdfWorker.js', import.meta.url));
      const workerListener = yield call(createWebWorkerListener, worker);
      worker.postMessage(params);
      yield takeEvery(workerListener, function* (event) {
        worker.terminate();
        yield put({
          type: actions.START_PDF_WEB_WORKER_SUCCESS,
          payload: {
            blob: event.data.blob,
          },
        });
        notification('success', 'Seu relatório está pronto');
      });
      yield take([
        actions.START_PDF_WEB_WORKER_REQUEST,
        actions.STOP_PDF_WEB_WORKER,
      ]);
      worker.terminate();
      workerListener.close();
    } catch (error) {
      console.warn(error);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(requestAppointments),
    fork(startPdfWebWorkerRequest),
    fork(startPdfWebWorker),
  ]);
}
